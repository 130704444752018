// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
// Customizable Area End

// Customizable Area Start
export interface Props {
  navigation: any;
  id: string;
}

interface IFaqList {
  attributes: {
    title: string;
    content: string;
  }
}

interface S {
  loading: boolean;
  isOpen: boolean;
  searchValue: string;
  faqList: IFaqList[];
  searchResultList: IFaqList[];
}

interface SS {
  id: string;
}
// Customizable Area End

export default class HelpCenterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  faqListApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      loading: false,
      isOpen: false,
      faqList: [],
      searchValue: "",
      searchResultList: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.faqListApiCallId) {
          if (responseJson.data) {
            this.setState({ faqList: responseJson.data, searchResultList: responseJson.data });
          } else if (responseJson.error) {
            toast.error(responseJson.error);
          }
        }
        this.setState({ loading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getFaqList();
  }

  onContactPageOpen = () => {
    this.setState({ isOpen: true });
  }

  onContactPageClose = () => {
    this.setState({ isOpen: false });
  }

  removeSearchValue = () => {
    this.setState({ searchValue: "", searchResultList: this.state.faqList });
  }

  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  }

  onSearch = (searchValue: string) => {
    const searchResultList = this.state.faqList.filter((item) => {
      return this.sanitizeData(item.attributes.title).toLowerCase().includes(searchValue.trim().toLowerCase()) || 
        this.sanitizeData(item.attributes.content).toLowerCase().includes(searchValue.trim().toLowerCase());
    });
    this.setState({ searchResultList, searchValue });
  }

  getFaqList = async () => {
    this.setState({ loading: true });
    this.faqListApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_content_management/help_centers`,
    });
  }
  // Customizable Area End
}