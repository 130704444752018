import React from "react";
import {
    Box, Button, Typography, Dialog, DialogContent, DialogActions, DialogTitle,
    InputBase, Checkbox,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Select, { components } from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { imageArrowLeft } from "./assets";
import CourseCreationHeaderController, { Props } from "./CourseCreationHeaderController";
import CustomEditor from "../../../components/src/CustomEditor.web";

export default class CourseCreationHeader extends CourseCreationHeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderTopBar = () => {
        return (
            <Header data-test-id="wrapper">
                <div className="content">
                    <div className="block">
                        <DefaultCustomButton onClick={this.onBack} style={buttonStyles.backButtonStyle} data-test-id="back-btn">
                            <img src={imageArrowLeft} alt="" />
                            <span>{this.props.isLessonVisible || this.props.isQuizVisible || this.props.isPreview ? "Back" : "Course Dashboard"}</span>
                        </DefaultCustomButton>
                    </div>{
                        this.props.isQuizVisible ? <div className="block" style={{ paddingRight: '20px', cursor: 'pointer' }}>
                            <DefaultCustomButton data-test-id="requirements-button-quiz" onClick={() => this.setState({ requirementsModal: { isVisible: true } })} style={buttonStyles.emptyButtonStyle}>Requirements</DefaultCustomButton>
                            <DefaultCustomButton data-test-id="save-button-quiz" style={buttonStyles.blackButtonStyle} onClick={() => this.props.onSaveQuiz()}>Save</DefaultCustomButton>
                        </div> :
                            <div className="block">
                                {!this.props.isLessonVisible && !this.props.isPreview && <DefaultCustomButton data-test-id="requirements-button" onClick={() => this.setState({ requirementsModal: { isVisible: true } })} style={buttonStyles.emptyButtonStyle}>Requirements</DefaultCustomButton>}
                                {!this.props.isPreview && <DefaultCustomButton style={this.props.isPreview ? buttonStyles.previewBlackButtonStyle : buttonStyles.emptyButtonStyle} onClick={this.props.onPreview} data-test-id="preview-btn">Preview</DefaultCustomButton>}
                                {!this.props.isLessonVisible && !this.props.isPreview && <DefaultCustomButton style={buttonStyles.whiteButtonStyle} onClick={this.openPackageDialog} data-test-id="open-dialog">Assign Learning Path</DefaultCustomButton>}
                                {!this.props.isPreview && <DefaultCustomButton data-test-id="save-course-btn" style={buttonStyles.blackButtonStyle} onClick={() => this.props.onSaveCourse(this.state.packageIds)}>Save</DefaultCustomButton>}
                                {!this.props.isLessonVisible &&!this.props.isPreview && this.props.isPublishBtnVisible && 
                                <DefaultCustomButton 
                                data-test-id="publish-btn" 
                                style={{...buttonStyles.blackButtonStyle,...this.props.isCoursePublished?buttonStyles.disabledBtn:{}}} 
                                onClick={() => this.props.onPublish(this.state.packageIds)}
                                >
                                    {this.props.isCoursePublished?"Published":"Publish"}
                                </DefaultCustomButton>}
                            </div>
                    }
                </div>
            </Header>
        );
    }

    requirementSchema = () => {
        return Yup.object().shape({
            totalHours: Yup.string().required("*Total hours is required")
                .test("not-zero", "*Value cannot be 0", (value) => {
                    return value && !/^0+(\.0+)?$/.test(value);
                }),
            courseRequirements: Yup.string().required("*Course requirement is required"),
        });
    }

    isValid = (value: string) => {
        return /^\d+$/.test(value) || value === '';
    }

    isValidNumber = (value: string) => {
        return /^\d+$/.test(value) || value === '';
    }

    renderRequirementModal = () => {
        return (
            <Formik
                data-test-id="modal-formik"
                enableReinitialize
                initialValues={{
                    totalHours: this.state.totalHours,
                    courseRequirements: this.state.courseRequirements,
                }}
                validationSchema={this.requirementSchema}
                onSubmit={(values) => {
                    this.submitCourseRequirements(values);
                }}
            >
                {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                    <form
                        noValidate
                        autoComplete="off"
                        className="form"
                        onSubmit={handleSubmit}
                    >
                        <DialogContent>
                            <Box>
                                <Typography className="label-title">Total Hours</Typography>
                                <CustomInput
                                    data-test-id="input-total-hours"
                                    fullWidth
                                    value={values.totalHours}
                                    placeholder="Total lesson hours"
                                    onChange={(e: { target: { value: string; }; }) => {
                                        const value = e.target.value;
                                        if (this.isValid(value)) {
                                            setFieldValue("totalHours", value.trim());
                                        }
                                    }}
                                />
                                {touched.totalHours && errors.totalHours &&
                                    <div className="text-danger">{errors.totalHours}</div>
                                }
                            </Box>
                            <Box mt="15px">
                                <Typography className="label-title">Course Requirements</Typography>
                                <Box className="requirement-editor">
                                    <CustomEditor
                                        hideBottomBorder
                                        value={values.courseRequirements}
                                        data-test-id="input-course-requirements"
                                        placeholder="Write course requirement here"
                                        onChange={(value: string) => {
                                            if (!this.sanitizeData(value).replace(/\n/g, "").trim()) {
                                                setFieldValue("courseRequirements", "");
                                            } else {
                                                setFieldValue("courseRequirements", value);
                                            }
                                        }}
                                    />
                                </Box>
                                {touched.courseRequirements && errors.courseRequirements &&
                                    <div className="text-danger">{errors.courseRequirements}</div>
                                }
                            </Box>
                        </DialogContent>
                        <DialogActions className="dialog-action">
                            <CancelButton sx={{ padding: "12px 30px !important" }} onClick={this.onHideRequirementsModal}>
                                Cancel
                            </CancelButton>
                            <DoneButton type="submit" data-test-id="submit-button">
                                Save
                            </DoneButton>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        );
    }

    quizSchema = (maxValue: number) => {
        return Yup.object().shape({
            noOfQuestions: Yup.string().required("*Number of questions is required")
                .test("not-zero", "*Value cannot be 0", (value) => {
                    return value && !/^0+(\.0+)?$/.test(value);
                })
                .test(
                    "max-questions",
                    `*Number of questions cannot exceed ${maxValue}`,
                    (value) => {
                        return !value || parseInt(value, 10) <= maxValue;
                    }
                ),
            noOfAnswers: Yup.string().required("*Number of correct answer is required")
                .test("not-zero", "*Value cannot be 0", (value) => {
                    return value && !/^0+(\.0+)?$/.test(value);
                })
                .test(
                    "max-answers",
                    "*Number of answers cannot exceed number of questions",
                    function (value) {
                        const { noOfQuestions } = this.parent;
                        return (
                            !value || !noOfQuestions || parseInt(value, 10) <= parseInt(noOfQuestions, 10)
                        );
                    }
                ),
            immediateFeedback: Yup.boolean(),
            scoreOnly: Yup.boolean().when('immediateFeedback', {
                is: false,
                then: Yup.boolean().oneOf([true], '*At least one of immediate feedback or score only must be checked.'),
            }),
            showAnswer: Yup.boolean(),
        })
    }

    getRequirementsTitle = () => {
        if (!this.props.isQuizVisible) {
            return "Course Requirements";
        }
        return this.props.isCurrentAssessmentFinal
            ? "Final Assessment Requirements"
            : "End of Lesson Assessment Requirements";
    }

    getImmediateObj = (checked: boolean, values: { scoreOnly: boolean; showAnswer: boolean; }) => {
        return ({
            immediateFeedback: checked,
            scoreOnly: checked ? false : values.scoreOnly,
            showAnswer: !checked ? false : values.showAnswer
        });
    }

    getScoreOnlyObj = (checked: boolean, values: { immediateFeedback: boolean; showAnswer: boolean; }) => {
        return ({
            scoreOnly: checked,
            showAnswer: checked ? false : values.showAnswer,
            immediateFeedback: checked ? false : values.immediateFeedback,
        });
    }

    getErrorMessage = (
        touched: { [key: string]: boolean | undefined },
        errors: { [key: string]: string | undefined },
        value: string
    ) => {
        return touched[value] && errors[value] &&
            <div className="text-danger">{errors[value]}</div>
    }

    renderModalMenu = () => {
        return (
            <CustomDialog
                open={this.state.requirementsModal.isVisible}
                onClose={this.onHideRequirementsModal}
                scroll={this.props.isQuizVisible ? "body" : "paper"}
                className="modal-menu"
            >
                <DialogTitle className="dialog-title">
                    <Typography className="heading-title">{this.getRequirementsTitle()}</Typography>
                    <CloseIcon
                        className="close-icon"
                        data-test-id="close-btn"
                        onClick={this.onHideRequirementsModal}
                    />
                </DialogTitle>
                {this.props.isQuizVisible ?
                    <Formik
                        data-test-id="formik"
                        enableReinitialize
                        initialValues={{
                            noOfQuestions: this.state.noOfQuestions,
                            noOfAnswers: this.state.noOfAnswers,
                            immediateFeedback: this.state.immediateFeedback,
                            showAnswer: this.state.showAnswer,
                            scoreOnly: this.state.scoreOnly,
                            retakeOption: this.props.isCurrentAssessmentFinal ? this.state.retakeOption : undefined,
                        }}
                        validationSchema={this.quizSchema(this.props.maxQuestion)}
                        onSubmit={(values) => {
                            this.submitAssessmentRequirement(values);
                        }}
                    >
                        {({ values, errors, touched, setValues, setFieldValue, handleSubmit }) => (
                            <form
                                noValidate
                                autoComplete="off"
                                className="form"
                                onSubmit={handleSubmit}
                            >
                                <CourseRequirementsContent>
                                    <Box className="dialog-content">
                                        <Box>
                                            <Typography className="label-title">Select number of questions for test</Typography>
                                            <CustomInput
                                                fullWidth
                                                data-test-id="input-total-questions"
                                                value={values.noOfQuestions}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    const value = e.target.value;
                                                    if (this.isValidNumber(value)) {
                                                        setFieldValue("noOfQuestions", value.trim());
                                                    }
                                                }}
                                            />
                                            {this.getErrorMessage(touched, errors, "noOfQuestions")}
                                        </Box>
                                        <Box>
                                            <Typography className="label-title">Set number of correct answers for passing</Typography>
                                            <CustomInput
                                                fullWidth
                                                data-test-id="input-correct-passing-answers"
                                                value={values.noOfAnswers}
                                                onChange={(e: { target: { value: string; }; }) => {
                                                    const value = e.target.value;
                                                    if (this.isValidNumber(value)) {
                                                        setFieldValue("noOfAnswers", value.trim());
                                                    }
                                                }}
                                            />
                                            {this.getErrorMessage(touched, errors, "noOfAnswers")}
                                        </Box>
                                        <Box className="checkbox-wrapper">
                                            <Typography className="option-title">Immediate feedback</Typography>
                                            <CustomCheckbox
                                                data-test-id="immediate-feedback"
                                                checked={values.immediateFeedback}
                                                onChange={(e) => {
                                                    setValues({
                                                        ...values,
                                                        ...this.getImmediateObj(e.target.checked, values)
                                                    });
                                                }}
                                            />
                                            {this.getErrorMessage(touched, errors, "immediateFeedback")}
                                        </Box>
                                        {values.immediateFeedback &&
                                            <Box className="checkbox-wrapper">
                                                <Typography className="option-title">Show correct answer</Typography>
                                                <CustomCheckbox
                                                    data-test-id="correct-answer"
                                                    checked={values.showAnswer}
                                                    onChange={(e) => {
                                                        setFieldValue("showAnswer", e.target.checked);
                                                    }}
                                                />
                                                {this.getErrorMessage(touched, errors, "showAnswer")}
                                            </Box>
                                        }
                                        <Box>
                                            <Box className="checkbox-wrapper">
                                                <Typography className="option-title">Score only</Typography>
                                                <CustomCheckbox
                                                    data-test-id="score-only"
                                                    checked={values.scoreOnly}
                                                    onChange={(e) => {
                                                        setValues({
                                                            ...values,
                                                            ...this.getScoreOnlyObj(e.target.checked, values)
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                            {this.props.isCurrentAssessmentFinal &&
                                                <Box>
                                                    <Box className="checkbox-wrapper">
                                                        <Typography className="option-title">Retake test</Typography>
                                                        <CustomCheckbox
                                                            data-test-id="retake-test"
                                                            checked={values.retakeOption}
                                                            onChange={(e) => {
                                                                setFieldValue("retakeOption", e.target.checked);
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            }
                                            {this.getErrorMessage(touched, errors, "scoreOnly")}
                                        </Box>
                                    </Box>
                                </CourseRequirementsContent>
                                <DialogActions className="dialog-action">
                                    <CancelButton sx={{ padding: "12px 30px !important" }} onClick={this.onHideRequirementsModal}>
                                        Cancel
                                    </CancelButton>
                                    <DoneButton type="submit" data-test-id="submit-button">
                                        Save
                                    </DoneButton>
                                </DialogActions>
                            </form>
                        )}
                    </Formik>
                    :
                    <>
                        {this.renderRequirementModal()}
                    </>
                }
            </CustomDialog>
        );
    }

    learningPathSchema = () => {
        return Yup.object().shape({
            title: Yup.string().required("*Title is required"),
            description: Yup.string().required("*Description is required"),
        });
    }

    renderLearningPathDialog = () => {
        return (
            <CustomDialog
                open={this.state.isPackageDialogOpen}
                onClose={this.closePackageDialog}
            >
                <DialogTitle className="dialog-title">
                    <Typography className="heading-title">Assign Learning Path</Typography>
                    <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.closePackageDialog} />
                </DialogTitle>
                <DialogContent className="content">
                    <Box>
                        <Typography sx={{ marginBottom: "5px" }}>Learning Path</Typography>
                        <Select
                            classNamePrefix="react-select"
                            placeholder="Select Learning Path"
                            data-test-id="list-dropdown"
                            options={this.state.packageList}
                            isMulti={true}
                            allowSelectAll={true}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            openMenuOnClick={true}
                            components={{
                                Option: (props) => <div>
                                    <components.Option {...props}>
                                        <label>{props.label}</label>
                                        <Checkbox
                                            disableRipple
                                            icon={<div className="unchecked" />}
                                            checkedIcon={<div className="checked"><DoneIcon /></div>}
                                            checked={props.isSelected}
                                            onChange={() => null}
                                        />
                                    </components.Option>
                                </div>
                            }}
                            value={
                                this.state.packageList.filter((option: { value: string; }) =>
                                    !!this.state.tempIds.find((ele: string) => +ele === +option.value)
                                )
                            }
                            onChange={(event) => {
                                let ids = [];
                                if (event) {
                                    ids = event.map((obj) => {
                                        return obj.value;
                                    });
                                }
                                this.setState({ tempIds: ids });
                            }}
                        />
                    </Box>
                    <Box m="20px 0px" display="flex" justifyContent="center">
                        <Typography>OR</Typography>
                    </Box>
                    <Formik
                        initialValues={{
                            title: "",
                            description: ""
                        }}
                        enableReinitialize
                        data-test-id="learning-formik"
                        validationSchema={this.learningPathSchema}
                        onSubmit={(values) => {
                            this.submitLearningPath(values);
                        }}
                    >
                        {({ errors, touched, values, setFieldValue, handleSubmit }) => (
                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={handleSubmit}
                                className="form"
                            >
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography className="create-title">Create new learning path</Typography>
                                    <DoneButton
                                        type="submit"
                                        sx={{
                                            width: "fit-content",
                                            padding: "8px 16px !important",
                                            borderRadius: "4px !important",
                                            fontSize: "14px !important",
                                        }}
                                    >
                                        Create
                                    </DoneButton>
                                </Box>
                                <Box mt="10px">
                                    <Typography className="label-title">Title of Learning Path</Typography>
                                    <CustomInputBase
                                        fullWidth
                                        data-test-id="title"
                                        value={values.title}
                                        placeholder="Write title here"
                                        onChange={(e: { target: { value: string; }; }) => {
                                            this.setState({ titleError: "" });
                                            setFieldValue("title", e.target.value);
                                        }}
                                    />
                                    {touched.title && errors.title &&
                                        <div className="text-danger">{errors.title}</div>
                                    }
                                    {this.state.titleError &&
                                        <div className="text-danger">{this.state.titleError}</div>
                                    }
                                </Box>
                                <Box mt="15px">
                                    <Typography className="label-title">Description</Typography>
                                    <CustomInputBase
                                        rows={5}
                                        fullWidth
                                        multiline={true}
                                        data-test-id="description"
                                        value={values.description}
                                        placeholder="Add description about learning path"
                                        onChange={(e: { target: { value: string; }; }) => {
                                            setFieldValue("description", e.target.value);
                                        }}
                                    />
                                    {touched.description && errors.description &&
                                        <div className="text-danger">{errors.description}</div>
                                    }
                                </Box>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
                <DialogActions className="dialog-action">
                    <CancelButton
                        sx={{
                            padding: "8px 40px !important",
                            borderRadius: "4px !important",
                            width: "fit-content",
                        }}
                        data-test-id="cancel-btn"
                        onClick={this.closePackageDialog}
                    >
                        Cancel
                    </CancelButton>
                    <DoneButton
                        sx={{
                            padding: "8px 40px !important",
                            borderRadius: "4px !important",
                            width: "fit-content",
                        }}
                        data-test-id="save-btn"
                        onClick={this.onSavePackageList}
                    >
                        Assign
                    </DoneButton>
                </DialogActions>
            </CustomDialog>
        );
    }

    render() {
        return (
            <>
                {this.renderModalMenu()}
                {this.renderLearningPathDialog()}
                <header style={{ borderBottom: this.props.isQuizVisible ? 'none' : '1px solid #E7E5E4', padding: this.props.isQuizVisible ? "20px 0px 20px 0px" : "15px 43px 15px 0px" }}>{this.renderTopBar()}</header>
                {this.props.isQuizVisible && <div style={{ borderBottom: '0.5px', boxShadow: '0px 2px 20px 0px #00000014' }}>
                    <Box display={"flex"} padding={'10px 0px 20px 0px'} justifyContent={"center"} alignItems={"center"}>
                        <CustomInputTitle
                            fullWidth
                            autoFocus
                            value={this.state.assessmentTitle}
                            onChange={(e) => this.setAssessmentTitle(e.target.value)}
                            data-test-id={"custom-assessment-title-input"}
                            placeholder={'Assessment title here'}
                        />
                    </Box>
                </div>}
                <main>
                    {this.props.children}
                </main>
            </>
        );
    }
}

// Customizable Area Start
const CustomDialog = styled(Dialog)({
    '& .MuiPaper-root': {
        maxWidth: '800px',
        width: '100%',
        borderRadius: "8px",
    },
    "& .dialog-title": {
        padding: "15px 30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #E2E8F0",
        "& .close-icon": {
            cursor: "pointer",
            color: "#334155",
        },
    },
    "& .create-title": {
        fontSize: "20px",
        color: "##1C1C1C",
        fontFamily: "Heebo-SemiBold",
    },
    "& .label-title": {
        fontSize: "14px",
        color: "#64748B",
        fontFamily: "Heebo-Regular",
        fontWeight: 600,
        padding: '5px 0',
    },
    "&.modal-menu": {
        '& .MuiPaper-root': {
            maxWidth: '670px',
        },
        "& .label-title": {
            color: "#334155"
        },
        "& .dialog-action": {
            padding: "16px 32px",
            gap:"8px"
        },
        "& form": {
            margin: "0px"
        },
        "& .requirement-editor": {
            border: "1px solid #9D9D9D",
            borderRadius: "4px",
            padding: "10px 5px 3px 0px",
            "& .public-DraftStyleDefault-block": {
                paddingLeft: "15px"
            },
            "& .public-DraftEditorPlaceholder-inner": {
                paddingLeft: "15px",
                fontSize: "14px",
                letterSpacing: "0.2px",
                color: "#c4c4c4",
                fontfamily: 'Heebo-Regular'
            }
        }
    },
    "& .heading-title": {
        fontSize: "24px",
        fontFamily: "Heebo-Bold",
    },
    "& .content": {
        padding: "20px 30px 25px !important",
    },
    "& .dialog-action": {
        padding: "10px 17px",
        borderTop: "1px solid #E2E8F0",
    },
    "& .react-select__placeholder": {
        paddingLeft: "7px"
    },
    "& .react-select__input": {
        paddingLeft: "7px",
    },
    "& .unchecked": {
        width: "20px",
        height: "20px",
        border: "1px solid #484848",
        borderRadius: "3px",
    },
    "& .checked": {
        width: "20px",
        height: "20px",
        backgroundColor: "#000",
        border: "1px solid #000",
        borderRadius: "3px",
    },
    "& .checked svg": {
        color: "#fff",
        fontSize: "20px"
    },
    "& .react-select__multi-value__remove:hover": {
        backgroundColor: "black",
        color: "#fff",
    },
    ".react-select__menu-list": {
        maxHeight: "223px",
    },
    "& .react-select__menu": {
        color: "black",
    },
    "& .react-select__option": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e8e8e8",
        backgroundColor: "#fff",
        color: "black",
        marginTop: "0px",
        padding: "0px 12px",
        paddingLeft: "0px",
        fontSize: "14px",
        "& label": {
            marginLeft: "15px"
        }
    },
    "& .react-select__option--is-selected": {
        fontFamily: "Heebo-SemiBold",
    },
    "& .react-select__option:hover": {
        backgroundColor: "#fff",
        fontFamily: "Heebo-SemiBold",
        color: "black",
    },
    "& .react-select__option:hover .MuiButtonBase-root:hover": {
        backgroundColor: "transparent"
    },
    "& .react-select__option:active": {
        backgroundColor: "#fff",
        color: "black",
        fontFamily: "Heebo-SemiBold",
    },
    "& .react-select__multi-value": {
        borderRadius: "6px",
        color: "#fff",
        backgroundColor: "black",
        padding: "0px 2px",
    },
    "& .react-select__multi-value__label": {
        color: "#fff",
    }
});

const DoneButton = styled(Button)({
    marginLeft: "15px !important",
    width: "95px",
    textTransform: "none",
    fontFamily: "Heebo-Bold",
    color: "white !important",
    fontSize: "16px !important",
    padding: "12px 28px !important",
    backgroundColor: "black",
    borderRadius: "8px !important",
    "&:hover": {
        backgroundColor: "black",
    },
});

const CancelButton = styled(Button)({
    marginLeft: "15px !important",
    width: "95px",
    textTransform: "none",
    fontFamily: "Heebo-Bold",
    color: "black !important",
    fontSize: "16px !important",
    padding: "12px 28px !important",
    background: "#F0F0F0",
    borderRadius: "8px !important",
    "&:hover": {
        backgroundColor: "#F0F0F0",
    },
});

const CustomInputBase = styled(InputBase)({
    border: "1px solid #CBD5E1",
    borderRadius: "5px",
    fontFamily: "Heebo-Regular",
    backgroundColor: "#fff",
    marginTop: "3px",
    fontSize: "14px",
    "& input": {
        padding: "10px 8px",
        '&:placeholder': {
            fontWeight: 500
        }
    },
    "& .MuiInputBase-inputMultiline": {
        fontSize: "14px",
        padding: "10px 8px",
    },

});

const Header = styled(Box)({
    backgroundColor: "#FFFFFF",
    "& .content": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .block": {
            gap: 16,
            display: "flex",
            alignItems: "center"
        }
    }
})

const CustomCheckbox = styled(Checkbox)(() => ({
    '& .MuiSvgIcon-root': {
        width: '32px',
        height: '32px',
        backgroundColor: 'white',
        border: '1px solid #A8A29E',
        borderRadius: '4px',
    },
    '&:not(.Mui-checked) .MuiSvgIcon-root': {
        color: 'transparent',
    },
    '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
            backgroundColor: 'black',
            borderColor: 'black',
        },
        '& .MuiSvgIcon-root path': {
            d: 'path("M5 12l5 5L20 7")',
            stroke: 'white',
            strokeWidth: 2,
            fill: 'none',
        },
    },
}));

const CustomInputTitle = styled(InputBase)({
    borderRadius: "4px",
    fontFamily: "Crimson Text",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "80px",
    width: '700px',
    "& ::placeholder": {
        fontFamily: "Crimson Text",
        fontSize: "40px",
        fontWeight: 400,
        lineHeight: "80px",
    }
});

const CustomInput = styled(InputBase)({
    padding: "10px 14px 10px 14px",
    background: "#FFFFFF",
    height: 48,
    border: "1px solid #9D9D9D",
    borderRadius: "4px",
    fontFamily: "Heebo-Regular",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    "& ::placeholder": {
        fontFamily: "Heebo-Regular",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
    }
});

const CourseRequirementsContent = styled(Box)({
    margin: "16px 0",
    padding: "24px 40px 24px 40px",
    "& .dialog-content": {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    "& .label-title": {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "22px",
        color: "#334155",
        marginBottom: "4px"
    },
    "& .option-title": {
        fontFamily: "Heebo-Regular",
        fontSize: "18px",
        fontWeight: "400",
        lineHeight: "32px",
        color: "##1C1C1C",
        marginBottom: "4px",
    },
    "& .checkbox-wrapper": {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center'
    }
})

const baseButtonStyle = {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    padding: "6px 16px",
    color: "#1C1C1C",
};

const buttonStyles = {
    emptyButtonStyle: {
        ...baseButtonStyle,
        fontWeight: 700,
    },
    previewBlackButtonStyle: {
        ...baseButtonStyle,
        color: "#FFFFFF",
        backgroundColor: "#1C1C1C",
    },
    whiteButtonStyle: {
        ...baseButtonStyle,
        borderRadius: "4px",
        border: "1px solid #1C1C1C",
    },
    blackButtonStyle: {
        ...baseButtonStyle,
        fontFamily: "Heebo-Regular",
        fontWeight: 400,
        color: "#FFFFFF",
        padding: "6px 10px",
        backgroundColor: "#1C1C1C",
    },
    disabledBtn:{
        opacity:'0.6',
        cursor:'not-allowed',
        color:'#fff',
        pointerEvents:'none',
      } as React.CSSProperties,
    backButtonStyle: {
        ...baseButtonStyle,
        fontFamily: "Heebo-Regular",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        gap: 8,
        "& img": {
            width: 24,
            height: 24,
        }
    }
};

const DefaultCustomButton = styled(Button)({
    textTransform: "none",
});

// Customizable Area End