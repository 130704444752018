// Customizable Area Start
import React from "react";
import {
  Box,
  Button,
  Typography,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  Grid
} from "@mui/material";
import { ExpandMore, TimerOutlined, MoreVert } from '@mui/icons-material';
import DOMPurify from 'dompurify';
import moment from "moment";
import MixpanelIntegrationController, {
  Props, ICourse, ILearningPathCourse
} from "./MixpanelIntegrationController";
import Loader from "../../../components/src/Loader.web";
const { baseURL } = require("../../../framework/src/config.js");
import { CourseThumbnailImage } from "./assets";

export default class MixpanelIntegration extends MixpanelIntegrationController {
  constructor(props: Props) {
    super(props);
  }

  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  }

  renderCourseCard = (courseInfo: ICourse) => {
    const courseImg = courseInfo.thumbnail_image?.url ? baseURL + courseInfo.thumbnail_image?.url : CourseThumbnailImage;
    return (
      <Grid item xs={6} md={3} key={courseInfo.id}>
        <Box sx={styles.courseCard}>
          <BoxThumbnail>
            <Box
              className="thumbnail"
              sx={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImg})` }}
            >
              <Typography className="percentage">
                <TimerOutlined className="time" />
                <Box ml="5px">{courseInfo.duration} Hours - {courseInfo.user_process ?? 0}% Completed</Box>
              </Typography>
            </Box>
          </BoxThumbnail>
          <Box sx={styles.courseCardDescription}>
            <Box className="course-title" dangerouslySetInnerHTML={{ __html: this.sanitizeData(courseInfo.title) }} />
            <Box className="count">{courseInfo.number_of_lessons ?? 0} lessons</Box>
            <Box className="description">
              <b style={{ color: "#1A1A1A" }}>You will learn: </b>
              <span dangerouslySetInnerHTML={{ __html: this.sanitizeData(courseInfo.description) }} />
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  renderLearningPathCourseCard = (courseDetail: ILearningPathCourse) => {
    const courseImg = courseDetail.images?.thumbnail ? baseURL + courseDetail.images.thumbnail : CourseThumbnailImage;
    return (
      <Grid item xs={6} md={3} key={courseDetail.course.id}>
        <Box sx={styles.courseCard}>
          <BoxThumbnail>
            <Box
              className="thumbnail"
              sx={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImg})` }}
            >
              <Typography className="percentage">
                <TimerOutlined className="time" />
                <Box ml="5px">{courseDetail.course.duration} Hours - {courseDetail.course.course_process ?? 0}% Completed</Box>
              </Typography>
            </Box>
          </BoxThumbnail>
          <Box sx={styles.courseCardDescription}>
            <Box className="course-title" dangerouslySetInnerHTML={{ __html: this.sanitizeData(courseDetail.course.title) }} />
            <Box className="count">{courseDetail.course.total_course_modules ?? 0} lessons</Box>
            <Box className="description">
              <b style={{ color: "#1A1A1A" }}>You will learn: </b>
              <span dangerouslySetInnerHTML={{ __html: this.sanitizeData(courseDetail.course.description) }} />
            </Box>
          </Box>
        </Box>
      </Grid>
    );
  }

  render() {
    return (
      <>
        <Wrapper>
          <Loader loading={this.state.loading || this.state.isLoading || this.state.isLoadingData} />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={3}>
                <Card>
                  <CardLabel>Courses Assigned</CardLabel>
                  <CardValue>{this.state.analyticsInfo.course_assigned}</CardValue>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Card>
                  <CardLabel>Courses Left To Complete</CardLabel>
                  <CardValue>{this.state.analyticsInfo.left_course}</CardValue>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Card>
                  <CardLabel>Courses Assigned</CardLabel>
                  <CardValue>{this.state.analyticsInfo.course_assigned}</CardValue>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <Card>
                  <CardLabel>Total Learning Time</CardLabel>
                  <CardValue>{this.state.analyticsInfo.total_time}h</CardValue>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Card>
                  <CardLabel>Number Of Assessments Assigned</CardLabel>
                  <CardValue sx={{ color: '#F59E0B' }}>{this.state.analyticsInfo.assign_modules}</CardValue>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Card>
                  <CardLabel>Average Assessment Score</CardLabel>
                  <CardValue sx={{ color: '#059669' }}>{this.state.analyticsInfo.avg_assign?.toFixed(2)}%</CardValue>
                </Card>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <Card>
                  <CardLabel>Average Time To Complete A Course</CardLabel>
                  <CardValue sx={{ color: '#059669' }}>{this.state.analyticsInfo.avg_time?.toFixed(2)}h</CardValue>
                </Card>
              </Grid>
            </Grid>
          </Box>         
          {this.state.accreditationData.length > 0 && (
            <Box padding='30px 0px 10px'>
              <Typography sx={{ fontSize: '24px', marginBottom: '1rem' }}>Accreditation</Typography>
              {this.state.accreditationData.map((accreditation, index) => (
                <StyledAccordion key={`${index + 1}`}>
                  <StyledAccordionSummary data-test-id="accordion">
                    <ContentContainer>
                      <TitleContainer>
                      <StyledTypography dangerouslySetInnerHTML={{ __html: this.sanitizeData(accreditation.attributes.all_data.title) }} />
                      </TitleContainer>
                      <GrayTypography>Total courses - {accreditation.attributes.all_data.total_courses}</GrayTypography>
                      <ProgressContainer>
                        <ProgressText>{accreditation.attributes.all_data.completion_percentage}% Completed</ProgressText>
                        <StyledLinearProgress
                          sx={{
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: accreditation.attributes.all_data.completion_percentage === 100 ? '#29CC6A' : 'black',
                            },
                          }}
                          variant="determinate"
                          value={accreditation.attributes.all_data.completion_percentage}
                        />
                      </ProgressContainer>
                    </ContentContainer>
                  </StyledAccordionSummary>
                  <AccordionDetails>
                    <Grid container sx={{ padding: '15px' }} spacing={3} marginTop={-5}>
                      {accreditation.attributes.all_data.courses.map((course: ICourse) => {
                        return this.renderCourseCard(course);
                      })}
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </Box>
          )}
          <Box mt="20px" mb='10rem'>
            <Typography sx={{ fontSize: '24px', marginBottom: '20px' }}>Your Learning Paths</Typography>
            {this.state.learningPathData.map((learningPath, index) => (
              <StyledAccordion key={`${index + 1}`}>
                <StyledAccordionSummary>
                  <ContentContainer>
                    <TitleContainer>
                      <StyledTypography dangerouslySetInnerHTML={{ __html: this.sanitizeData(learningPath.learning_path_title) }} />
                    </TitleContainer>
                    <div>
                      <Box display='flex' mb='4px'>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM10.8 16.4L15.47 12.9C15.74 12.7 15.74 12.3 15.47 12.1L10.8 8.6C10.47 8.35 10 8.59 10 9V16C10 16.41 10.47 16.65 10.8 16.4ZM4 12.5C4 16.91 7.59 20.5 12 20.5C16.41 20.5 20 16.91 20 12.5C20 8.09 16.41 4.5 12 4.5C7.59 4.5 4 8.09 4 12.5Z" fill="#6A6A6A" />
                        </svg>
                        <GrayTypography sx={{ marginLeft: "8px" }}>{moment(learningPath.learning_path_created_at).format("DD.MM.YYYY 09:00")}</GrayTypography>
                      </Box>
                      <Box display='flex' mb='4px'>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M11 4.5H17.4913C19.4765 4.5 20.6698 6.70247 19.5855 8.36541L18.1938 10.5L19.5855 12.6346C20.6698 14.2975 19.4765 16.5 17.4913 16.5H14C12.3431 16.5 11 15.1569 11 13.5V4.5ZM13 6.5V13.5C13 14.0523 13.4477 14.5 14 14.5H17.4913C17.8884 14.5 18.127 14.0595 17.9102 13.7269L16.5184 11.5923C16.0856 10.9284 16.0856 10.0716 16.5184 9.40767L17.9102 7.27308C18.127 6.9405 17.8884 6.5 17.4913 6.5H13Z" fill="#6A6A6A" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M6 4.5V12.5H11V5.5C11 4.94772 10.5523 4.5 10 4.5H6ZM5 2.5C4.44772 2.5 4 2.94772 4 3.5V14.5H13V5.5C13 3.84315 11.6569 2.5 10 2.5H5Z" fill="#6A6A6A" />
                          <path d="M4 3.5C4 2.94772 4.44772 2.5 5 2.5C5.55228 2.5 6 2.94772 6 3.5V21.5C6 22.0523 5.55228 22.5 5 22.5C4.44772 22.5 4 22.0523 4 21.5V3.5Z" fill="#6A6A6A" />
                        </svg>
                        <GrayTypography sx={{ marginLeft: "8px" }}>{moment(learningPath.current_date).format("DD.MM.YYYY 09:00")}</GrayTypography>
                      </Box>
                    </div>
                    <ProgressContainer>
                      <ProgressText>{learningPath.total_processes}% Completed</ProgressText>
                      <StyledLinearProgress variant="determinate" value={learningPath.total_processes} />
                      <MoreVert />
                    </ProgressContainer>
                  </ContentContainer>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <Grid container sx={{ padding: '15px' }} spacing={3} marginTop={-5}>
                    {learningPath.courses.map((course: ILearningPathCourse) => {
                      return this.renderLearningPathCourseCard(course);
                    })}
                  </Grid>
                </AccordionDetails>
              </StyledAccordion>
            ))}
          </Box>
        </Wrapper>
      </>
    );
  }
}

const Wrapper = styled(Box)({
  "& .learning-path-tab": {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    columnGap: '10px',
    overflow: "auto",
    "::-webkit-scrollbar": {
      height: "5px"
    }
  },
  "& .thumbnail": {
    height: "160px",
    position: "relative",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  "& .percentage": {
    display: 'flex',
    padding: '10px',
    color: '#fff',
    alignItems: 'center',
    zIndex: '2',
    fontSize: '14px',
    position: "absolute",
    bottom: "0px",
    "& .timer": {
      marginRight: '10px'
    }
  },
  "& .course-title": {
    wordBreak: "break-word",
    fontSize: '16px',
    color: 'black',
    "-webkit-line-clamp": "2",
    "text-overflow": "ellipsis",
    "overflow": "hidden",
    "display": "-webkit-box",
    "-webkit-box-orient": "vertical",
  },
  "& .count": {
    fontSize: '12px',
    color: '#171717'
  },
  "& .description": {
    fontSize: '14px',
    color: "#8A8A8A",
    "-webkit-line-clamp": "3",
    "text-overflow": "ellipsis",
    "overflow": "hidden",
    "display": "-webkit-box",
    "-webkit-box-orient": "vertical",
  }
});

const BoxThumbnail = styled(Box)({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '160px',
  "&>img": {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: '1'
  }
});

const Card = styled(Box)({
  padding: '16px 32px',
  backgroundColor: 'white',
  boxShadow: '0px 2px 20px 0px #00000014',
  borderRadius: '4px'
})
const CardLabel = styled(Typography)({
  fontSize: '0.875rem',
  fontWeight: 700,
  fontFamily: 'Heebo-Bold',
  color: '#6A6A6A'
})
const CardValue = styled(Typography)({
  fontSize: "1.875rem",
  fontWeight: 700,
  fontFamily: 'Heebo-Bold'
})
const StyledTypography = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '16px',
});

const StyledLinearProgress = styled(LinearProgress)({
  backgroundColor: '#e7e5e4',
  height: '8px',
  width: '100%',
  marginRight: '0.8rem',
  '& .MuiLinearProgress-bar': {
    backgroundColor: 'black',
  },
});

const StyledAccordion = styled(Accordion)({
  border: '1px solid #D6D3D1',
  boxShadow: 'none',
  marginBottom: '10px'
});

const StyledAccordionSummary = styled((props: any) => (
  <AccordionSummary
    expandIcon={
      <ExpandMore />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(0),
  },
}));

const ContentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  margin: '0 1rem',
});

const TitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: "30%"
});

const ProgressContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '30%',
});

const GrayTypography = styled(Typography)({
  color: 'gray',
});

const LearningPathButton = styled(Button)({
  textTransform: "none",
  marginBottom: "10px",
  fontFamily: "Heebo-Regular",
  whiteSpace: "noWrap",
  color: "black !important",
  minWidth: "fit-content",
  fontSize: "14px !important",
  padding: "7px 16px !important",
  backgroundColor: "white",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "white",
  },
});

const ProgressText = styled(Typography)({
  marginRight: '0.5rem',
  whiteSpace: 'nowrap',
});
const styles = {
  mainBlock: {
    flexDirection: 'row',
    display: 'flex',
  },
  logoutButton: {
    flexDirection: 'row',
    display: 'flex',
    color: 'red',
    gap: '8px',
    justifyContent: 'start',
    flexGrow: '1',
    textTransform: 'none',
    fontSize: '16px',
  },
  commonButton: {
    backgroundColor: 'white',
    fontSize: '14px',
    padding: '5px 15px',
    textTransform: 'none',
    color: 'black',
  },
  blackButton: {
    color: 'white',
    backgroundColor: 'black',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  courseCard: {
    display: 'flex',
    width: '100%',
    height: "100%",
    maxWidth: '350px',
    gap: '16px',
    flexDirection: 'column',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  },
  courseCardDescription: {
    gap: '16px',
    flexDirection: 'column',
    padding: '0px 16px 24px 16px',
    display: 'flex',
  },
}
// Customizable Area End
