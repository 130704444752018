import React from 'react';
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Editor, EditorProps } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, Modifier } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ResizeObserver from 'resize-observer-polyfill';

interface ExtendedEditorProps {
  handleBeforeInput?: (input: string) => string;
}
interface ExtendedEditorProps extends EditorProps {}

interface Props {
  value: string;
  placeholder?: string;
  maxCharLimit?: number;
  isListHide?: boolean;
  isBlockTypeVisible?: boolean;
  hideBottomBorder?: boolean;
  isTableEditor?: boolean;
  onChange: (value: string) => void;
}

interface S {
  editorState: EditorState;
  isListHide: boolean;
  isBlockTypeVisible: boolean;
  wrapperHeight: number;
}

interface SS {}

const fontList = [
  'Be Vietnam',
  'Cormorant',
  'DM Sans',
  'Heebo',
  'Inter',
  'Lato',
  'Lora',
  'Lustria',
  'Maitree',
  'Maven Pro',
  'Merriweather',
  'Montserrat',
  'Open-Sans',
  'Oswald',
  'Poppins',
  'Raleway',
  'Roboto',
  'Roboto Slab',
];

export default class CustomEditor extends React.Component<Props, S, SS> {
  private wrapperRef: React.RefObject<HTMLDivElement>;
  private resizeObserver: ResizeObserver | null;

  constructor(props: Props) {
    super(props);
    const contentBlock = htmlToDraft(this.props.value || "");
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

    this.state = {
      isListHide: !!this.props.isListHide,
      isBlockTypeVisible: !!this.props.isBlockTypeVisible,
      editorState: EditorState.createWithContent(contentState),
      wrapperHeight: 0,
    };

    this.wrapperRef = React.createRef();
    this.resizeObserver = null;
  }

  componentDidMount() {
    if (this.wrapperRef.current) {
      // Initialize ResizeObserver to watch the wrapper's height
      this.resizeObserver = new ResizeObserver((entries:any) => {
        for (let entry of entries) {
          const newHeight = entry.contentRect.height;
          if (newHeight !== this.state.wrapperHeight) {
            this.setState({ wrapperHeight: newHeight });
          }
        }
      });

      // Start observing the wrapper
      this.resizeObserver.observe(this.wrapperRef.current);
    }
  }

  componentWillUnmount() {
    // Disconnect the ResizeObserver
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  handlePastedText = (pastedText: string) => {
    const { editorState } = this.state;
    const { maxCharLimit, onChange } = this.props;
    if (maxCharLimit) {
      const currentContent = editorState.getCurrentContent();
      const selectionState = editorState.getSelection();
      const plainText = currentContent.getPlainText();
  
      // Calculate the length of the selected text
      const selectedTextLength = selectionState.getEndOffset() - selectionState.getStartOffset();
      const charactersRemaining = maxCharLimit - (plainText.length - selectedTextLength);
      const truncatedText = charactersRemaining > 0 ? pastedText.slice(0, charactersRemaining) : "";
  
      // Replace the selected text with the new text
      const newContent = Modifier.replaceText(
        currentContent,
        selectionState,
        truncatedText,
        editorState.getCurrentInlineStyle() // Preserve styles
      );
  
      const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
  
      this.setState({ editorState: newEditorState }, () => {
        onChange(draftToHtml(convertToRaw(newEditorState.getCurrentContent())));
      });
      return true; // Indicate that we handled the paste
    }
    return false;
  };

  handleBeforeInput = (input: string) => {
    const { maxCharLimit } = this.props;
    if (maxCharLimit) {
      const { editorState } = this.state;
      const currentContent = editorState.getCurrentContent();
      const currentText = currentContent.getPlainText();
      if (currentText.length >= maxCharLimit) {
        return 'handled';
      }
    }
    return 'not-handled';
  };

  onEditorStateChange = (editorState: EditorState) => {
    const { maxCharLimit } = this.props;
    let currentText = '';
    if (maxCharLimit) {
      const currentContent = editorState.getCurrentContent();
      currentText = currentContent.getPlainText();
    }
    if (!maxCharLimit || currentText.length <= maxCharLimit) {
      this.setState({ editorState }, () => {
        this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
      });
    }
  };

  render() {
    const { hideBottomBorder,isTableEditor } = this.props;
    const { isListHide, isBlockTypeVisible, editorState, wrapperHeight } = this.state;
    const toolbarOptions = ['history', 'fontFamily', 'fontSize', 'textAlign', 'colorPicker', 'inline'];
    if (!isListHide) {
      toolbarOptions.push('list');
    }
    if (isBlockTypeVisible) {
      toolbarOptions.splice(1, 0, 'blockType');
    }
    const CustomEditor: React.ComponentType<ExtendedEditorProps> = Editor;

    return (
      <Wrapper
        hideBottomBorder={hideBottomBorder}
        ref={this.wrapperRef} 
        isTableEditor={isTableEditor}
        wrapperHeight={wrapperHeight}
      >
        <CustomEditor
          toolbarOnFocus
          handleBeforeInput={this.handleBeforeInput}
          handlePastedText={this.handlePastedText}
          placeholder={this.props.placeholder ?? 'Write here'}
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: toolbarOptions,
            blockType: {
              inDropdown: true,
              options: ['Normal', 'Blockquote'],
            },
            fontSize: {
              options: Array.from({ length: 45 }, (_, i) => i + 10),
            },
            fontFamily: {
              options: fontList,
            },
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            textAlign: {
              inDropdown: true,
              options: ['left', 'center', 'right', 'justify'],
            },
            list: !this.state.isListHide && {
              inDropdown: false,
              options: ['unordered', 'ordered'],
            },
          }}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "hideBottomBorder" && prop !== "isTableEditor" && prop !== "wrapperHeight" })<{
  hideBottomBorder?: boolean;
  isTableEditor?: boolean;
  wrapperHeight?: number;
}>(({ hideBottomBorder, isTableEditor, wrapperHeight }) => ({
  backgroundColor: "white",
  position: isTableEditor?'static':"relative",
  "& .public-DraftStyleDefault-ltr": {
    textAlign: "inherit"
  },
  ".public-DraftStyleDefault-block": {
    margin: "0px",
    marginBottom: "10px"
  },
  "& .rdw-editor-main": {
    height: "fit-content",
    borderBottom: hideBottomBorder ? "none" : "1.5px solid #D6D3D1",
  },
  "& .rdw-history-wrapper, & .rdw-list-wrapper": {
    flexWrap: "noWrap"
  },
  "& .rdw-editor-toolbar": {
    zIndex: "2000",
    width: "fit-content",
    top:'-50px',
    left:0,
    position:"absolute",
    transform: isTableEditor?'unset': `translateY(calc(${wrapperHeight}px + 50px) )` ,
    border: "1.5px solid black",
    flexWrap: "noWrap",
  },
  "& .rdw-dropdown-optionwrapper": {
    width: "50px",
    scrollbarWith:'thin'
  },
  "& .rdw-fontfamily-optionwrapper": {
    width: "119px"
  },
  "& .rdw-text-align-dropdown": {
    "& .rdw-dropdown-selectedtext": {
      width: "29px"
    },
    "& .rdw-dropdown-optionwrapper": {
      width: "50px",
    },
  },
  "& .rdw-block-dropdown": {
    "& .rdw-dropdown-optionwrapper": {
      width: "109px"
    }
  },
  "& .rdw-inline-wrapper": {
    display: "contents",
    "& .rdw-option-wrapper": {
      height: "20px",
      width: "20px",
      padding: "4px",
      margin: "0px 2px",
      borderRadius: "4px",
      "&.rdw-option-active ": {
        background: "lightgray"
      }
    }
  },
  '& blockquote': {
    margin: "10px 14px",
    borderLeft: '4px solid #ccc',
    color: '#555',
  },
}));