import React from "react";

// Customizable Area Start
import { AppBar, Tab, Tabs,CircularProgress, Box, Button, Typography, Select,Card,CardContent,Rating,Divider, TextField, FormControl, MenuItem, IconButton, InputAdornment, Modal, Container, Grid, ListItemText, ListItemIcon, Checkbox, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import { KeyboardArrowDown, Search, Close, AddCircleOutline, DeleteOutline, ExpandMore, ArrowForwardIos, Delete } from '@mui/icons-material';
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import { DeleteIcon } from "./assets";
import DOMPurify from 'dompurify';


 /* istanbul ignore next */
const  StyledMenuItem = styled(MenuItem)(({ theme,selected }) => ({
  padding: '5px',
  borderRadius: "2px",
  backgroundColor:"#fff",
  borderColor:'black',
  margin:"5px",
  "&:hover": {
    backgroundColor: "#f6f6f6",
    cursor:'pointer'
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: "500",
    color:"#000",
    marginLeft:"10px",
    width:"225px",
    textWrap:"auto"
  }
}));
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON,
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

   /* istanbul ignore next */
  FeedbackAggregation = () => {
    const {feedbackForms ,filteredForms,selectedCourse} = this.state;
    const feedbackData= selectedCourse.id !== "0" ? filteredForms : feedbackForms
    return (
      <Box width={"100%"}>
      <Box sx={{ padding:"3% 0px"}}>
      { feedbackData && feedbackData.length > 0 ?
      <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Feedback Forms
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Number of Questions
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Number of Responses
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
               Average Rating
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {feedbackData && feedbackData.length >0 &&feedbackData.map((row:any, index:any) => (
            <TableRow key={index}>
              <TableCell style={{width:"40%"}}>
                <Typography variant="body2" fontWeight="bold">{row.name}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {row.number_of_questions}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {row.number_of_responses}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {row.average_rating.toFixed(0)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <IconButton style={{color:"#000",fontWeight:"bold"}} 
                onClick={()=>this.handlefeedbackFormOpen(row.id,row.name)}
                  >
                  <ArrowForwardIos fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      :(
        <div style={{textAlign:"center"}}>
          <Typography variant="h6" style={{color:"#6A6A6A",textAlign:"center",padding:"2% 0%"}}>You don't have any Feedback Forms</Typography>
          </div>
      )}
      </Box>

      </Box>
    );
  };

  /* istanbul ignore next */
  sanitizeData = (htmlString:string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };
  
  /* istanbul ignore next */
  FeedbackReviewModal = () => {
    return (
      <Modal open={this.state.reviewModal} onClose={this.handleReviewModalClose}>
        <Container
          sx={{
            boxShadow: "0px 8px 32px 0px #0000000F",
            width: '640px',
            //height:'700px',
            minWidth: '380px',
            padding: '12px 24px 12px 24px',
            borderRadius: '8px 8px 32px 8px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            maxHeight:"90%",
            overflow:"scroll",
            left: '50%',
            top:"50%",
            transform: 'translate(-50%, -50%)',
            position:"absolute"
          }}
        > 
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="0px 0px 10px 0" borderBottom="1px solid #E2E8F0">
            <Typography
              style={{
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              New Feedback Form
            </Typography>
            <IconButton style={{color:"#000",fontWeight:"bold"}} data-testID="close-Modal" onClick={this.handleReviewModalClose}>
              <Close />
            </IconButton>
          </Box>
          {this.state.isLoadingPage?
          <Box sx={{ width: '100%', height: '100vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress
            style={{
              height: '60px',
              width: '60px',
              boxShadow: 'inset 0 0 1px 1px #D9d9d9',
              borderRadius: '50%'
            }}
            sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
          />
        </Box>:<>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflowY: 'auto',
            gap: '14px'
          }}>
            <FormControl fullWidth>
              <Typography
                style={{marginBottom:"1%", fontWeight: '700', fontSize: '14px', color: '#64748B' }}
              >Course</Typography>
              <Select
                labelId="client-select-label"
                id="client-select"
                placeholder="Select Course"
                value={this.state.selectedCourse.id}
                onChange={this.handleSelectedCourse}
                data-testID="clientSelect"
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span style={{
                      textOverflow:"ellipsis",
                      textWrap:"nowrap",
                      overflow:"hidden",
                      width:"80%"
                    }}>{this.state.selectedCourse.id == "0" ? "Select Course" : this.sanitizeData(this.state.selectedCourse.name)}</span>
                    <ExpandMore sx={{ color: 'white' }} />
                  </Box>
                )}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '&.MuiPaper-root': {
                        marginTop:"0.5%",
                        border: '1px solid #ccc',
                        boxShadow: 'none',
                        height:"40%"
                      },
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                {this.state?.courseData?.map((course,index) => (
                <StyledMenuItem key={course.id} value={course.id}
                 sx={{
                   display: "flex",
                   alignItems: "center",
                   gap: "8px",
                   backgroundColor:"#fff"
                 }}
                 >
                  <ListItemText
                  primary={this.sanitizeData(course.name)}
                  primaryTypographyProps={{
                 fontSize: "14px",
                 }}
                />

                 </StyledMenuItem>                 
                ))}
              </Select>
            </FormControl>
            <Box>
              <Typography
                style={{ fontWeight: '700', fontSize: '14px', color: '#334155',marginBottom:"1%" }}
              >Name of Feedback Form</Typography>
              <TextField placeholder="Builder's Feedback Form" data-testID="formName" fullWidth value={this.state.feedbackFormName} onChange={this.handleFeedbackFormName} />
            </Box>
            <Box sx={{padding:'0px'}}>
              <Button data-testID="addQuestion" style={{padding:'0px', display: 'flex', gap: '6px', textTransform: 'none' ,paddingTop:'16px'}} onClick={this.handleAddQuestion}>
                <AddCircleOutline />
                <Typography
                  style={{ fontWeight: '700', fontSize: '14px', color: '#1C1C1C' }}
                >
                  Add question
                </Typography>
              </Button>
            </Box>

            <Box>
              {this.state.questions.map((question, index) => (
                <Box key={index} display={"flex"} flexDirection={"column"} >
                  <Typography
                    style={{margin:'24px,0px 4px 0px', fontWeight: '700', fontSize: '14px', color: '#334155',padding:'0px' }}
                  >Question {index +1}</Typography>
                  <TextField
                    placeholder="Write question here"
                    sx={{padding:'5px 0px 0px 0px'}}
                    data-testID="question"
                    fullWidth
                    value={question.question}
                    onChange={(e) => this.handleQuestionChange(index, 'question', e.target.value)}
                  />
                  <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}  gap={'16px'}>
                    {this.state.questions.length ==1 && 
                    <Box display={"flex"} alignItems="center" margin={'24px 0px 20px 0px'} gap={"10px"}>
                      <Typography
                        style={{ fontWeight: '700', fontSize: '14px', color: '#000' }}
                      >Required: </Typography>
                      <Select
                        style={{height:"40px",borderRadius:"7px" }}
                        value={question.required ? 'Yes' : 'No'}
                        onChange={(e) => this.handleQuestionChange(index, 'required', e.target.value === 'Yes')}
                        IconComponent={ExpandMore}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </Box>}
                    <Box display={"flex"} alignItems="center" gap={"10px"} margin={'24px 0px 20px 0px'}>
                      <Typography
                        style={{
                         fontWeight: '700', fontSize: '14px', color: '#000'
                        }}>Type: </Typography>
                      <Select
                        style={{borderRadius:"7px",height:"40px" }}
                        value={question.type}
                        IconComponent={ExpandMore}
                        onChange={(e) => this.handleQuestionChange(index, 'type', e.target.value)}
                      >
                        <MenuItem value="free-text">Free text</MenuItem>
                        <MenuItem value="rating">Numeric 1-10</MenuItem>
                      </Select>
                    </Box>
                    <Box>
                      {this.state.questions.length > 1 && <IconButton data-testID={`delete${index}`} onClick={() => this.handleDeleteQuestion(index)}>
                        <DeleteOutline style={{ color: 'red' }} />
                      </IconButton>}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <Box display={"flex"} gap={"24px"} paddingTop={"10px"} justifyContent={"end"} borderTop="1px solid #E2E8F0">
            <Button
              style={{
                backgroundColor: '#F0F0F0',
                color: '#1C1C1C',
                padding: '16px 32px',
                borderRadius: '8px',
                textTransform: 'none', fontWeight: '700', fontSize: '16px',
              }}
              data-testID="closeModal"
              onClick={this.handleReviewModalClose}>Cancel</Button>
            <Button style={{
              backgroundColor: '#1C1C1C',
              color: 'white',
              textTransform: 'none',
              padding: '16px 32px',
              borderRadius: '8px', fontWeight: '700', fontSize: '16px',
            }}
            data-testID="saveBtn"
              onClick={this.handleSave}
            >
              Save
            </Button>
          </Box></>}
        </Container>
      </Modal>
    )
  }
  /* istanbul ignore next */
  FeedbackSummaryQuestion = () => {
    const { reviewSummary } = this.state;
    const {questions,user_feedback} =reviewSummary
    return (
      <>
     <TableContainer component={Paper} style={{marginTop:"3%"}}>
      <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography style={webStyle.tableHeader}>
              Questions
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography style={webStyle.tableHeader}>
              Average Rating
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography style={webStyle.tableHeader}>
              Number of Responses
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      {questions && questions.length>0 && questions.map((questionData:any)=>(<TableBody>
        <TableRow>
          <TableCell>
            <Typography variant="body2" fontWeight="bold">{questionData.question}</Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2" fontWeight="bold">
              {questionData.average_rating}
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="body2" fontWeight="bold">
              {questionData.number_of_responses}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>))}
    </Table>
     </TableContainer>
     {user_feedback && user_feedback.length>0 ?(<TableContainer component={Paper} style={{marginTop:"4%"}}>
     <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography style={webStyle.tableHeader}>
              Name of User
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography style={webStyle.tableHeader}>
              Rating
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {user_feedback && user_feedback.length>0 && user_feedback.map((user:any, index:any) => (
          <TableRow key={index}>
            <TableCell>
              <Typography variant="body2" fontWeight="bold">{user.user_name}</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body2" fontWeight="bold">
                {user.average_rating}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
     </TableContainer>)
     :(
      <div style={{textAlign:"center"}}>
          <Typography variant="h6" style={{color:"#6A6A6A",textAlign:"center",padding:"2% 0%"}}>No user responses are available.</Typography>
      </div>
     )}
    </>
    )
  }
  /* istanbul ignore next */
  individualFeedBackForm =()=>{
    const {feedback} = this.state
    const {questions,overall_rating,user_feedback,feedback_form} =feedback
    return(
      <>
      <TableContainer component={Paper} style={{marginTop:"3%"}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Questions
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Average Rating
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Number of Responses
              </Typography>
            </TableCell>
            <TableCell align="center">
              {/* <img src={DeleteIcon} alt="delete"/> */}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questions && questions.length > 0 && questions.map((item:any, index:any) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="body2" fontWeight="bold">{item.question}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {item.average_rating}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {item.number_of_responses}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <IconButton style={{color:"#000",fontWeight:"bold"}}
                onClick={()=>this.handlefeedbackQuestionOpen(item.question_id,feedback_form.id)}
                >
                  <ArrowForwardIos fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={1}>
              <Typography style={{fontSize:"18px"}} fontWeight="bold">
                Overall Rating
              </Typography>
            </TableCell>
            <TableCell align="center" colSpan={1}>
              <Typography style={{fontSize:"18px"}} fontWeight="bold">
                {overall_rating}
              </Typography>
            </TableCell>
            <TableCell align="left" colSpan={2}>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      </TableContainer>

      {user_feedback && user_feedback.length>0 ?(<TableContainer component={Paper} style={{marginTop:"4%"}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Name of User
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography style={webStyle.tableHeader}>
                Average Rating
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={webStyle.tableHeader}>
                Additional Comment
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user_feedback && user_feedback.length>0 && user_feedback.map((user:any, index:any) => (
            <TableRow key={index}>
              <TableCell style={{width:"30%"}}>
                <Typography variant="body2" fontWeight="bold">{user.user_name}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="body2" fontWeight="bold">
                  {user.average_rating}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">{user.suggestions}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
       </Table>
      </TableContainer>)
      :(
        <div style={{textAlign:"center"}}>
        <Typography variant="h6" style={{color:"#6A6A6A",textAlign:"center",padding:"2% 0%"}}>No user responses are available.</Typography>
        </div>
      )}
      </>
    )
  }

  /* istanbul ignore next */
  renderFeedback =()=>{
    const { selectedCourse, courseData ,sortBy ,duration,summaryModal,questionSummaryModal,feedbackName, searchFeedback} = this.state;
    return(
      <Box display={"flex"} overflow={'hidden'}>
            <Box>
              <Sidebar navigation={this.props.navigation} current="analytics" />
            </Box>
            <Box width={"100%"} display={"flex"} flexDirection={"column"} gap={"16px"} padding={"24px 18px"}  sx={{backgroundColor:'#F0F0F0', overflow:'auto'}}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
              
              <Box sx={webStyle.contentHeader}>
                <Box sx={webStyle.navigation}>
                  <Box sx={webStyle.breadcrumbButton}
                  onClick={()=>{this.handleCloseFeedback()}}
                  >
                    Analytics
                  </Box>
                    <ArrowForwardIos fontSize="small" />
                  <Box sx={webStyle.currentNavigationPage} 
                    onClick={()=>{this.handleCloseFeedback()}}>
                    Feedback aggregation
                    </Box>
                    {feedbackName !=="" && 
                    <>
                    <ArrowForwardIos fontSize="small" />
                    <Box onClick={()=>{this.handleCloseQuestion()}} sx={webStyle.currentNavigationPage}>{feedbackName}</Box>
                    </>}
                </Box>
                
              </Box>

               <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
               <Button
              data-testID="feedbackModal"
              style={{
                height: '100%',
                display: 'flex', textAlign: 'center', gap: '10px',
                alignItems: 'center', fontWeight: '700',
                padding: '10px 16px', borderRadius: '4px',
                fontSize: '16px', lineHeight: '24px',
                backgroundColor: '#000', color: '#fff',
                textTransform: 'none',
                width:"100%"
              }}
              onClick={this.handleReviewModalOpen}
            >
              <AddCircleOutline />
              <Typography
                style={{ fontWeight: '700', fontSize: '14px', color: '#fff' }}
              >
                Create New Review
              </Typography>
              </Button>
               </Box>

              </Box>
              <Box width={"fit-content"}>
              <AppBar position="static" style={{ maxHeight:'60px',padding:'10px' ,width: "60vw", boxShadow: 'none', borderRadius: '4px' }} color="secondary">
                  <Tabs
                    data-testID="Tabs"
                    style={{ gap: '10px' }}
                    value="Analytics"
                    textColor="secondary"
                    TabIndicatorProps={{
                      style: {
                        display: 'none'
                      },
                    }}
                    aria-label="analytics tabs"
                  >
                    {this.tabConfigurations.map((tab, index) => (
                      <Tab
                        key={index}
                        onClick={() => this.handleChangeTabAnalytics(tab.value)}
                        data-test-id={`tab-${index}`}
                        label={
                          <Typography
                              sx={{
                                padding: '12px 0',
                                width:'max-content',
                                fontWeight: '700',
                                textTransform: 'none',
                                fontSize: '12px',
                                fontFamily: 'Heebo-bold',
                                lineHeight: '18px',
                              }}
                            >
                              {tab.label}
                            </Typography>
                        }
                        sx={{
                          padding:'0 16px',
                          minHeight:0,
                          height:'100%',
                          borderRadius: '4px',
                          flex:1,
                          color: tab.value === 'Analytics' ? 'white' : '#6A6A6A',
                          backgroundColor: tab.value === 'Analytics' ? 'black' : 'inherit',
                          boxSizing:'border-box',
                          maxHeight:'60px',
                          '&.Mui-selected': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderBottom: 'none',
                          },
                        }}
                        value={tab.value}
                      />
                    ))}
                  </Tabs>
              </AppBar>
              </Box>



              <Box paddingTop={"1%"}>
              <Box display={"flex"} gap={"15px"} alignItems={"center"} height={"50px"}>
              <Box style={{
              width: '100%', height: '100%'
             }}>
            <TextField
              variant="outlined"
              placeholder="Search Form"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid",
                backgroundColor:"#fff",
                height:"100%",
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height:"100%",
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                },
              }}
              value={searchFeedback}
              onChange={(e)=>this.handleSearchFeedback(e)}
              data-testID="searchQuery"
              onKeyDown={(e) => this.handleKeyDown(e.key)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
              </Box>
              <div style={{marginTop:"3%", marginBottom: 2,width:"100%",display:"flex",justifyContent:"space-between",flexWrap:"wrap"}}>
              <div style={{width:"30%"}}>
              <FormControl>
              <Select
                labelId="client-select-label"
                id="client-select"
                placeholder="Select Course"
                value={selectedCourse.id}
                onChange={this.handleFilterSelectedCourse}
                IconComponent={(props) => (
                  <ExpandMore
                    {...props}
                    style={{
                      color: '#000',
                    }}
                  />
                )}
                style={{minWidth:"235px",width:"100%",backgroundColor:"#fff",color:"#000",fontWeight:"bold",height:"50px"}}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span style={{
                      textOverflow:"ellipsis",
                      textWrap:"nowrap",
                      overflow:"hidden",
                      width:"220px"
                    }}>{selectedCourse.id =="0"?`${selectedCourse.name} (${courseData?.length})`: this.sanitizeData(selectedCourse.name)}</span>
                  </Box>
                )}
                 MenuProps={{
                  PaperProps: {
                    sx: {
                      '&.MuiPaper-root': {
                        marginTop:"0.5%",
                        border: '1px solid #ccc',
                        boxShadow: 'none',
                        height:"35%"
                      },
                    },
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                <StyledMenuItem  value={"0"} style={{backgroundColor:"#fff"}}>
                <ListItemText
                   primary={"All Courses"}
                   primaryTypographyProps={{
                  fontSize: "14px",
                  }}
                 />
                 <ListItemIcon sx={{ minWidth: "32px" }}>
                <Checkbox
                  checked={selectedCourse.id == "0"}
                  value={"0"}
                  name="filter-radio-group"
                  sx={{
                    color: "#000",
                    "&.Mui-checked": { color: "#000" },
                  }}
                />
                </ListItemIcon>
                </StyledMenuItem>
                {courseData?.map((course) => (
                  <StyledMenuItem key={course.id} value={course.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    backgroundColor:"#fff"
                  }}
                  >
                   <ListItemText
                   primary={this.sanitizeData(course.name)}
                   primaryTypographyProps={{
                  fontSize: "14px",
                  }}
                 />
                 <ListItemIcon sx={{ minWidth: "32px" }}>
                <Checkbox
                  checked={selectedCourse.id === course.id}
                  value={course.id}
                  name="filter-radio-group"
                  sx={{
                    color: "#000",
                    "&.Mui-checked": { color: "#000" },
                  }}
                />
                </ListItemIcon>
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{...webStyle.gridWidth,flexWrap:"wrap"}}>      
            <FormControl sx={{
              display:"none",
              '&:focus-visible':{
                border: '0 !important',
                outline: '0 !important'
              }
            }}>
              <Select
                value={duration}
                onChange={(e:any) => this.handlefilterChange(e,"duration")}
                name="duration"
                style={webStyle.filterSelect}
                sx={{
                  '&:focus-visible':{
                    border: '1px solid #ccc',
                    outline: '1px solid #ccc'
                  }
                }}
                IconComponent={ExpandMore}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '&.MuiPaper-root': {
                        border: '1px solid #ccc',
                        boxShadow: 'none',
                        marginTop:"0.5%"
                      },
                      },
                  },
                }}
              >
                {["Published","Unpublished"].map((item)=>(
                  <MenuItem value={item} style={{fontWeight:duration==item?"bold":"",backgroundColor:"#fff"}}>
                  {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
         
            <FormControl>
              <Select
                value={sortBy}
                onChange={(e:any) => this.handlefilterChange(e,"sortBy")}
                name="sortBy"
                style={webStyle.filterSelect}
                IconComponent={ExpandMore}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      '&.MuiPaper-root': {
                        border: '1px solid #ccc',
                        boxShadow: 'none',
                        marginTop:"0.5%"
                      },
                    },
                  },
                }}
              >
                {["Sort by Name","Sort by Responses","Sort by Questions"].map((item)=>(
                   <MenuItem value={item} style={{fontWeight:sortBy==item?"bold":"",backgroundColor:"#fff"}}>
                   {item}
                   </MenuItem>
                ))}
              </Select>
            </FormControl>
         
          </div>
           </div>
                {summaryModal?
                this.individualFeedBackForm()
                :questionSummaryModal ?
                this.FeedbackSummaryQuestion()
                :this.FeedbackAggregation()
                }
              </Box>
            </Box>
          </Box>
    )
  }


  tabConfigurations = [
    { label: 'User Analytics', value: 'UserAnalytics' },
    { label: 'Client-Wide Analytics', value: 'ClientWideAnalytics' },
    { label: 'Course Analytics', value: 'CourseAnalytics' },
    { label: 'Feedback Aggregation', value: "Analytics" },
    { label: 'Reports', value: 'ReportsAnalytics' },
  ];

  // Customizable Area End

  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          {this.renderFeedback()}
          {this.FeedbackReviewModal()}
          <AdminFooter/>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  filterSelect:{
    width:"100%",
    minWidth:"195px",
    backgroundColor:"#fff",
    color:"#000",
    fontWeight:"bold",
    height:"50px"
  },
  gridWidth:{
     display:"flex",
     justifyContent:"end",
     gap:"10px",
     width:"70%",
  },
  tableHeader:{
    color:"#475569",
    fontWeight:"600",
    fontSize:"12px"
},
contentHeader: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '1%'
},
navigation: {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '5px',
  alignItems:"center",
},
currentNavigationPage: {
  fontWeight: '700',
  fontSize: '18px',
  padding: '2px 8px',
  cursor:"pointer"
},
breadcrumbButton: {
  fontSize: '18px',
  padding: '1px 8px',
  cursor:"pointer"
},
};
// Customizable Area End
