import React from "react";
// Customizable Area Start
import {
  Box, Container, Typography, IconButton, Popover, Grid, InputAdornment, Modal,
  Paper, MenuItem, MenuList, Chip, TextField, Button, Card, CardContent, Checkbox,
  Dialog, DialogActions, DialogContent, DialogTitle, InputBase, Menu
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  styled,
  StyledEngineProvider,
} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import { FilterList, KeyboardArrowDown, AddCircleOutline, MoreVert, Search, } from '@mui/icons-material';
import { courseImg, ellipse, timer } from "./assets";
import HtmlReactParser from 'html-react-parser';
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";
import DOMPurify from "dompurify";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  AddPackageModal = () => {
    return (
      <CustomDialog
        open={this.state.addPackageModal}
        onClose={this.closeAddPackageModal}
      >
        <DialogTitle className="dialog-title">
          <Typography className="heading">Create new package</Typography>
          <CloseIcon className="close-icon" data-testID="closeAddPackageModal" onClick={this.closeAddPackageModal} />
        </DialogTitle>
        <DialogContent className="heading">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box ml="17px">
                <Typography className="label-title">Title of Package</Typography>
                <CustomInput
                  fullWidth
                  data-testID="titlePackage"
                  placeholder="Title of package"
                  value={this.state.packageName}
                  onChange={this.handlePackageName}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton onClick={this.closeAddPackageModal}>
            Cancel
          </CancelButton>
          <DoneButton data-testID="addPackageTitle" onClick={this.addPackageTitle}>
            Add Courses
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderHTML = (data: any) => {
    return data.includes('<p') ? HtmlReactParser(data.replaceAll('<p>', '').replaceAll('</p>', '')) : data;
  }

  CreatePackageModal = () => {
    return (
      <Modal open={this.state.createPackageModal} onClose={this.closeCreatePackageModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={webStyle.container}>

          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              Add Courses in {this.state.packageName}
            </Typography>
            <IconButton data-testID="closeCreatePackageModal" onClick={this.closeCreatePackageModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              placeholder="Search Courses"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid"
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                  '& input': {
                    padding: '4px 8px',
                  },
                },
              }}
              value={this.state.courseSearchQuery}
              onChange={this.handleCourseSearchQuery}
              data-testID="handleCourseSearchQuery"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> <Search /></InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          {this.state.packageData && this.state.packageData.length > 0 &&
            <Box
              display={"flex"}
              gap={"10px"}
              width={"25%"}
              alignItems={"center"}>
              <Custombox
                checked={this.state.packageData.length > 0}
                onChange={this.handleDeselectAll}
                data-testID="deselectAll"
              />
              <Typography>{this.state.packageData.length} items selected</Typography>
            </Box>
          }
          <Box
            padding={2}
            width="100%"
            sx={{
              overflowY: 'scroll',
              height: '100%',
            }}
          >
            <Grid
              container
              spacing={4}
              justifyContent="center">
              {this.state.courseData.map((course, index) => {
                const courseImage = course.image || courseImg;
                return(
                <Grid item
                  padding={"10px"}
                  md={4}
                  key={course.id}
                  style={{
                    width: '300px',
                    height: '350px'
                  }}
                >
                  <Card style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }}>
                    <Box
                      position="relative">
                      <Box
                        style={{
                          position: 'relative',
                          height: '160px',
                          width: '100%',
                          backgroundSize: 'cover',
                          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImage})`,
                          backgroundPosition: 'center',
                        }}
                      />
                      <Box
                        position="absolute"
                        left={0}
                        top={0}
                        height="100%"
                        width="100%"
                        flexDirection="column"
                        display="flex"
                        justifyContent="space-between"
                        p={1}
                      >
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems={"center"}
                          padding={"8px"}>
                          <Chip style={{ borderRadius: '4px' }} label="Published" color="primary" />
                          <CustomCheckbox
                            checked={this.state.packageData.includes(course.id)}
                            data-testID={`check${index}`}
                            onChange={() => this.handleCourseSelection(course.id)}
                          />
                        </Box>
                        <Box
                          display={"flex"}
                          padding={"5px"}
                          alignItems={"center"}>
                          <img src={timer} height={"16px"} width={"16px"} alt="" />
                          <Typography
                            color={"white"}
                            margin={"10px"}>
                            {course.duration} Hours
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <CardContent>
                        <Typography variant="h6" component="div" sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 2,
                        }}> {this.sanitizeData(course.title)}</Typography>
                      <Typography variant="body2" color="text.secondary"> {course.course_module_count} Lessons</Typography>
                        <Typography variant="body2" color="text.secondary" sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 3,
                        }}>
                          {this.sanitizeData(course.description)}
                        </Typography>                    </CardContent>
                  </Card>
                </Grid>
                )}
              )}
            </Grid>
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <CancelButton data-testID="closeCreatePackageModal" onClick={this.closeCreatePackageModal}>Cancel</CancelButton>
            <DoneButton variant="contained" data-testID="createPackage" onClick={this.createPackage}>Create</DoneButton>
          </Box>
        </Container>
      </Modal>
    )
  }
  
  /* istanbul ignore next */
  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };

  UpdatePackageNameModal = () => {
    return (
      <CustomDialog
        open={this.state.updatePackageNameModal}
        onClose={this.closeUpdatePackageNameModal}
      >
        <DialogTitle className="dialog-title">
          <Typography className="heading">Update Package Name</Typography>
          <CloseIcon className="close-icon" data-testID="closeUpdatePackageNameModal" onClick={this.closeUpdatePackageNameModal} />
        </DialogTitle>
        <DialogContent className="heading">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box ml="17px">
                <Typography className="label-title">Title of Package</Typography>
                <CustomInput
                  fullWidth
                  placeholder="Title of package"
                  value={this.state.updatedPackageName}
                  data-testID="titlePackageUpdate"
                  onChange={this.handleUpdatePackageName}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton data-testID="closeUpdatePackageNameModal" onClick={this.closeUpdatePackageNameModal}>
            Cancel
          </CancelButton>
          <DoneButton data-testID="updatePackageTitle" onClick={this.UpdatePackageTitle}>
            Save Changes
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  UpdatePackageModal = () => {
    return (
      <Modal open={this.state.updatePackageModal} onClose={this.closeUpdatePackageModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={webStyle.container}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              Add Courses in {this.state.currentPackage?.attributes.name}
            </Typography>
            <IconButton data-testID="closeUpdatePackageModal" onClick={this.closeUpdatePackageModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <TextField
              variant="outlined"
              placeholder="Search Courses"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid"
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                  '& input': {
                    padding: '4px 8px',
                  },
                },
              }}
              value={this.state.courseSearchQuery}
              onChange={this.handleCourseSearchQuery}
              data-testID="handleCourseSearchQueryy"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
          <Box
            width="100%"
            padding={2}
            sx={{
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            <Grid container justifyContent="center">
              {this.state.courseData.map((course, index) => {
                const courseImage = course.image || courseImg;
                return(
                <Grid item
                  //padding={"10px"}
                  //md={4}
                  key={course.id} style={{ width: '300px', height: '350px' ,margin:'16px'}}>
                  <Card style={{ width: '100%', height: '100%', position: 'relative', boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.08)'}}>
                    <Box position="relative">
                      <Box
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: '160px',
                          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImage})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      />
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                      >
                        <Box display="flex" justifyContent="space-between" alignItems={"center"} padding={"8px"}>
                          <Chip style={{ borderRadius: '4px' }} label="Published" color="primary" />
                          <CustomCheckbox
                            data-testID={`checks${index}`}
                            checked={
                              this.state.updatedPackage?.addCourse?.includes(course.id) ?? false
                            }
                            onChange={() => this.handleUpdateCourseSelection(course.id)}
                          />
                        </Box>
                        <Box display={"flex"} padding={"5px"} alignItems={"center"}>
                          <img src={timer} height={"16px"} width={"16px"} alt="" />
                          <Typography color={"white"} margin={"10px"}>
                            {course.duration} Hours
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <CardContent>
                        <Typography variant="h6" component="div" sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 2,
                        }}>
                          {this.sanitizeData(course.title)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {course.course_module_count} Lessons
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          WebkitLineClamp: 3,
                        }}>
                          {this.sanitizeData(course.description)}
                        </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              )}
              )}
            </Grid>
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <CancelButton data-testID="closeUpdatePackageModal" onClick={this.closeUpdatePackageModal}>Cancel</CancelButton>
            <DoneButton data-testID="update" onClick={this.updatePackage}>Save Changes</DoneButton>
          </Box>
        </Container>
      </Modal>
    )
  }

  DeleteModal = () => {
    return (
      <CustomDialog
        open={this.state.deleteModal}
        onClose={this.handleDeleteModalClose}
        className="delete-popup"
      >
        <DialogTitle className="dialog-title">
          <CloseIcon className="close-icon" data-testID="close-Modal" onClick={this.handleDeleteModalClose} />
        </DialogTitle>
        <DialogContent className="heading">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box padding="0px 5px 0px 15px">
                <Typography className="delete-title">Are you sure you want to delete {this.state.currentPackage?.attributes.name} package?</Typography>
                <Typography className="delete-description">This action is irreversible, and you will lose the data permanently without any chance of recovery.</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            sx={{ borderRadius: "8px !important" }}
            onClick={this.handleDeleteModalClose}
          >
            Cancel
          </CancelButton>
          <DoneButton
            sx={{
              backgroundColor: "#FC5555 !important",
              borderRadius: "8px !important",
            }}
            data-testID="deletePackageBtn"
            onClick={() => this.deletePackage()}
          >
            Delete
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Wrapper>
            <Box display={"flex"} sx={webStyle.mainBlock}>
              <Box>
                <Sidebar navigation={this.props.navigation} current="packages" />
              </Box>
              <Box className="right-section">
                <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                  <Typography style={{ color: "#0F172A", fontFamily: 'Heebo', fontSize: '24px', fontWeight: "700", lineHeight: '32px', letterSpacing: '0.5px' }}>Packages</Typography>
                  <Button
                    style={{ color: '#ffffff', padding: '15px', minWidth: '250px', display: 'flex', gap: '8px', backgroundColor: '#1C1C1C', fontWeight: '700', fontFamily: 'heebo', fontSize: '16px', lineHeight: '24px', textTransform: "none" }}
                    startIcon={<AddCircleOutline />}
                    onClick={this.openAddPackageModal}
                    data-testID="createPackageBtn"
                  >
                    Create New Package
                  </Button>
                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  gap: '22px'
                }} >
                  <Box style={{ width: '75%' }}>
                    <TextField
                      variant="outlined"
                      placeholder="Search packages"
                      style={{
                        borderRadius: '4px',
                        border: "#E7E7E7 1px solid"
                      }}
                      sx={{
                        backgroundColor: "#fff",
                        '& .MuiOutlinedInput-root': {
                          height: '45px',
                          '& fieldset': {
                            borderRadius: '4px',
                          },
                          '& input': {
                            padding: '4px 8px',
                          },
                        },
                      }}
                      value={this.state.packageSearchQuery}
                      onChange={this.handlePackageSearchQuery}
                      data-testID="handlePackageSearchQuery"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Box>
                  <Box display={'flex'} gap={"5px"}>
                    <Box sx={{ whiteSpace: "nowrap" }}>Sort by :</Box>
                    <Box
                      data-testID='btnSort'
                      onClick={this.handlePopoverClick}
                      style={{ whiteSpace: "nowrap", cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                    >
                      {this.state.currentSortKey}
                      <KeyboardArrowDown />
                    </Box>

                    <Popover
                      data-test-id='btnPopover'
                      open={this.isSortPopoverOpen()}
                      anchorEl={this.state.anchorElSort}
                      onClose={()=>[
                        this.setState({anchorElSort:null})
                      ]}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                      style={{ boxShadow: 'none' }}
                    >
                      {this.sortKeys.map((item, index) => (
                        <MenuItem
                          key={'sortId' + index}
                          data-testID={`btnOptions${index}`}
                          onClick={() => this.handleSort(item)}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Popover>
                  </Box>
                </Box>

                {this.state.packages && this.state.packages.length > 0 ?
                  <Box className="package-wrapper">
                    <Grid container>
                      {this.state.packages.map((value, index) => (
                        <Grid item xs={6} key={index} className='package-card'>
                          <Paper elevation={2}
                            sx={{
                              boxShadow: "0px 2px 20px 0px #00000014",
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              padding: '12px 10px 12px 20px',
                              borderRadius: '8px',
                              gap: '16px'
                            }}>
                            <Box>
                              <Typography
                                style={{ fontFamily: 'Heebo-Medium', paddingBottom: '8px', fontWeight: '500', fontSize: '16px', lineHeight: '23.5px', color: '#000000' }}
                              >
                                {value.attributes.name}
                              </Typography>
                              <Box display={"flex"} gap={"5px"}>
                                <img src={ellipse} alt="" />
                                <Typography
                                  style={{
                                    fontFamily: 'Heebo',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    lineHeight: '17px',
                                    color: '#1C1C1C'
                                  }}
                                >
                                  {value.attributes.courses.length || 0} {value.attributes.courses.length > 1 ? 'Courses' : 'Course'}
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <IconButton
                                data-testID="packageOptions"
                                onClick={(event) => this.handlePackageOption(event, value)}
                              >
                                <MoreVert />
                              </IconButton>
                            </Box>
                            <CustomMenu
                              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                              anchorEl={this.state.userAnchorEl}
                              open={this.isPackagePopoverOpen()}
                              onClose={this.handlePopoverClose}
                              slotProps={{
                                paper: {
                                  elevation: 0,
                                  sx: {
                                    padding: '0',
                                    borderRadius: "4px",
                                    border: "1px solid #F0F0F0",
                                    boxShadow: "rgba(0, 0, 0, 0.02) 0px 0px 1px 0px",
                                  }
                                }
                              }}
                            >
                              <MenuItem className="menu-item" data-testID="UpdateName" onClick={this.handleUpdateName}>Update Name</MenuItem>
                              <MenuItem className="menu-item" data-testID="UpdateCourse" onClick={this.handleUpdateCourse} >Update Courses</MenuItem>
                              <MenuItem className="menu-item" data-testID="clonePackage" onClick={this.clonePackage}>Clone</MenuItem>
                              <MenuItem className="menu-item delete" data-testID="deletePackage" onClick={this.handleDeleteModalOpen}>Delete</MenuItem>
                            </CustomMenu>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  :
                  <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"} gap={"20px"} alignItems={"center"}>
                    <Typography
                      style={{
                        color: '#6A6A6A',
                        fontFamily: 'Heebo',
                        fontSize: '32px',
                        lineHeight: '47px',
                        fontWeight: '400'
                      }}
                    >
                      You don't have any packages
                    </Typography>
                    <Button
                      style={{
                        padding: '4px 60px',
                        border: '#6A6A6A 1px solid',
                        borderRadius: '4px',
                        fontFamily: 'Heebo',
                        lineHeight: '47px',
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#1C1C1C',
                        backgroundColor: "#fff",
                        textTransform: 'none',
                      }}
                      onClick={this.openAddPackageModal}
                    >
                      Create New Package
                    </Button>
                  </Box>
                }
              </Box>
            </Box>
            <AdminFooter/>
            {this.AddPackageModal()}
            {this.CreatePackageModal()}
            {this.UpdatePackageNameModal()}
            {this.UpdatePackageModal()}
            {this.DeleteModal()}
          </Wrapper>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  mainBlock: {
    height: "calc(100vh - 72px)",
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    backgroundColor:'#fafafa',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow: 1
  },
  container: {
    left: "50%",
    position: 'absolute',
    transform: "translate(-50%, -50%)",
    top: "50%",
    boxShadow: "0px 8px 32px 0px #0000000F",
    height: "75%",
    width: '70%',
    padding: '24px',
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '20px',
  }
};

const Wrapper = styled(Box)({
  "& .right-section": {
    width: "100%",
    padding: "25px 25px 25px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    overflow: "auto"
  },
  "& .package-wrapper": {
    padding: "3px"
  },
  '& .package-wrapper .package-card:nth-of-type(odd)': {
    paddingRight: "25px",
    [theme.breakpoints.down('sm')]: {
      paddingRight: "0px",
    }
  },
  "& .package-wrapper .package-card": {
    paddingTop: "20px"
  },
});

const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    backgroundColor: 'white',
    width: '20px',
    borderRadius: '6px',
    height: '24px',
    border: '1px solid black',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M5 12l5 5L20 7")',
      stroke: 'white',
      fill: 'none',
    },
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
});

const Custombox = styled(Checkbox)({
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    backgroundColor: 'white',
    border: '1px solid black',
    height: '20px',
    borderRadius: '6px',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M6 12h12")',
      fill: 'none',
      stroke: 'white',
    },
  },
});

const CustomDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    maxWidth: '600px',
    width: '100%',
    borderRadius: "8px",
  },
  "& .description": {
    color: "#0F172A",
    fontSize: "16px",
    marginBottom: "15px",
    fontFamily: "Heebo-Regular"
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "18px 15px 16px 30px",
    borderBottom: "1px solid #E2E8F0",
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
    },
  },
  "&.delete-popup": {
    "& .MuiPaper-root": {
      borderRadius: "8px 8px 32px 8px",
    },
    "& .dialog-title": {
      justifyContent: "end",
      borderBottom: "none",
    },
    "& .delete-title": {
      lineHeight: "32px",
      marginBottom: "10px",
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
      letterSpacing: "-0.5px"
    },
    "& .delete-description": {
      fontSize: "18x",
      fontFamily: "Heebo-Regular",
      letterSpacing: "-0.5px"
    },
    " & .dialog-action": {
      padding: "20px",
    }
  },
  "& .heading": {
    fontSize: "24px",
    fontFamily: "Heebo-Bold",
    letterSpacing: "-0.5px"
  },
  "& .label-title": {
    marginTop: "10px",
    fontSize: "14px",
    color: "#64748B",
    marginBottom: "5px",
    fontFamily: "Heebo-Bold",
  },
  "& .dialog-action": {
    padding: "15px 18px",
    borderTop: "1px solid #E2E8F0",
  },
});

const CustomInput = styled(InputBase)({
  width: "100%",
  borderRadius: "8px",
  padding: "7px 8px",
  backgroundColor: "#fff",
  border: "1px solid #A8A29E",
});

const CancelButton = styled(Button)({
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "black",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});

const CustomMenu = styled(Menu)({
  "& .MuiList-root": {
    padding: "0px"
  },
  "& .menu-item": {
    fontSize: "14px",
    margin: "5px 0px",
    borderRadius: "4px",
    fontFamily: "Heebo-Regular",
    "&.delete": {
      color: "#DC2626"
    }
  },
});
// Customizable Area End
