import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {uploadFileToApi} from '../../../components/src/UploadFile.web';
import { createRef } from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { Account, Group, GroupAccount, AccountAttributes } from "./types";
import { apiCall } from '../../../components/src/ApiCall.web';

export interface IValue {
  companyLogo: string;
  companyName: string;
  contactName: string;
  jobTitle: string;
  email: string;
  contactPhone: string;
}
type SizeImageType = 'large' | 'medium' | 'small'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  loading: boolean;
  isLoaded: boolean;
  name: string;
  searchTerm:string;
  createdAt: string;
  updatedAt: string;
  editMode: boolean;
  token: string;
  groupList: Group[];
  searchQuery: string,
  filteredAccountsData: Account[];
  isVisibleModal: boolean;
  isVisibleAddAccountModal: boolean;
  isVisibleDeleteAccountModal: boolean;
  metaData: {
    current_page: number,
    next_page: number | null,
    prev_page: number | null,
    total_count: number,
    total_pages: number
  },
  currentPage:number;
  itemsPerPage:number,

  accountsData: Account[];
  sortBy:string;
  currentStatus:string;
  anchorEl: { [key: string]: HTMLElement | null }; 
selectedCount:number;
  archiveIds: string [];
  modalAccData: Account[];
  selectedAccounts: GroupAccount[];
  dropdownAccountStatus: boolean;
  fieldError: boolean;
  filterAchorEl:any;
  filteredTableData:any;
  assignBy: string | undefined;
  openDialog: string;
  packagesList: any[],
  learningPathList: any[];
  selectedLearningPaths: any[];
  selectedPackages: any[];
  clientId: string | number | null;
  companyLogo: string;
  contactError: string;
  emailError: string;
  isDeleteClientModalOpen: boolean;
  selectedClientToDelete:any;
  logoLarge: any;
  logoMedium: any;
  logoSmall: any;
  errors: {
    companyLogoLarge?: string;
    companyLogoMedium?: string;
    companyLogoSmall?: string;
  };
  touched: {
    companyLogoLarge?: boolean;
    companyLogoMedium?: boolean;
    companyLogoSmall?: boolean;
  };
  selectedResolution: string | null;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountGroupsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupsApiCallId = "";
  getAccountsApiCallId = "";
  postGroupApiCallId = "";
  putGroupApiCallId = "";
  deleteGroupApiCallId = "";
  postAddAccountsApiCallId = "";
  postRemoveAccountsApiCallId = "";
  getClientsApiCallId = "";
  getLearningPathListApiCallId: string = "";
  getPackageListApiCallId: string = "";
  createClientApiCallId: string = "";
  assignCourseApiCallId: string = "";
  deleteClientApiCallId: string = "";
  exportClientApiCallId: string = "";
  archiveApiCallId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement>;
  fileInputLarge?: HTMLInputElement | null;
  fileInputMedium?: HTMLInputElement | null;
  fileInputSmall?: HTMLInputElement | null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "0",
      loading: false,
      isLoaded: false,
      name: "",
      createdAt: "",
      updatedAt: "",
      editMode: false,
      searchTerm: '',
      token: "",
      currentPage:0,
      itemsPerPage: 10,
      groupList: [],
      isVisibleModal: false,
      isVisibleAddAccountModal: false,
      isVisibleDeleteAccountModal: false,
      metaData: {
        current_page: 1,
        next_page: 0,
        prev_page: 0,
        total_count: 0,
        total_pages: 0
      },
      accountsData: [],
      filteredAccountsData:[],
      searchQuery: '',
      anchorEl: {} ,
      selectedCount:0,
      archiveIds: [],
      sortBy: 'Name (A-Z)',
      currentStatus:'all',
      modalAccData: [],
      dropdownAccountStatus: false,
      selectedAccounts: [],
      fieldError: false,
      filterAchorEl:null,
      filteredTableData:[],
      assignBy: "Learning Paths",
      openDialog: "",
      packagesList: [],
      learningPathList: [],
      selectedLearningPaths: [],
      selectedPackages: [],
      clientId: null,
      companyLogo: "",
      contactError: "",
      emailError: "",
      isDeleteClientModalOpen: false,
      selectedClientToDelete:null,
      logoLarge: null,
      logoMedium: null,
      logoSmall: null,
      errors: {},
      touched: {},
      selectedResolution: '',
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = createRef<HTMLInputElement>();
    this.fileInputLarge = null;
    this.fileInputMedium = null;
    this.fileInputSmall = null;
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getUsers(1);
  }

  receive = async (from: string, message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getGroupsApiCallId != null &&
      this.getGroupsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.setState({
          groupList: responseJson.data.sort(
            (a: Group, b: Group) => parseInt(a.id) - parseInt(b.id)
          ),
        });
      } else {
        this.showAlert("Alert", "No Data");
        this.setState({ groupList: [] });

        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postGroupApiCallId != null &&
      this.postGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Received", message);

      const token = localStorage.getItem("token");
      this.setState({ token: token ? token : "" });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.putGroupApiCallId != null &&
      this.putGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.deleteGroupApiCallId != null &&
      this.deleteGroupApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.message) {
        this.getGroups(this.state.token);
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAccountsApiCallId != null &&
      this.getAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        const data = responseJson.data.map((account: Account) => {
          return { ...account, isSelected: false };
        });
        this.setState({
          accountsData: data,
        });
      } else {
        this.setState({ accountsData: [] });
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postAddAccountsApiCallId != null &&
      this.postAddAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleAddAccountModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.postRemoveAccountsApiCallId != null &&
      this.postRemoveAccountsApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson.data) {
        this.getGroups(this.state.token);
        this.setState({ isVisibleDeleteAccountModal: false });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleResponse(apiRequestCallId, responseJson);
    }
  };

  handleResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getLearningPathListApiCallId) {
        this.setState({ learningPathList: responseJson.data, loading: false });
      } else if (apiRequestCallId === this.getPackageListApiCallId) {
        this.setState({ packagesList: responseJson.data, loading: false });
      } else if (apiRequestCallId === this.createClientApiCallId) {
        this.handleClientResponse(responseJson);
      } else if (apiRequestCallId === this.assignCourseApiCallId) {
        if (responseJson.message) {
          toast.success(responseJson.message);
          this.setState({ openDialog: "", selectedLearningPaths: [], selectedPackages: [] });
        }
        this.setState({ isLoaded: false });
      } else {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
      if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0].token) {
        toast.error(responseJson.errors[0].token);
        this.props.navigation.navigate("AdminLogin");
      }
    }
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: { error?: string; message?: string }) => {
    if (apiRequestCallId === this.deleteClientApiCallId) {
      if (responseJson.message) {
        toast.success(responseJson.message);
        const metaData = this.state.metaData;
        const remainingItems = (metaData.total_count - 1) % this.state.itemsPerPage;
        const isEmpty = metaData.current_page === metaData.total_pages && remainingItems === 0;
        const pageToFetch = metaData.current_page === metaData.total_pages && isEmpty
          ? Math.max(metaData.current_page - 1, 1)
          : metaData.current_page;
        this.getUsers(pageToFetch);
      } else if (responseJson.error) {
        toast.error(responseJson.error);
      }
    } else {
      this.handleApiResponse1(apiRequestCallId, responseJson);
    }
  }

  handleApiResponse1 = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.archiveApiCallId) {
      if (responseJson.message) {
        toast.success(responseJson.message);
        const updatedAccounts = this.state.accountsData.map(account => {
          if (this.state.archiveIds.includes(account.id)) {
            return {
              ...account,
              isSelected: false,
              attributes: {
                ...account.attributes,
                status: "archive",
              }
            };
          }
          return account;
        });
        this.setState({ archiveIds: [], selectedCount: 0, accountsData: updatedAccounts });
      }
    } else if(apiRequestCallId === this.getClientsApiCallId){
      if (responseJson.clients) {
        const data = responseJson.clients.data.map((account: Account & { id: string }) => ({
          id: account.id,
          type: account.type,
          isSelected: this.state.archiveIds.includes(account.id),
          attributes: {
            company_name: account.attributes.company_name,
            contact_name:	account.attributes.contact_name,
            contact_email	:	account.attributes.contact_email,
            count: account.attributes.accounts_count,
            contact_job_title:account.attributes.contact_job_title,
            contact_phone:account.attributes.contact_phone,
            created_at:account.attributes.created_at,
            updated_at:account.attributes.updated_at,
            status: account.attributes.status
          }
        }))
        this.setState({
          isLoaded: false,
          metaData: responseJson.meta,
          accountsData:data, filteredAccountsData: data
        });
      } else if (responseJson.error === "No clients are present!") {
        this.setState({
          isLoaded: false,
          metaData: {
            current_page: 0,
            next_page: 0,
            prev_page: 0,
            total_count: 0,
            total_pages: 0
        },
          accountsData:[],
          filteredAccountsData: []
        });
      }
    } else if(apiRequestCallId === this.exportClientApiCallId){
      if(responseJson.csv_url) {
        this.handleExportResponse(responseJson.csv_url.download_url);
      }
      this.setState({ isLoaded: false });
    }
  }

  handleExportResponse = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "filename.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  }

  handleClientResponse = (responseJson: { data: { id: string }, errors: string | string[]; }) => {
    if(responseJson.errors && Array.isArray(responseJson.errors)) {
      if(responseJson.errors.includes("Contact email has already been taken")) {
        this.setState({ emailError: "*Contact email has already been taken" });
      }
      if(responseJson.errors.includes("Contact phone Invalid or unrecognized phone number")) {
        this.setState({ contactError: "*Contact phone Invalid or unrecognized phone number" });
      }
      if(responseJson.errors.includes("Contact phone has already been taken")) {
        this.setState({ contactError: "*Contact phone has already been taken" });
      }
      if(responseJson.errors.includes("Contact phone must be a valid phone number with 11 to 13 digits")) {
        this.setState({ contactError: "*Contact phone must be a valid phone number with 11 to 13 digits" });
      }
      this.setState({  loading: false });
    } else if(responseJson.data) {
      this.getUsers(1);
      this.setState({ openDialog: "assign", clientId: responseJson.data.id, loading: true, assignBy: "Learning Paths",logoSmall:null,logoMedium:null,logoLarge:null },() => {
        this.getLearningPathList();
      });
    }
  }

  handleInfo = (resolution : string) => () => {

    this.setState((prevState) => ({
      selectedResolution: prevState.selectedResolution === resolution ? null : resolution,
    }));
  }

  handleNewClick = () => {
    this.setState({ openDialog: "create" });
  }

  onCloseDialog = () => {
    this.setState({ openDialog: "", selectedLearningPaths: [], selectedPackages: [],logoLarge:'',logoMedium:'',logoSmall:'' });
  }

  showFilter = (event:any) => {
    this.setState({filterAchorEl:event.currentTarget})
  }
  handleFilterClose = () => {
    this.setState({filterAchorEl:null})
  }
  
  filterAccountsData = () => {
    const { searchQuery, accountsData } = this.state;

    if (!searchQuery.trim()) {
      this.setState({ accountsData: this.state.filteredAccountsData });
      return;
    }

    const filteredData = accountsData.filter((account: Account) => {
      const fullName = `${account.attributes.contact_name}`.toLowerCase();
      const email = `${account.attributes.contact_email}`.toLowerCase();
      const query = searchQuery.toLowerCase();

      return fullName.includes(query) || email.includes(query);
    });

    this.setState({ accountsData: filteredData });
  };

  debouncedSearch = _.debounce(() => {
    this.getUsers(1);
  }, 500);

  handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.debouncedSearch();
    });
  };

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>, id: string) => {
    this.setState({
      anchorEl: { ...this.state.anchorEl, [id]: event.currentTarget }
    });
  };
  
  handleMenuClose = (id: string) => {
    this.setState({
      anchorEl: { ...this.state.anchorEl, [id]: null }
    });
  };
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  handleCheckboxChange = (id: string, isSelect: boolean) => {
  const updatedAccounts = this.state.accountsData.map(account => {
    if (account.id === id) {
      return { ...account, isSelected: !account.isSelected };
    }
    return account;
  });
  
  let tempArchiveIds = [...this.state.archiveIds];
  if(isSelect) {
    tempArchiveIds = tempArchiveIds.filter((item) => item !== id);
  } else {
    tempArchiveIds.push(id);
  }
  this.setState({ accountsData: updatedAccounts, selectedCount: tempArchiveIds.length, archiveIds: tempArchiveIds });
  };
  isStringNullOrBlank = (str: string) => {
    return str === null || str.length === 0;
  };

  hideModal = () => {
    this.setState({ isVisibleModal: !this.state.isVisibleModal });
  };

  hideAddAccountModal = () => {
    this.setState({ isVisibleAddAccountModal: false });
  };

  hideDeleteAccountModal = () => {
    this.setState({ isVisibleDeleteAccountModal: false });
  };

  showAddModal = () => {
    this.setState({
      name: "",
      editMode: false,
      fieldError: false,
      isVisibleModal: !this.state.isVisibleModal,
    });
  };

  handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sortBy = event.target.value as string;
    this.setState({ sortBy }, () => {
      this.getUsers(1);
    });
  }

  handleStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const status = event.target.value as string;
    this.setState({ currentStatus: status }, () => {
      this.getUsers(this.state.currentPage);
    })
  }

  handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sortBy = event.target.value as string;
    let sortedData = [...this.state.accountsData];
  
    if (sortBy === 'A-Z') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        firstAccount.attributes.company_name.localeCompare(secondAccount.attributes.company_name)
      );
    } else if (sortBy === 'Z-A') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        secondAccount.attributes.company_name.localeCompare(firstAccount.attributes.company_name)
      );
    } else if (sortBy === 'old-new') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        new Date(firstAccount.attributes.created_at).getTime() - new Date(secondAccount.attributes.created_at).getTime()
      );
    } else if (sortBy === 'new-old') {
      sortedData.sort((firstAccount: any, secondAccount: any) =>
        new Date(secondAccount.attributes.created_at).getTime() - new Date(firstAccount.attributes.created_at).getTime()
      );
    } else if (sortBy === 'relevance') {
      // Handle 'relevance' sorting if applicable
      // Implement your relevance logic here, if required
    }
  
    this.setState({ accountsData: sortedData, sortBy });
  };

  handleChangePage = (event:any,newPage:any) => {
    this.setState({currentPage:newPage});
  };

  handleInputName = (name: string) => {
    this.setState({ name, fieldError: false });
  };

  showHideAddAccountModal = () => {
    this.setState({
      isVisibleAddAccountModal: !this.state.isVisibleAddAccountModal,
      editMode: true,
    });
  };

  handleAddAccounts = (group: Group) => {
    let accArr: any[] = [];
    if (group.attributes.accounts.length > 0) {
      accArr = this.state.accountsData.filter(
        (ar) =>
          !group.attributes.accounts.find((rm) => rm.id.toString() === ar.id)
      );
    } else {
      accArr = this.state.accountsData;
    }

    this.setState({
      id: group.id,
      name: group.attributes.name,
      modalAccData: accArr,
    });
    this.showHideAddAccountModal();
  };

  showHideDeleteAccountModal = () => {
    this.setState({
      isVisibleDeleteAccountModal: !this.state.isVisibleDeleteAccountModal,
      editMode: true,
    });
  };

  handleDeleteAccounts = (group: Group) => {
    const accData = group.attributes.accounts.map((account: GroupAccount) => {
      return { ...account, isSelected: false };
    });
    this.setState({
      id: group.id,
      name: group.attributes.name,
      selectedAccounts: accData,
    });
    this.showHideDeleteAccountModal();
  };

  handleEditGroup = (group: Group) => {
    this.setState({ id: group.id, name: group.attributes.name });
    this.showEditModal();
  };

  showEditModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      editMode: true,
    });
  };

  expandAccountsView = () => {
    this.setState({ dropdownAccountStatus: !this.state.dropdownAccountStatus });
  };

  handleAccountSelect = (accountId: string) => {
    const newData = this.state.modalAccData.map((account: Account) => {
      if (account.id === accountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ modalAccData: newData });
  };
  deselectAll = () => {
    const updatedAccounts = this.state.accountsData.map(account => {
      return { ...account, isSelected: false };
    });
    this.setState({
      accountsData: updatedAccounts,
      selectedCount: 0,
      archiveIds: []
     });
  }
  handleDeleteAccountSelect = (groupAccountId: number) => {
    const newData = this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.id === groupAccountId) {
        return { ...account, isSelected: !account.isSelected };
      }
      return account;
    });
    this.setState({ selectedAccounts: newData });
  };

  handleGetGroups = () => {
    this.getGroups(this.state.token);
    this.getAccounts(this.state.token);
  };
  handleClientClick = (clientID : string,company_name:string) => {
    localStorage.setItem('SelectedClientID',clientID);
    localStorage.setItem('SelectedClientName',company_name);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationManageClientMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleEditClientClick = (clientID : string) => {
    localStorage.setItem('SelectedClientID',clientID);
    const msg: Message = new Message(
      getName(MessageEnum.NavigationClientProfileMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getGroups = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.groupsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addGroup = () => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.postGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  editGroup = (groupId: string) => {
    if (this.isStringNullOrBlank(this.state.name)) {
      this.setState({ fieldError: true });
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
      };
      const httpBody = {
        name: this.state.name,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.putGroupApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.groupsApiEndPoint + "/" + `${groupId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  deleteGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAccounts = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.tasksAccountsApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUsers = (page: number) => {
    this.setState({ isLoaded: true });
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getClientsApiCallId = requestMessage.messageId;
    let endPoint = `${configJSON.getGroupsApiCallId}?page=${page}&per_page=${this.state.itemsPerPage}&search=${this.state.searchQuery}&sort_by=${this.state.sortBy}`
    if (['archive','active'].includes(this.state.currentStatus)) {
      endPoint = endPoint + `&status=${this.state.currentStatus}`
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSaveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.modalAccData.map((account: Account) => {
      if (account.isSelected) {
        accountIds.push(account.id);
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postAddAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/add_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRemoveAccountsToGroup = (groupId: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const accountIds: string[] = [];
    this.state.selectedAccounts.map((account: GroupAccount) => {
      if (account.isSelected) {
        accountIds.push(account.id.toString());
      }
    });
    const httpBody = {
      account_ids: accountIds,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postRemoveAccountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.groupsApiEndPoint + "/" + `${groupId}` + "/remove_accounts"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  handleDrop = (event: React.DragEvent<HTMLDivElement>, size: SizeImageType) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      this.handleLogoUpload(file, size);
    }
  };

  handleDropEvent = (size: SizeImageType) => (e: React.DragEvent<HTMLDivElement>) => {
      this.handleDrop(e, size);
  };

  deleteLogo = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
    this.setState({ companyLogo: "" });
  }

  handleLogo = (file: Blob | undefined) => {
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];
    if (!file) return;
    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          this.setState({ companyLogo: reader.result as string });
        }
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  handleAssignByValue = (value: string | undefined) => {
    this.setState({ assignBy: value }, () => {
      if (this.state.packagesList.length === 0) {
        this.getPackageList();
      }
    });
  }

  selectLearningPathValue = (id: string, courses: { id: string }[]) => {
    this.setState(prevState => {
      const existingPath = prevState.selectedLearningPaths.find((path) => path.learningPathId === id);
      if (existingPath) {
        return {
          selectedLearningPaths: prevState.selectedLearningPaths.filter((path) => path.learningPathId !== id)
        };
      } else {
        return {
          selectedLearningPaths: [
            ...prevState.selectedLearningPaths,
            {
              learningPathId: id,
              selectedCourses: courses.map((course) => course.id)
            }
          ]
        };
      }
    });
  }

  selectSingleCourse = (learningPathId: string, courseId: string) => {
    this.setState(prevState => {
      const existingPath = prevState.selectedLearningPaths.find((path) => path.learningPathId === learningPathId);
      if (existingPath) {
        const courseSelected = existingPath.selectedCourses.includes(courseId);
        const updatedCourses = courseSelected
          ? existingPath.selectedCourses.filter((id: string) => id !== courseId)
          : [...existingPath.selectedCourses, courseId];
        if (updatedCourses.length === 0) {
          return {
            selectedLearningPaths: prevState.selectedLearningPaths.filter((path) => path.learningPathId !== learningPathId)
          };
        } else {
          return {
            selectedLearningPaths: prevState.selectedLearningPaths.map((path) =>
              path.learningPathId === learningPathId
                ? { ...path, selectedCourses: updatedCourses }
                : path
            )
          };
        }
      } else {
        return {
          selectedLearningPaths: [
            ...prevState.selectedLearningPaths,
            { learningPathId, selectedCourses: [courseId] }
          ]
        };
      }
    });
  }

  selectPackage = (packageId: string, courses: { id: string }[]) => {
    this.setState((prevState) => {
      const isSelected = prevState.selectedPackages.find((packageObj) => packageObj.id === packageId);
      const updatedSelectedPackages = isSelected
        ? prevState.selectedPackages.filter((packageObj) => packageObj.id !== packageId)
        : [
          ...prevState.selectedPackages,
          {
            id: packageId,
            selectedCourses: courses.map((course) => course.id)
          }];
      return { selectedPackages: updatedSelectedPackages };
    });
  }

  onNameChange = () => {
    this.setState({ emailError: "" });
  }

  onPhoneChange = () => {
    this.setState({ contactError: "" });
  }

  getLearningPathList = async () => {
    this.setState({ loading: true });
    this.getLearningPathListApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_categories/learning_paths`,
    });
  }

  getPackageList = async () => {
    this.setState({ loading: true });
    this.getPackageListApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_content_management/course_packages`,
    });
  }

  onExport = async() => {
    this.setState({ isLoaded: true });
    this.exportClientApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/clients/csv_client_export`,
    });
  }

  createClient = async (values: IValue) => {
    console.log("createClient called with values:", values);
    this.setState({ loading: true });
    this.createClientApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_account_groups/clients`,
      body: {
        "client": {
          "company_logo_small": this.state.logoSmall,
          "company_logo_medium": this.state.logoMedium,
          "company_logo_large": this.state.logoLarge,
          "company_name": values.companyName,
          "contact_name": values.contactName,
          "contact_job_title": values.jobTitle,
          "contact_email": values.email,
          "contact_phone": values.contactPhone
        }
      }
    });
  }

  onArchive = async () => {
    this.setState({ loading: true });
    const archiveIds = this.state.archiveIds.map((id) => "client_ids[]=" + id).join('&');
    this.archiveApiCallId = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/clients/archive_clients?${archiveIds}`,
    });
  }

  handleDeleteClientConfirm = (id: string | null) => {
    const client = this.state.accountsData.find(account => account.id == id);
    this.setState({ isDeleteClientModalOpen: true, selectedClientToDelete: client });
  }

  onDeleteClienFeedback = (isDelete: boolean = false) => {
    if (isDelete) {
      this.deleteClient(this.state.selectedClientToDelete.id);
    }
    this.setState({ isDeleteClientModalOpen: false, selectedClientToDelete: null });
  }

  deleteClient = async (id: string | number) => {
    this.setState({ loading: true });
    this.deleteClientApiCallId = await apiCall({
      method: "DELETE",
      contentType: "application/json",
      token: localStorage.getItem("token"),
      navigation: this.props.navigation,
      endPoint: `/bx_block_account_groups/clients/${id}`,
    });
  }

  assignCourses = async () => {
    this.setState({ isLoaded: true });
    const learningPathData = this.state.selectedLearningPaths.map((item) => ({
      "learning_path_data": {
        "learning_path_id": item.learningPathId,
        "selected_course_ids": item.selectedCourses
      }
    }));

    const packageData = this.state.selectedPackages.map((item) => ({
      "course_package_data": {
        "course_package_id": item.id,
        "selected_course_ids": item.selectedCourses
      }
    }));

    this.assignCourseApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_content_management/assign_courses_and_learning_paths`,
      body: {
        "id": this.state.clientId,
        "data": [
          ...learningPathData,
          ...packageData
        ]
      }
    });
  }

  handleLogoUpload = async (file: File, size: SizeImageType) => {
    const res: any = await uploadFileToApi(file);
    if (res) {
      this.setState((prevState) => ({
        logoSmall: size === 'small' ? res.image_url : prevState.logoSmall,
        logoLarge: size === 'large' ? res.image_url : prevState.logoLarge,
        logoMedium: size === 'medium' ? res.image_url : prevState.logoMedium,
      }));
    }
  };

  handleDeleteLogo = (size: SizeImageType) => () => {
    this.setState({
      ...this.state,
      [`logo${size.charAt(0).toUpperCase() + size.slice(1)}`]: null
    });
  };
  assignRef = (size: SizeImageType) => (ref: HTMLInputElement | null) => {
    if (size === 'large') {
      this.fileInputLarge = ref;
    } else if (size === 'medium') {
      this.fileInputMedium = ref;
    } else if (size === 'small') {
      this.fileInputSmall = ref;
    }
  };
  handleFileChange = (size: SizeImageType) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      this.handleLogoUpload(file, size);
    }
  };
  handleReuploadClick = (inputRef: HTMLInputElement | null) => () => {
    if (inputRef) {
      inputRef.click();
    }
  };
  handleFileUpload = (inputRef: HTMLInputElement | null) => () => {
    if (inputRef) {
      inputRef.click();
    }
  }
  // Customizable Area End
}
