import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Grid } from "@mui/material";
import Header from "../../../components/src/Header.web";
import {TimerOutlined} from '@mui/icons-material'
import { styled } from "@mui/material/styles";

// Customizable Area End
import MyLearningController, {
  Props,
} from "./MyLearningController.web";
import { myLearningCourseImg,} from "./assets";
import MixpanelIntegration from "../../../blocks/mixpanelintegration/src/MixpanelIntegration";
import Loader from "../../../components/src/Loader.web";

export default class MyLearning extends MyLearningController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderCourseList = (learningPath: any)=>{
    return (
        <Box display={'flex'} flexDirection={'column'} marginBottom={'30px'}>
            <Typography fontFamily={"Heebo-Medium"}fontSize={18} fontWeight={'500'}>{learningPath.title}</Typography>
            <Grid container spacing={4} marginTop={0} >
                {learningPath.courses.map((course:any, index:number) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={course.id} style={{overflow:'hidden',height:'40%'}}>
                        <Box data-test-id={'course-' + index} sx={styles.courseCard} onClick={
                          ()=>{
                            this.props.navigation.navigate('CourseDescription',{path:{courseId:course.id}})
                            localStorage.setItem("assign_course_id",course.assign_course_id)
                          }}>
                            <BoxThumbnail>
                                <img src={course.thumbnail_image?.url || myLearningCourseImg} alt="ux_courses" />
                                <div style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px',
                                    height: '65px',
                                    boxSizing: 'border-box'
                                }}>
                                </div>
                                <Typography
                                    style={{
                                        display: 'flex',
                                        color: '#fff',
                                        padding: '10px',
                                        fontSize: '14px',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TimerOutlined style={{ marginRight: '5px' }} />{course.duration} Hours - {course.completed_percentage}% Completed
                                </Typography>
                            </BoxThumbnail>
                            <Box sx={styles.courseCardDescription}>
                              <Box sx={{ fontSize: '16px',height:'48px',overflow:'hidden',textOverflow:'ellipsis',fontFamily:"Heebo-Medium" }}>
                                    <p dangerouslySetInnerHTML={{__html:this.sanitizeData(course.title)}}/>
                              </Box>
                              <Box sx={{ fontSize: '12px', color: '#171717' }}>{course.lessons} lessons</Box>
                              <Box sx={{ fontSize: '14px', color: "#8A8A8A", height: '110px', overflow: 'hidden' }}>
                                <b style={{ color: "#1A1A1A", textOverflow: 'ellipsis',fontFamily:"Heebo-Medium" }}>You will learn:</b> 
                                <Typography style={{
                                  fontFamily:"Heebo-Medium !important",
                                  height:"100%",
                                textOverflow:'ellipsis',
                                display: '-webkit-box',  
                                WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  overflow: 'hidden'}}>
                                <div dangerouslySetInnerHTML={{ __html: this.sanitizeData(course.description) }}  style={{fontFamily:"Heebo-Medium"}}/>
                              </Typography>
                              </Box>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Header
          navigation={this.props.navigation}
          islogin={true}
          isHeader={true}
        >
          <Loader loading={this.state.isLoadingData} />
            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '30px', justifyContent: 'space-between' }}>
              <Box
                display={"flex"} justifyContent={"space-between"} padding={"10px 0px 20px 0px"}
              >
                <Box sx={{ display: 'flex', gap: '16px', padding: '15px 0px', alignItems: 'center', fontSize: '14px',fontFamily:"Heebo-Regular" }}>
                  <Button onClick={()=>this.onChangeTab("allCourse")} sx={{ ...styles.commonButton, ...(this.state.currentTab === "allCourse" && styles.blackButton)}} data-test-id="allCourse">
                    All Courses ({this.countCourses('allCourse')})
                  </Button>
                  <Button sx={{ ...styles.commonButton, ...(this.state.currentTab === "ongoing" && styles.blackButton)}} data-test-id="ongoing-tab" onClick={()=>this.onChangeTab("ongoing")} >
                    Ongoing ({this.countCourses('ongoing')})
                  </Button>
                  <Button sx={{ ...styles.commonButton, ...(this.state.currentTab === "completed" && styles.blackButton)}} data-test-id="completed-tab" onClick={()=>this.onChangeTab("completed")} >
                    Completed ({this.countCourses('completed')})
                  </Button>
                  <Button onClick={()=>this.onChangeTab("insight")} sx={{ ...styles.commonButton, ...(this.state.currentTab === "insight" && styles.blackButton)}} data-test-id="insight">
                    Learning Insights
                  </Button>
                </Box>
              </Box>
              {this.state.currentTab === "insight" ? <MixpanelIntegration navigation={this.props.navigation} /> :
                <Box display={'flex'} flexDirection={'column'} sx={{ boxSizing: 'border-box' }}>
                  {this.state.mylearningPageShowingData.map(course => this.renderCourseList(course))}
                </Box>
              }
            </Box>
        </Header>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const BoxThumbnail = styled(Box)({
    boxShadow: 'inset 0px -130px 50px -85px rgba(0,0,0,0.9)',
    boxSizing:'border-box',
    position:'relative',
    width: '100%',
    display:'flex',
    flexDirection:'column',
    justifyContent:'space-between',
        height:'160px',
    "&>img":{
        position: 'absolute',
        width:'100%',
        height:'100%',
        zIndex:'-1'
    }
})
const styles = {
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },
  commonButton:{
    fontSize:'14px',
    color: 'black', 
    backgroundColor: 'white', 
    textTransform: 'none',
    padding:'5px 15px',
    fontWeight:"400",
    fontFamily:"Heebo-Regular"
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    height:'100%',
    width: '100%',
    maxWidth:'350px',
    borderRadius:'8px',
    boxShadow:'0px 2px 20px 0px #00000014',
    marginBottom:'10px',
    overflow:'hidden',
    cursor:'pointer'
},
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
}
// Customizable Area End
