import React from "react";
// Customizable Area Start
import {
  Typography, InputBase, Box, Grid, Button, Hidden, Slide, Backdrop,
  Dialog, DialogTitle, DialogContent, DialogActions, Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik } from "formik";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CustomisableUserProfilesController from "./CustomisableUserProfilesController";
import Header from "../../../components/src/Header.web";
import Loader from "../../../components/src/Loader.web";

const logoutIcon = require('../assets/logout.svg').default;

const Transition = React.forwardRef((props: { children: React.ReactElement }, ref: React.Ref<HTMLInputElement>) => {
  return <Slide direction="up" ref={ref} {...props} />;
});
// Customizable Area End

export default class UserProfile extends CustomisableUserProfilesController {
  // Customizable Area Start
  resetPasswordSchema = () => {
    return Yup.object().shape({
      password: Yup.string()
        .min(8, '*Password must be at least 8 characters long')
        .matches(/[a-z]/, '*Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, '*Password must contain at least one uppercase letter')
        .matches(/\d/, '*Password must contain at least one digit')
        .matches(/^(?=.*[!@#$%^&*(),.?":{}|<>]).+$/, '*Password must contain at least one special character')
        .required('*Password is required'),
      confirmPassword: Yup.string()
        .required("*Confirm password is required")
        .oneOf([Yup.ref("password"), null], "*Password do not match. Please re-enter password."),
    });
  }

  changePasswordDialog = () => {
    const isMobile = window.innerWidth <= 600;
    return (
      <CustomDialog
        open={this.state.openDialog === "password"}
        data-test-id="password-dialog"
        className="change-password"
        onClose={this.handleClose}
        scroll={isMobile ? "paper" : "body"}
        TransitionComponent={isMobile ? Transition : undefined}
      >
        <DialogTitle className="dialog-title">
          <Hidden smDown>
            <Typography className="heading">Change Password</Typography>
          </Hidden>
          <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.handleClose} />
        </DialogTitle>
        <Formik
          data-test-id="formik"
          initialValues={{
            password: "",
            confirmPassword: ""
          }}
          enableReinitialize
          validationSchema={this.resetPasswordSchema}
          onSubmit={(values) => {
            this.resetPasswordApi(values);
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit }) => (
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className="form"
            >
              <DialogContent className="content">
                <Typography className="description">Your new password should be different from the previous one</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="label-title">Create new password</Typography>
                    <CustomInput
                      fullWidth
                      data-test-id="password"
                      placeholder="New password"
                      onChange={(e) => {
                        setFieldValue("password", e.target.value);
                      }}
                    />
                    {touched.password && errors.password &&
                      <div className="text-danger">{errors.password}</div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="label-title">Confirm password</Typography>
                    <CustomInput
                      fullWidth
                      data-test-id="confirm-password"
                      placeholder="Confirm password"
                      onChange={(e) => {
                        setFieldValue("confirmPassword", e.target.value);
                      }}
                    />
                    {touched.confirmPassword && errors.confirmPassword &&
                      <div className="text-danger">{errors.confirmPassword}</div>
                    }
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className="dialog-action">
                <CancelButton data-test-id="cancel-btn" onClick={this.handleClose}>
                  Cancel
                </CancelButton>
                <DoneButton
                  type="submit"
                >
                  Done
                </DoneButton>
              </DialogActions>
            </form>
          )}
        </Formik>
      </CustomDialog>
    );
  }

  logoutPopUp = () => {
    const isMobile = window.innerWidth <= 600;
    return (
      <CustomDialog
        open={this.state.openDialog === "logout"}
        data-test-id="logout-dialog"
        className="logout"
        onClose={this.handleClose}
        scroll={isMobile ? "paper" : "body"}
        TransitionComponent={isMobile ? Transition : undefined}
      >
        <DialogTitle className="dialog-title">
          <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.handleClose} />
        </DialogTitle>
        <DialogContent className="heading">
          Are you sure you want to Log out?
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            data-test-id="cancel-btn"
            onClick={this.handleClose}
            sx={{
              width: ["100%", "110px"],
              backgroundColor: ["#FFF", "#F0F0F0"],
            }}
          >
            No
          </CancelButton>
          <DoneButton
            data-test-id="logout-btn"
            onClick={this.onLogout}
            sx={{
              width: ["100%", "110px"],
              marginTop: ["10px", "0px"],
              marginLeft: ["0px", "auto"],
            }}
          >
            Yes
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  alert = () => {
    return (
      <CustomSnackbar
        open={this.state.openDialog === "alert"}
        data-test-id="snakbar"
        autoHideDuration={4000}
        onClose={this.handleClose}
        TransitionComponent={(props) => <Slide {...props} direction="down" />}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        message={
          <Box display="flex" alignItems="center">
            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M10.4996 21.3996C16.2434 21.3996 20.8996 16.7434 20.8996 10.9996C20.8996 5.25585 16.2434 0.599609 10.4996 0.599609C4.75585 0.599609 0.0996094 5.25585 0.0996094 10.9996C0.0996094 16.7434 4.75585 21.3996 10.4996 21.3996ZM15.3188 9.31885C15.8265 8.81117 15.8265 7.98805 15.3188 7.48037C14.8112 6.97269 13.9881 6.97269 13.4804 7.48037L9.19961 11.7611L7.51885 10.0804C7.01117 9.57269 6.18805 9.57269 5.68037 10.0804C5.17269 10.5881 5.17269 11.4112 5.68037 11.9188L8.28037 14.5188C8.78805 15.0265 9.61117 15.0265 10.1188 14.5188L15.3188 9.31885Z" fill="#34D399" />
            </svg>
            <Box ml="10px" component="span">Password changed successfully</Box>
          </Box>
        }
      />
    );
  }

  alertPopUp = () => {
    return (
      <>
        <Hidden smDown>{this.alert()}</Hidden>
        <Hidden smUp>
          <Backdrop
            open={this.state.openDialog === "alert"}
            sx={{
              zIndex: 9999999,
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
          >
            {this.alert()}
          </Backdrop>
        </Hidden>
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapper">
        <Loader loading={this.state.loading} />
        <Header
          navigation={this.props.navigation}
          supportpagename="account"
          islogin={true}
          isHeader={true}
        >
          <Box className="user-profile">
            <Box className="title">Account Settings</Box>
            <Box>
              <Grid container>
                <Grid item sm={4}>
                  <Typography className="details">Personal details</Typography>
                </Grid>
                <Grid item sm={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box mb="20px">
                        <Typography className="text-label">First Name</Typography>
                        <Typography className="user-detail">{this.state.userDetail?.first_name}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mb="20px">
                        <Typography className="text-label">Last Name</Typography>
                        <Typography className="user-detail">{this.state.userDetail?.last_name}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mb="20px">
                        <Typography className="text-label">Email</Typography>
                        <Typography className="user-detail">{this.state.userDetail?.email}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mb="20px">
                        <Typography className="text-label">Job Title</Typography>
                        <Typography className="user-detail">{this.state.userDetail?.job_title ?? "-"}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mb="20px">
                        <Typography className="text-label">Company</Typography>
                        <Typography className="user-detail">{this.state.userDetail?.company_name ?? "-"}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={6} sm={8}>
                          <Box mb="20px">
                            <Typography className="text-label">Password</Typography>
                            <Typography className="user-detail password">••••••••</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={4}>
                          <Box mb="20px" textAlign="end">
                            <button
                              data-test-id="password-btn"
                              className="text-label button password"
                              onClick={() => this.onButtonClick("password")}
                            >
                              Change Password
                            </button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <hr className="hr" />
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Typography className="details">Account actions</Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <button
                    className="text-label button logout"
                    onClick={() => this.onButtonClick("logout")}
                  >
                    <img src={logoutIcon} />
                    <span>Log Out</span>
                  </button>
                </Grid>
              </Grid>
              <Hidden >
                <Grid container className="below-options">
                  <Grid item xs={12}>
                    <Typography className="details general-info">General Info</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box component="button" data-test-id="terms-btn" className="options" mt="5px" onClick={()=>{this.props.navigation.navigate("TermsConditionsUserPart")}}>
                      <Typography>Terms & Conditions</Typography>
                      <KeyboardArrowRightIcon />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box component="button" data-test-id="privacy-btn" className="options" mt="10px" onClick={() => this.props.navigation.navigate("PrivacyPolicyUserPart")}>
                      <Typography>Privacy Policy</Typography>
                      <KeyboardArrowRightIcon />
                    </Box>
                  </Grid>
                </Grid>
              </Hidden>
            </Box>
          </Box>
        </Header>
        {this.state.openDialog === "alert" && this.alertPopUp()}
        {this.logoutPopUp()}
        {this.changePasswordDialog()}
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#FAFAFA",
  [theme.breakpoints.down('md')]: {
    height: "100%",
    backgroundColor: "#fff",
  },
  "& .user-profile": {
    padding: "40px 123px",
    [theme.breakpoints.down('lg')]: {
      padding: "40px 60px",
    },
    [theme.breakpoints.down('sm')]: {
      padding: "15px 20px",
    },
    "& .title": {
      color: "#0F172A",
      fontSize: "30px",
      marginBottom: "20px",
      fontFamily: "Heebo-Bold",
      [theme.breakpoints.down('sm')]: {
        display: "none"
      },
    },
    "& .details": {
      color: "#64748B",
      fontSize: "14px",
      marginBottom: "15px",
      fontFamily: "Heebo-Bold",
      textTransform: "uppercase",
      "&.general-info": {
        marginTop: "20px",
        marginBottom: "0px",
      }
    },
    "& .text-label": {
      color: "#334155",
      fontSize: "14px",
      marginBottom: "10px",
      fontFamily: "Heebo-Bold",
      "&.button": {
        border: "none",
        cursor: "pointer",
        whiteSpace: "nowrap",
        backgroundColor: "transparent",
      },
      "&.logout": {
        display: "flex",
        alignItems: "center",
        "& img": {
          marginRight: "10px"
        }
      },
      "&.password": {
        [theme.breakpoints.down('sm')]: {
          color: "black"
        },
      },
    },
    "& .user-detail": {
      color: "#94A3B8",
      fontSize: "16px",
      fontFamily: "Heebo-Regular",
      "&.password": {
        fontSize: "20px",
        color: "#334155",
      }
    },
    "& .hr": {
      marginBottom: "20px",
      border: "1px solid #E2E8F0"
    },
    "& .below-options": {
      "& .options": {
        width: "100%",
        border: "none",
        display: "flex",
        padding: "16px 0px",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        borderBottom: "1px solid #e0e0e0",
        "& p": {
          fontFamily: "Heebo-Regular",
        }
      }
    }
  }
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    bottom: 0,
  },
  '& .MuiPaper-root': {
    maxWidth: '600px',
    width: '100%',
    borderRadius: "8px 8px 32px 8px",
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
      borderRadius: '0px 24px 0px 0px',
    }
  },
  '& .MuiDialog-container': {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-end',
    }
  },
  "& form": {
    margin: "0px"
  },
  "& .description": {
    color: "#0F172A",
    fontSize: "16px",
    marginBottom: "15px",
    fontFamily: "Heebo-Regular"
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 16px 20px 40px",
    borderBottom: "1px solid #E2E8F0",
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
    },
  },
  "& .heading": {
    fontSize: "24px",
    fontFamily: "Heebo-Bold",
  },
  "& .content": {
    padding: "30px 40px 40px 40px !important",
  },
  "& .label-title": {
    fontSize: "14px",
    color: "#64748B",
    marginBottom: "5px",
    fontFamily: "Heebo-Bold",
  },
  "& .cool-gray": {
    color: "#64748B"
  },
  "& .dialog-action": {
    padding: "20px",
    borderTop: "1px solid #E2E8F0",
  },
  "&.change-password": {
    "& .dialog-title": {
      [theme.breakpoints.down('sm')]: {
        borderBottom: "none",
        justifyContent: "end",
      }
    },
    "& .content": {
      [theme.breakpoints.down('sm')]: {
        paddingTop: "20px !important"
      }
    }
  },
  "&.logout": {
    "& .dialog-title": {
      paddingBottom: "0px",
      borderBottom: "none",
      justifyContent: "end",
      [theme.breakpoints.down('sm')]: {
        padding: "40px 20px 25px 20px"
      }
    },
    "& .heading": {
      padding: "15px 35px !important",
      [theme.breakpoints.down('sm')]: {
        fontSize: "20px",
        borderTop: "1px solid #E2E8F0",
        padding: "25px 22px 10px !important",
      }
    },
    '& .MuiPaper-root': {
      maxWidth: '550px',
    },
    "& .dialog-action": {
      [theme.breakpoints.down('sm')]: {
        borderTop: "none",
        padding: "0px 20px",
        marginBottom: "60px",
        flexDirection: "column"
      }
    }
  }
}));

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  zIndex: 9999999,
  padding: "0px 10px",
  '&.MuiSnackbar-root': {
    top: '90px !important',
    [theme.breakpoints.down('sm')]: {
      top: '13px !important',
    },
    "& .MuiPaper-root": {
      fontSize: "16px",
      borderRadius: "8px",
      padding: "12px 55px",
      backgroundColor: "#fff",
      color: "black !important",
      fontFamily: "Heebo-Regular",
      boxShadow: "0px 6px 15px -3px #00000026",
      [theme.breakpoints.down('sm')]: {
        padding: "12px 18px",
      },
    }
  },
}));

const CustomInput = styled(InputBase)({
  width: "100%",
  borderRadius: "8px",
  padding: "10px 8px",
  backgroundColor: "#fff",
  border: "1px solid #A8A29E",
});

const CancelButton = styled(Button)({
  width: "110px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  width: "110px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});
// Customizable Area End