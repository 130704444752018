import React from "react";

// Customizable Area Start
import Header from '../../../components/src/Header.web';
import {
    Box,
    Button,
    Typography
} from "@mui/material";
import HtmlReactParser from 'html-react-parser';
import { arrowBackIcon } from './assets';
import { styled } from "@mui/material/styles";
// Customizable Area End

import PrivacyPolicyUserPartController, {
    Props,
    configJSON,
} from "./PrivacyPolicyUserPartController";
import Loader from "../../../components/src/Loader.web";

export default class PrivacyPolicyUserPart extends PrivacyPolicyUserPartController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    rendermobileHeader = (currentPage: string, onBack: () => void) => {
        return (
            <Box sx={styles.mobileHeaderContainer}>
                <Button
                    sx={styles.mobileHeaderBackBtn}
                    data-test-id="backBtn"
                    onClick={onBack}
                >
                    <img style={styles.mobileHeaderBackIcon} src={arrowBackIcon} alt="back" />
                </Button>
                <Typography style={styles.mobileHeaderTitle}>
                    {currentPage}
                </Typography>
                <Box sx={styles.mobileHeaderBox}></Box>
            </Box>
        );
    }
    renderPrivacy(){
        return (<Box sx={styles.container}>
            <CustomBoxPolicy sx={styles.content}>
                <Box sx={styles.title}>Privacy Policy</Box>
                <Box sx={styles.textContent}>{HtmlReactParser(this.state.privacyPolicyValue)}</Box>
            </CustomBoxPolicy>
        </Box>)
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isMobile } = this.state;
        return (
            <div>
                <Loader loading={this.state.loading}/>
                {!isMobile ? (
                    <Header
                        navigation={this.props.navigation}
                        islogin={this.state.isloggedIn}
                        isHeader={false}
                    >
                        {this.renderPrivacy()}
                    </Header>
                ) : (
                    <>
                        {this.rendermobileHeader('Privacy Policy', () => this.props.navigation.navigate('LandingPage'))}
                        {this.renderPrivacy()}
                    </>
                )}
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const CustomBoxPolicy = styled(Box)({
    "@media(max-width:900px)":{
        "ul,ol":{
        paddingInlineStart:'10px'
    }
    }
})
const styles = {
    mobileHeaderContainer: {
        position: 'sticky',
        alignItems: 'center',
        top: '0px',
        display: 'flex',
        padding: '6px 16px',
        justifyContent: 'space-between',
        zIndex: '1000',
        backgroundColor: '#FFFFFF',
    },
    mobileHeaderBackBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        width: '48px',
        color: '#0F172A',
        minWidth: '48px',
        borderRadius: '50%',
    },
    mobileHeaderBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '48px',
        height: '48px',
    },
    mobileHeaderTitle: {
        fontFamily: 'Heebo-Regular',
        fontSize: 14,
        fontWeight: '700',
        textTransform: 'uppercase' as 'uppercase',
        color: '#0F172A',
    },
    mobileHeaderBackIcon: {
        maxWidth: '24px',
        maxHeight: '24px',
    },
    container: {
        display:'flex',
        justifyContent:'center',
        alignItems:"center",
        padding:'30px 0'
    },
    content: {
        width:"90%"
    },
    title: {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    textContent: {
        padding: "24px 0",
        fontFamily: "Heebo",
        fontSize: "16px",
        lineHeight: "24px",
        "& img": {
            maxWidth: '100%',
            width: "auto",
            height: "revert-layer",
        }
    }
}

// Customizable Area End  