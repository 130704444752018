import React from "react";
// Customizable Area Start

import {
    Modal,
    MenuItem,
    Box,
    Button,
    IconButton,
    Typography,
    TextField,
    styled,
    Select as MuiSelect,
    InputAdornment,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,Grid,Dialog, DialogContent, DialogActions, DialogTitle,
    InputBase, Checkbox,Card,CardContent,Container,Chip,CircularProgress,ThemeProvider, Table,
    TableHead,
    TableBody,
    TableContainer,
    TableCell,
    TableRow,
    Paper,LinearProgress
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {ExpandMore,Search,AddCircleOutline ,MoreVert,KeyboardArrowDown,ArrowForwardIos, EventNoteOutlined, TimerOutlined
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from "yup";
import HtmlReactParser from 'html-react-parser';
import { myLearningCourseImg} from "../../dashboard/src/assets";
import {  CourseThumbnail, timer } from "./assets";
import DOMPurify from 'dompurify';
// Customizable Area End

import AdminAccreditationController, {
    Props,
} from "./AdminAccreditationController";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";

export default class AdminAccreditation extends AdminAccreditationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

  // Customizable Area Start
  renderHTML = (data: any) => {
    return data.includes('<p') ? HtmlReactParser(data.replaceAll('<p>', '').replaceAll('</p>', '')) : data;
  }

  renderCreateAccreditationMsg = () => {
    return (
      <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '180px' }}><Box><Typography sx={webStyles.grayText}>You don’t have any accreditations</Typography>
      </Box>
        <Box data-test-id="createAccreditation" onClick={() => this.openCreateAccreditationModal()}
          sx={webStyles.createPathContainer}><Typography sx={webStyles.createPathText}>Create accreditation</Typography></Box></Box>
    )
  }

  renderAccreditationDialog = () => {
    return (
      <CustomDialog
        open={this.state.isCreateAccreditationModalOpen}
        onClose={this.closeCreateAccreditationModal}
      >
        <DialogTitle className="dialog-title">
          <Typography className="heading-title">Create accreditation</Typography>
          <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.closeCreateAccreditationModal} />
        </DialogTitle>
        <DialogContent className="content">

          <Box mt="10px">
            <Typography className="label-title">Title of accreditation</Typography>
            <CustomInputBase
              fullWidth
              data-test-id="title"
              value={this.state.title}
              placeholder="Write title here"
              onChange={(e: { target: { value: string; }; }) => {
                this.setState({ title: e.target.value });
              }}
            />
            {this.state.titleError &&
              <div className="text-danger">{this.state.titleError}</div>
            }
          </Box>
          <Box mt="15px">
            <Typography className="label-title">Description</Typography>
            <CustomInputBase
              rows={5}
              fullWidth
              multiline={true}
              data-test-id="description"
              value={this.state.description}
              placeholder="Add description about accreditation"
              onChange={(e: { target: { value: string; }; }) => {
                this.setState({ description: e.target.value });
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="cancel-btn"
            onClick={this.closeCreateAccreditationModal}
          >
            Cancel
          </CancelButton>
          <DoneButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            type="submit"
            data-test-id="saveBtntn"
            onClick={() => { this.handleAddCourses() }}
          >
            Add Courses
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  customRangePicker = (startDate: any, endDate: any) => {
    const { startPickerOpen, endPickerOpen } = this.state;
    return (
      <Box display="flex" alignItems="center" gap={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: "100%" }}
            value={startDate}
            onChange={(date) => this.setState({ startDate: date, startPickerOpen: false })}
            format="DD/MM/YYYY"
            open={startPickerOpen}
            onOpen={() => this.setState({ startPickerOpen: true })}
            onClose={() => this.handleCloseStartPopover()}
            slots={{
              openPickerIcon: EventNoteOutlined,
            }}
            slotProps={{
              textField: {
                placeholder: "Select a StartDate",
              },
              openPickerButton: {
                sx: {
                  color: "#000",
                },
              },
            }}
          />
          <Box>-</Box>
          <DatePicker
            value={endDate}
            onChange={(date) => this.setState({ endDate: date, endPickerOpen: false })}
            sx={{ width: "100%" }}
            minDate={startDate}
            format="DD/MM/YYYY"
            open={endPickerOpen}
            onOpen={() => this.setState({ endPickerOpen: true })}
            onClose={() => this.handleCloseEndPopover()}
            slots={{
              openPickerIcon: EventNoteOutlined,
            }}
            slotProps={{
              textField: {
                placeholder: "Select a endDate",
              },
              openPickerButton: {
                sx: {
                  color: "#000",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  renderExportUsersDialog = () => {
    return (
      <CustomDialog
        open={this.state.isExportModalOpen}
        onClose={this.onCloseExportModal}
      >
        <DialogTitle className="dialog-title">
          <Typography className="heading-title">Export Report</Typography>
          <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.onCloseExportModal} />
        </DialogTitle>
        <DialogContent className="content">

          <Box mt="10px">
            <Typography className="label-title">Date range</Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={{ display: 'flex' }}>
                {this.customRangePicker(this.state.startDate, this.state.endDate)}
              </Box>
            </LocalizationProvider>
          </Box>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="cancel-btn"
            onClick={() => this.setState({ isExportModalOpen: false })}
          >
            Cancel
          </CancelButton>
          <DoneButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            type="submit"
            data-test-id="saveBtntnExport"
            onClick={() => { this.exportUsersCSV() }}
          >
            Export
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderDeletePathDialog = () => {
    return (
      <CustomDialog
        open={this.state.isDeleteAccreditationModalOpen}
        onClose={() => this.onDeletePathConfirm(false)}
      >
        <Box sx={{ display: 'flex', justifySelf: 'flex-end', justifyContent: 'flex-end', padding: '8px' }}> <CloseIcon className="close-icon" data-test-id="close-btn" onClick={() => this.onDeletePathConfirm(false)} /></Box>
        <DialogContent className="content">
          <Typography sx={{
            fontFamily: 'Heebo',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '32px',
            letterSpacing: '-0.005em',
            textAlign: 'left'
          }}
          >{`Are you sure you want to delete ` + this.state.selectedAccreditation?.attributes.title + `?`}</Typography>
          <Typography sx={{
            fontFamily: 'Heebo',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '26px',
            textAlign: 'left',
            marginTop: '16px'
          }}>This action is irreversible, and you will lose the data permanently without any chance of recovery.</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="cancel-btn"
            onClick={() => this.onDeletePathConfirm(false)}
          >
            Cancel
          </CancelButton>
          <DeleteButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="save-btn"
            onClick={() => this.onDeletePathConfirm(true)}
          >
            Delete
          </DeleteButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  buttonText = () => {
    if (this.state.isCreatingAccreditation) {
      return 'Create Accreditation'
    } else {
      return 'Assign Course'
    }
  }

  renderUpdatePackageModal = () => {
    return (
      <Modal open={this.state.updatePackageModal} onClose={this.closeUpdatePackageModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={webStyles.container} key={this.state.updatedPackage?.addCourse || 'pkg_modal'}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              {this.state.isCloning ? 'Assign Courses to Learning Path' : 'Assign Courses'}
            </Typography>
            <IconButton data-testID="closeUpdatePackageModal" onClick={this.closeUpdatePackageModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            width="100%"
            padding={2}
            sx={{
              height: '100%',
              overflowY: 'scroll',
            }}
          >
            {this.state.isCloning && <Grid container>
              <Grid item xs={12}>
                <Box padding="10px 5px 32px 5px">
                  <Typography className="label-title">Select learning path</Typography>
                  <MuiSelect
                    data-test-id="sort-select"
                    value={this.state.selectedAccreditationToAssign}
                    disableUnderline
                    variant='standard'
                    sx={{
                      width: '95%',
                      backgroundColor: 'transparent',
                      border: '1px solid #ccc',
                      padding: '5px',
                      borderRadius: '5px',
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'transparent',
                        border: '1px solid #ccc',
                        '& input': {
                          backgroundColor: 'transparent',
                          border: '1px solid #ccc',
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 300,
                          overflowY: 'auto',
                        },
                      },
                    }}
                    IconComponent={ExpandMore}
                  >
                  </MuiSelect>
                </Box>
              </Grid>
            </Grid>}
            <Grid container spacing={4} justifyContent="center">
              <Box sx={{ padding: '1rem 2rem' }}>
                <div>
                  {this.state.filteredLearningPaths?.map((learningPath: any) => (
                    <StyledAccordion key={learningPath.id}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls={`panel-${learningPath.id}-content`}
                        id={`panel-${learningPath.id}-header`}
                        sx={{
                          flexDirection: 'row-reverse',
                          display: 'flex',
                          alignItems: 'center',
                          columnGap: '1rem'
                        }}
                      >
                        <Typography sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                          {learningPath.attributes.title}
                        </Typography>
                        <StyledCheckbox
                          data-test-id="learning-path"
                          checked={this.state.selectedLearningPaths.includes(learningPath.id)}
                          onChange={() => this.handleLearningPathToggle(learningPath.id, learningPath.attributes.courses)}
                        />
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="body2" sx={{ padding: '0rem 2.1rem' }}>
                          {this.renderHTML(learningPath.attributes.description)}
                        </Typography>
                        <Box sx={{ display: 'flex' }}> <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', margin: '20px' }}>
                          {learningPath.attributes.courses.map((course: any,index:number) => {
                            const courseImage = course.thumbnail_image?.url || myLearningCourseImg;
                                return (
                                  <Grid item key={course.id} margin={'16px'}>
                                    {this.renderCard(course, courseImage, index, learningPath.id,true)}
                                  </Grid>
                                )
  })}

                        </div>
                        </Box>
                      </AccordionDetails>
                    </StyledAccordion>
                  ))}
                </div>
              </Box>
            </Grid>
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <CancelButton data-testID="closeUpdatePackageModal" onClick={() => this.state.isCreatingAccreditation ? this.onSubmitAccreditation() : this.closeUpdatePackageModal()}>{(this.state.isCreatingAccreditation) ? 'Skip & Create' : 'Cancel'}</CancelButton>
            <DoneButton data-testID="updatePath" onClick={() => { this.onSubmitAccreditation() }}>{this.buttonText()}</DoneButton>
          </Box>
        </Container>
      </Modal>
    )
  }

  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };

  renderCard = (course: any, courseImage: any, index: number, accreditationId: string, showCheckBox: boolean = false) => {
    return <Card style={{ width: '100%', height: '100%', position: 'relative' }}>
      <Box position="relative">
        <Box
          style={{
            position: 'relative',
            width: '100%',
            height: '160px',
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={1}
        >
          <Box display="flex" justifyContent="space-between" alignItems={"center"} padding={"8px"}>
            <Chip style={{ borderRadius: '4px', backgroundColor: course.status === 'published' ? '#1C1C1C' : '#1C1C1C66' }} label={course.status?.toUpperCase() || 'DRAFT'} color="primary" />
            {showCheckBox ? <CustomCheckbox
              data-testID={`checks${index}`}
              checked={this.state.selectedCourses.includes(course.id)}
              onChange={() => this.handleCourseToggle(accreditationId, course.id)}
            /> : <>
              <IconButton key={'btn-more-course' + course.id} data-test-id='btnMoreCourses' onClick={(e) => this.handleCoursePopoverClick(e, course)}
                sx={{
                  borderRadius: '4px',
                  width: 32,
                  height: 32,
                  backgroundColor: '#1C1C1C66',
                  marginRight: '12px',
                  color: '#FFFFFF'
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Popover
                data-test-id='btnPopoverCourse'
                key={'more_popover_course' + course.id}
                id={course.id}
                open={this.state.showCourseActionPopup && this.state.selectedCourse.id == course.id}
                anchorEl={this.state.anchorElCourse}
                onClose={() => this.handleCoursePopoverClose()}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                <Typography sx={{ p: 2 }}>
                  {course.status !== 'published' && <MenuItem key={'action_a' + course.id + accreditationId} data-test-id='btnCoursePublish' onClick={() => this.handlePublishCourse(course)}>Publish Course</MenuItem>}
                  <MenuItem key={'action_b' + course.id + accreditationId} data-test-id='btnCourseUpdate' onClick={() => { this.handleUpdateCourse(course.id) }}>Update Course</MenuItem>
                  <MenuItem key={'action_c' + course.id + accreditationId} data-test-id='btnCourseClone' onClick={() => this.handleCloneCourse()}>Clone</MenuItem>
                  <MenuItem key={'action_c' + course.id + accreditationId} data-test-id='btnCourseDelete' onClick={() => this.handleDeleteCourse(course)}><Box sx={{ color: '#DC2626' }}>Delete Course</Box></MenuItem>
                </Typography>
              </Popover>
            </>}
          </Box>
          <Box display={"flex"} padding={"5px"} alignItems={"center"}>
            <img src={timer} height={"16px"} width={"16px"} alt="" />
            <Typography color={"white"} margin={"10px"}>
              {course.duration} Hours
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent>
        <Typography variant="h6" component="div" sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: 2,
        }}>
          {this.sanitizeData(course.title)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {course.lessons} Lessons
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: 3,
        }}>
          {this.sanitizeData(course.description)}
        </Typography>
      </CardContent>
    </Card>
  }

  renderAccreditationDetais = () => {
    return (this.isSelectedTab('about') ? this.renderAbout() : this.renderUsers())
  }

  renderAbout = () => {
    return (
      <Box>
        <Box mt="10px">
          <Typography className="label-title">Title of accreditation</Typography>
          <CustomInputBase
            fullWidth
            data-test-id="title"
            value={this.state.title}
            placeholder="Write title here"
            onChange={(e: { target: { value: string; }; }) => {
              this.setState({ title: e.target.value });
            }}
          />
          {this.state.titleError &&
            <div className="text-danger">{this.state.titleError}</div>
          }
        </Box>
        <Box mt="15px">
          <Typography className="label-title">Description</Typography>
          <CustomInputBase
            rows={5}
            fullWidth
            multiline={true}
            data-test-id="description"
            value={this.state.description}
            placeholder="Add description about accreditation"
            onChange={(e: { target: { value: string; }; }) => {
              this.setState({ description: e.target.value });
            }}
          />
        </Box>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '20px 0px 20px 0px' }}>
          {this.state.selectedAccreditation?.attributes.courses.map((course: any, index: any) => {
            const courseImage = course.thumbnail_image?.url || myLearningCourseImg;
            return (
              <Grid item xs={3} key={course.id}>
                {this.renderCard(course, courseImage, index, this.state.selectedAccreditation?.id)}
              </Grid>
            )
          })}

        </div>
      </Box>
    )
  }

  renderUsers = () => {
    return (<Box>   
      <Container style={{ paddingLeft: '4px' }} maxWidth={false}>
      <Paper style={{ padding: '16px' }}>
        <TableContainer style={{ maxHeight: '700px' }}>
          <Table stickyHeader style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={[{
                    width: '150px',
                    minWidth: '80px',
                  },webStyles.tableHeaderText]}
                >
                  {'Users'}
                </TableCell>
                <TableCell
                  sx={[{
                    width: '130px',
                    minWidth: '80px',
                  },webStyles.tableHeaderText]}
                >
                  {'Client'}
                </TableCell>
                <TableCell
                  sx={[{
                    width: '150px',
                    minWidth: '80px'}
                    ,webStyles.tableHeaderText]}
                >
                  {'Date Completed'}
                </TableCell>
                <TableCell
                  sx={[{
                    width: '500px',
                    minWidth: '150px',
                  },webStyles.tableHeaderText]}
                >
                  {'Status'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.userProcessData?.map((user:any) => {
                const statusString = this.getUserStatusString(user);
                const statusValue =this.getUserStatusValue(user);
               return( <TableRow key={user.user_id}>
                  <TableCell
                    sx={{
                      textAlign: 'left',
                      width: '80px',
                      minWidth: '60px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Typography>{user.name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'left',
                      width: '150px',
                      minWidth: '100px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Typography>{user.client_name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'left',
                      width: '110px',
                      minWidth: '90px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {user.date ? this.formatDate(user.date) : '-'}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'left' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                      <Box width={'150px'}>
                        <Typography sx={{ fontSize: '16px', lineHeight: '23.5px', marginRight: 2 }} gutterBottom>
                          {statusString}
                        </Typography>
                      </Box>
                      <Box sx={{ width: '70%' }}>
                        <LinearProgress
                          variant="determinate"
                          value={statusValue || 0}
                          sx={{
                            height: 10,
                            borderRadius: 5,
                            backgroundColor: '#e0e0e0',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: '#29CC6A',
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>)
  })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container> 
    </Box>)           
  }

  renderLoader = () => {
    return <Box sx={{ width: '100%', marginTop: '200px' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress
        style={{
          height: '80px',
          width: '80px',
          boxShadow: 'inset 0 0 1px 1px #D9d9d9',
          borderRadius: '50%'
        }}
        sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
      />
    </Box>
  }

  renderHeaderBtn = () => {
    let btnText = 'Create New accreditation';
    let btnWidth = '250px';
    if (this.showViewMode()) {
      btnText = this.isSelectedTab('about') ? 'Save' : 'Export Report';
      btnWidth = '75px';
    }
    return (<Button
      style={{ color: '#ffffff', padding: '15px', minWidth: btnWidth, display: 'flex', gap: '8px', backgroundColor: '#1C1C1C', fontWeight: '700', fontFamily: 'heebo', fontSize: '16px', lineHeight: '24px', textTransform: "none" }}
      startIcon={!this.showViewMode() ? <AddCircleOutline /> : null}
      onClick={() => this.showViewMode() ? this.onSaveAccreditation() : this.openCreateAccreditationModal()}
      data-test-id="headerBtn"
    >
      {btnText}
    </Button>)
  }

  // Customizable Area End
    render() {
        return (
            // Customizable Area Start
          <ThemeProvider theme={{}}>
            <Box sx={webStyles.mainWrapper}>
              <Box sx={webStyles.mainBlock}>
                <Box>
                  <Sidebar navigation={this.props.navigation} current='accreditation' />
                </Box>
                <Box sx={webStyles.pageContent} data-testid="pageContent">
                  <Box sx={webStyles.contentHeader}>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', alignContent: 'center' }}> <Box><Typography sx={webStyles.breadcrumbButton} onClick={() => { this.onBackPress() }}>Accreditation</Typography></Box>
                      {this.showViewMode() && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <ArrowForwardIos fontSize="small" sx={{ margin: '0px 10px' }} />{this.isSelectedTab('about') ? 'About' : 'Users'}
                      </Box>}
                    </Box>
                    <Box>
                      {this.renderHeaderBtn()}
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection={'row'} alignItems="center" justifyContent="space-between" bgcolor="#f5f5f5" sx={{ paddingBottom: '20px', height: '56px' }}>
                    {this.showViewMode() ?
                      <>
                        <Box className="tab-buttons">
                          <TabButton data-test-id='personalTab' sx={this.isSelectedTab('about') ? webStyles.selectedBtn : webStyles.normalBtn} onClick={() => this.onTabSwitch('about')}>About</TabButton>
                          <TabButton data-test-id='assignTab' sx={this.isSelectedTab('users') ? webStyles.selectedBtn : webStyles.normalBtn} onClick={() => this.onTabSwitch('users')}>Users</TabButton>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: "center" }}>
                         {this.isSelectedTab('users') &&<><Box data-test-id="allFilter" display="flex" alignItems="center" sx={{ marginRight: "20px" }} onClick={() => { }}>
                            <Typography sx={webStyles.filterText}>Filter</Typography>
                            <IconButton aria-label="filter">
                              <FilterListIcon />
                            </IconButton>
                          </Box>
                          <div style={{ display: 'flex', alignItems: 'center',marginLeft:'25px' }}>
                      <Typography>Sort by:</Typography>
                      <MuiSelect
                        data-test-id="sort-select"
                        value={this.state.sortBy}
                        onChange={(e: any) => this.handleSortChange(e)}
                        defaultValue="relevance"
                        disableUnderline
                        variant='standard'
                        sx={{
                          paddingLeft: '10px',
                          width: '150px',
                          backgroundColor: 'transparent',
                          '& .MuiSelect-icon': {
                            color: 'gray',
                          },
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            '& input': {
                              backgroundColor: 'transparent',
                              padding: ''
                            },
                          },
                        }}
                            >
                              <MenuItem value="A-Z" sx={{fontSize:'14px',lineHeight:'22px'}}>Users (A to Z)</MenuItem>
                              <MenuItem value="Z-A" sx={{fontSize:'14px',lineHeight:'22px'}}>Users (Z to A)</MenuItem>
                              <MenuItem value="status-asc" sx={{fontSize:'14px',lineHeight:'22px'}}>Status (Ascending)</MenuItem>
                              <MenuItem value="status-desc" sx={{fontSize:'14px',lineHeight:'22px'}}>Status (Descending)</MenuItem>
                              <MenuItem value="old-new" sx={{fontSize:'14px',lineHeight:'22px'}}>Oldest to newest</MenuItem>
                              <MenuItem value="new-old" sx={{fontSize:'14px',lineHeight:'22px'}}>Newest to oldest</MenuItem>
                            </MuiSelect>
                    </div></>}
                        </Box></> : <>  <Box sx={webStyles.searchBox}>
                          <TextField
                            InputProps={{
                              sx: {
                                '& .MuiOutlinedInput-notchedOutline': {
                                  border: 'none',
                                  height: '100%',
                                },
                                '& .MuiOutlinedInput-root': {
                                  height: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                },
                                '& input': {
                                  paddingLeft: '16px',
                                  lineHeight: '24px',
                                  fontSize: '16px',
                                },
                                backgroundColor: 'white'
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Search />
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            placeholder="Search accreditation"
                            data-test-id='accreditationSearch'
                            sx={{ width: "100%", height: "100%", marginLeft: "4px" }}
                            value={this.state.accreditationSearchQuery}
                            onChange={(e: any) => this.handleAccreditationSearchQuery(e)} /></Box>
                        <Box sx={{ display: 'flex', alignItems: "center" }}>
                          <Box data-test-id="allFilter" display="flex" alignItems="center" sx={{ marginRight: "20px" }} onClick={() => { }}>
                            <Typography sx={webStyles.filterText}>Filter</Typography>
                            <IconButton aria-label="filter">
                              <FilterListIcon />
                            </IconButton>
                          </Box>
                          <Box display={'flex'} gap={"5px"}>
                            <Box sx={{ whiteSpace: "nowrap" }}>Sort by :</Box>
                            <Box
                              data-testID='btnSort'
                              //onClick={this.handlePopoverClick}
                              style={{ whiteSpace: "nowrap", cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                            >
                              {'Relevance'}
                              <KeyboardArrowDown />
                            </Box>
                          </Box>
                          <Box>
                          </Box>
                        </Box></>}
                  </Box>
                  {this.renderAccreditationDialog()}
                  {this.renderUpdatePackageModal()}
                  {this.renderDeletePathDialog()}
                  {this.renderExportUsersDialog()}
                  {this.state.loading ?
                    <>{this.renderLoader()}</> :
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {!this.state.filteredAccreditations.length && this.renderCreateAccreditationMsg()}
                      {this.showViewMode() && this.renderAccreditationDetais()}
                      {!this.showViewMode() && this.state.filteredAccreditations.map((accreditation: any) => (
                        <StyledAccordion key={accreditation.id}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls={`panel-${accreditation.id}-content`}
                            id={`panel-${accreditation.id}-header`}
                            sx={{
                              flexDirection: 'row-reverse',
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: '1rem'
                            }}
                          >
                            <Typography sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                              {accreditation.attributes.title}
                            </Typography>
                            <Box>
                              <IconButton key={'btn-more' + accreditation.id} data-test-id='btnMore' onClick={(e) => this.handleAccrediatationPopoverClick(e, accreditation)}
                                sx={{
                                  borderRadius: '50%',
                                  width: 40,
                                  height: 40,
                                }}
                              >
                                <MoreVert />
                              </IconButton>
                              <Popover
                                data-test-id='btnPopover'
                                key={'more_popover' + accreditation.id}
                                id={'accrediatation' + accreditation.id}
                                open={this.state.showActionPopup}
                                anchorEl={this.state.anchorElAccrediatation}
                                onClose={() => this.handleAccrediatationPopoverClose()}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                sx={{
                                  '& .MuiPaper-root': {
                                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                  },
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  <MenuItem key={accreditation.id + 'update'} data-test-id='btnOptionsUpdate' onClick={(e) => this.viewAccreditation(accreditation)}>View</MenuItem>
                                  <MenuItem key={accreditation.id + 'assign'} data-test-id='btnOptionsAssign' onClick={(e) => this.handleAssignCourses(e, accreditation)}>Add Courses</MenuItem>
                                  <MenuItem key={accreditation.id + 'users'} data-test-id='btnOptionsUsers' onClick={(e) => this.viewAccreditation(accreditation, true)}>Users</MenuItem>
                                  <MenuItem key={accreditation.id + 'clone'} data-test-id='btnOptionsClone' onClick={(e) => { }}>Clone</MenuItem>
                                  <MenuItem key={accreditation.id + 'delete'} data-test-id='btnOptionsDelete' onClick={(e) => this.handleDeleteAccrediatation(e, accreditation)}><Box sx={{ color: '#DC2626' }}>Delete</Box></MenuItem>
                                </Typography>
                              </Popover>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body2" sx={{ padding: '0rem 2.1rem' }}>
                              {this.renderHTML(accreditation.attributes.description)}
                            </Typography>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '20px' }}>
                              {accreditation.attributes.courses.map((course: any, index: any) => {
                                const courseImage = course.thumbnail_image?.url || myLearningCourseImg;
                                return (
                                  <Grid item xs={3} key={course.id}>
                                    {this.renderCard(course, courseImage, index, accreditation.id)}
                                  </Grid>
                                )
                              })}

                            </div>
                          </AccordionDetails>
                        </StyledAccordion>
                      ))}
                    </Box>}
                </Box>
              </Box>
              <Box sx={webStyles.footerBlock}>
                <Box sx={webStyles.leftFooter}>
                  <Box>Terms and Conditions</Box>
                  <Box>Privacy Policy</Box>
                  <Box></Box>
                </Box>

                <Box>
                  <Box>
                    © 2024 Thinkspike, All Rights Reserved</Box>
                </Box>
              </Box>
            </Box>
          </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StyledCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
      color: 'black',
      borderRadius: '6px',
      '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
      },
  },
});
const BoxThumbnail = styled(Box)({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '160px',
  "&>img": {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: '1'
  }
})

const CustomDialog = styled(Dialog)({
  '& .MuiPaper-root': {
      maxWidth: '800px',
      width: '100%',
      borderRadius: "8px",
  },
  "& .dialog-title": {
      padding: "15px 30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #E2E8F0",
      "& .close-icon": {
          cursor: "pointer",
          color: "#334155",
      },
  },
  "& .create-title": {
      fontSize: "20px",
      color: "##1C1C1C",
      fontFamily: "Heebo-SemiBold",
  },
  "& .label-title": {
      fontSize: "14px",
      color: "#64748B",
      fontFamily: "Heebo-Regular",
      fontWeight: 600,
      padding: '5px 0',
  },
  "&.modal-menu": {
      '& .MuiPaper-root': {
          maxWidth: '670px',
      },
      "& .label-title": {
          color: "#334155"
      },
      "& .dialog-action": {
          padding: "17px",
      },
      "& form": {
          margin: "0px"
      },
      "& .requirement-editor": {
          border: "1px solid #9D9D9D",
          borderRadius: "4px",
          padding: "10px 5px 3px 0px",
          "& .public-DraftStyleDefault-block": {
              paddingLeft: "15px"
          },
          "& .public-DraftEditorPlaceholder-inner": {
              paddingLeft: "15px",
              fontSize: "14px",
              letterSpacing: "0.2px",
              color: "#c4c4c4",
              fontfamily: 'Heebo-Regular'
          }
      }
  },
  "& .heading-title": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
  },
  "& .content": {
      padding: "20px 30px 25px !important",
  },
  "& .dialog-action": {
      padding: "10px 17px",
      borderTop: "1px solid #E2E8F0",
  },
  "& .react-select__placeholder": {
      paddingLeft: "7px"
  },
  "& .react-select__input": {
      paddingLeft: "7px",
  },
  "& .unchecked": {
      width: "20px",
      height: "20px",
      border: "1px solid #484848",
      borderRadius: "3px",
  },
  "& .checked": {
      width: "20px",
      height: "20px",
      backgroundColor: "#000",
      border: "1px solid #000",
      borderRadius: "3px",
  },
  "& .checked svg": {
      color: "#fff",
      fontSize: "20px"
  },
  "& .react-select__multi-value__remove:hover": {
      backgroundColor: "black",
      color: "#fff",
  },
  ".react-select__menu-list": {
      maxHeight: "223px",
  },
  "& .react-select__menu": {
      color: "black",
  },
  "& .react-select__option": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #e8e8e8",
      backgroundColor: "#fff",
      color: "black",
      marginTop: "0px",
      padding: "0px 12px",
      paddingLeft: "0px",
      fontSize: "14px",
      "& label": {
          marginLeft: "15px"
      }
  },
  "& .react-select__option--is-selected": {
      fontFamily: "Heebo-SemiBold",
  },
  "& .react-select__option:hover": {
      backgroundColor: "#fff",
      fontFamily: "Heebo-SemiBold",
      color: "black",
  },
  "& .react-select__option:hover .MuiButtonBase-root:hover": {
      backgroundColor: "transparent"
  },
  "& .react-select__option:active": {
      backgroundColor: "#fff",
      color: "black",
      fontFamily: "Heebo-SemiBold",
  },
  "& .react-select__multi-value": {
      borderRadius: "6px",
      color: "#fff",
      backgroundColor: "black",
      padding: "0px 2px",
  },
  "& .react-select__multi-value__label": {
      color: "#fff",
  }
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  //width: "95px",
  height:'56px',
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
      backgroundColor: "black",
  },
});

const TabButton = styled(Button)({
    textTransform: "none",
    fontFamily: "Heebo-Regular",
    width:'130px',
    //color: "white !important",
    //fontSize: "14px !important",
    padding: "10px 16px !important",
    borderRadius: "4px !important",
    "&:hover": {
      backgroundColor: "black",
      color: 'white',
    },
});

const DeleteButton = styled(Button)({
  marginLeft: "15px !important",
  height:'56px',
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "#FC5555",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#FC5555",
},
});

const CancelButton = styled(Button)({
  marginLeft: "15px !important",
  height:'56px',
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  background: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
      backgroundColor: "#F0F0F0",
  },
});

const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    backgroundColor: 'white',
    width: '20px',
    borderRadius: '4px',
    height: '24px',
    border: '1px solid black',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M5 12l5 5L20 7")',
      stroke: 'white',
      fill: 'none',
    },
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
});

const CustomInputBase = styled(InputBase)({
  border: "1px solid #CBD5E1",
  borderRadius: "5px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
      padding: "10px 8px",
      '&:placeholder': {
          fontWeight: 500
      }
  },
  "& .MuiInputBase-inputMultiline": {
      fontSize: "14px",
      padding: "10px 8px",
  },

});
const StyledAccordion = styled(Accordion)({
    border: '1px solid #D6D3D1',
    boxShadow: 'none', 
    marginBottom:'10px',
  });

  const webStyles = {
    courseCard: {
        display: 'flex',
        width: '100%',
        maxWidth: '350px',
        gap: '16px',
        flexDirection: 'column',
        boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
    },
    container: {
      left: "50%",
      position: 'absolute',
      transform: "translate(-50%, -50%)",
      top: "50%",
      boxShadow: "0px 8px 32px 0px #0000000F",
      height: "75%",
      width: '70%',
      padding: '24px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
    },
    courseCardDescription: {
        gap: '16px',
        flexDirection: 'column',
        padding: '0px 16px 24px 16px',
        display: 'flex',
    },
    selectedBtn: {
        backgroundColor: '#1C1C1C',
        color: 'white',
        fontSize: '14px',
        marginRight:'24px',
      },
      normalBtn: {
        backgroundColor:'#FFFFFF',
        color: "#1C1C1C",
        fontSize: '16px',
        marginRight:'24px'
      },
      tableViewStyle: {
        width: '100%',
        overflow: 'hidden',
        borderRadius:"6px"
      },
      tableContainerStyle: {
        maxHeight: 600,
        width:'100%',
      },
    createPathContainer:{
      display:'flex',
      justifyContent:'center',
      width: '281px',
      height: '56px',
      padding: '10px 16px 10px 16px',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: '#6A6A6A',
      marginTop:'20px',
      textAlign:'center',
      backgroundColor: '#FFFFFF'
    },
    createPathText:{
      fontFamily: 'Heebo',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      margin:'auto',
      
      textAlign: 'center'
    },
    grayText:{
      fontFamily: 'Heebo',
      fontSize: '32px',
      fontWeight: '400',
      lineHeight: '47px',
      textAlign: 'center',
      color: '#6A6A6A'
    },
    modalStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: 600,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    },
    filterText:{
      fontFamily: 'Heebo',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '22px',
      textAlign: 'left',
      color:' #334155'
    },
    dateString: {
      marginLeft:'24px',
      fontFamily: 'Heebo',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      textAlign: 'left',
      color: '#475569'
    },
    tableHeaderText: {
      textAlign: "left",
      font: 'inter',
      color: '#475569',
      fontSize: "14px",
      lineHeight:'22px',
      fontWeight: 700,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    tableContentText:{
      fontFamily: 'Heebo',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      textAlign: 'left',
      color: '#232323',
      paddingTop:'8px',
      paddingBottom:'8px'
    },
    searchBox: {
      minWidth: '830px',
      display: 'flex',
      maxHeight: '56px',
      alignItems: 'center',
      alignContent: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      border: '1px solid rgba(214, 211, 209, 1)'
    },
    footerBlock: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '24px 52px 24px 52px',
      backgroundColor: '#1C1C1C',
      color: '#fff',
    },
    leftFooter: {
      display: 'flex',
      gap: '24px',
      flexGrow:1
    },
    rightFooter: {},
    modalRowViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dropdownViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 280,
      justifyContent: 'space-between',
      padding: 10,
      border: '1px solid #ccc',
      borderRadius: 5,
    },
    modalButtonViewStyle: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px 0px',
    },
    tableButtonViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerButtonViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    secondButtonViewStyle: {
      marginLeft: 10,
    },
    btnAddAccounts: {
      backgroundColor: '#005500',
      marginLeft: 10,
    },
    btnDeleteAccounts: {
      backgroundColor: '#990000',
      marginLeft: 10,
    },
    checkCircleRed: {
      height: 10,
      width: 10,
      backgroundColor: 'red',
      borderRadius: 5,
    },
    innerTableBox: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    arrow: {
      height: 20,
      width: 20,
      resizeMode: 'contain',
    },
    dropdownListContainer: {
      maxHeight: 200,
      overflow: 'auto',
      width: 300,
    },
    errorMsg: {
      color: 'red',
      margin: 10,
    },
    mainWrapper: {
      flex: 1,
      flexDirection: 'col',
      backgroundColor: '#f6f6f6',
  
    },
    mainBlock: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor:'#f6f6f6',
    },
    pageContent: {
      display: 'flex',
      overflowX:'auto',
      flexDirection: 'column', 
      alignItems:'left',
      paddingLeft:'21.5px',
      paddingRight:'40.5px',
      width:"100%",
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      textAlign:"center",
      justifyContent:'center',
      color:"black"
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height:'92px',
    },
    navigation: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'
    },
    currentNavigationPage: {
      fontWeight: '700',
      fontSize: '24px',
      padding: '4px 4px'
    },
    breadcrumbButton: {
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      fontSize: '24px',
      fontWeight:'bolder',
      fontFamily:'Heebo',
      cursor:'pointer'
    },
  };
// Customizable Area End
