// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean,
  currentTab:string,
  userData:any,
  userId:any;
  filteredLearningPaths:any[],
  formData: any | {
    firstName: string,
    lastName: string,
    jobTitle: string,
    email: string
  },
  selectedCourses: any[];
  selectedToUnassign:any[];
  assignedCourses:any[];
  assignedIds:any[];
  selectedLearningPaths: any[];
  searchQuery: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}

export default class ClientUserManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLearningPathsApiCallId: string;
  getUserDetailsApiCallId:string;
  updateUserDetailsApiCallId:string;
  assignCoursesApiCallId:string;
  getUserCoursesApiCallId:string;
  tabs = {
    PERSONAL_INFO: 'personal_info',
    ASSIGN_COURSES: 'assign_courses',
    ANALYTICS: 'user_analytics'
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      loading: false,
      currentTab: 'personal_info',
      userData: null,
      filteredLearningPaths: [],
      userId:null,
      formData: {
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
      },
      selectedCourses: [],
      selectedToUnassign:[],
      assignedCourses:[],
      assignedIds:[],
      selectedLearningPaths: [],
      searchQuery: '',

    };
    this.getLearningPathsApiCallId = '';
    this.getUserDetailsApiCallId = '';
    this.getUserCoursesApiCallId='';
    this.updateUserDetailsApiCallId = '';
    this.assignCoursesApiCallId = '';
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const userIdParam = localStorage.getItem('selectedUserId')
    this.setState({ userId: userIdParam }, () => {
      this.getUserDetails(userIdParam);
      this.getUserAssignedCourses(userIdParam);
    });
    this.getLearningPaths();
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getUserDetailsApiCallId: this.handleUserDetailsResponse(responseJson);
            break;
            case this.updateUserDetailsApiCallId: this.handleUpdateUserResponse(responseJson);
            break;
          case this.getLearningPathsApiCallId: this.handleLearningPathsResponse(responseJson);
            break;
            case this.assignCoursesApiCallId: this.handleAssignCoursesResponse(responseJson);
            break;
            case this.getUserCoursesApiCallId: this.handleUserCoursesResponse(responseJson);
            break;
            
        }
      }
    }
    // Customizable Area End
  }

  onChangeSearchQuery = (e: any) => {
    this.setState({ searchQuery: e.target.value },()=>{
      this.getLearningPaths();
    });
  }

  getLearningPaths = async () => {
    this.setState({ loading: true });
    let endPoint = `bx_block_categories/learning_paths`;
    if (this.state.searchQuery.length) {
      endPoint = endPoint + `/search_learning_path?query=${this.state.searchQuery}`
    }
    this.getLearningPathsApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: endPoint,
      token: localStorage.getItem("token"),
    });
  }

  getUserDetails = async (userIdParam:any) => {
    this.getUserDetailsApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_admin/user_managements/${userIdParam}`,
      token: localStorage.getItem("token"),
    });
  } 

  getUserAssignedCourses = async (userIdParam:any) => {
    this.getUserCoursesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_admin/courses/assigned_courses?user_id=${userIdParam}`,
      token: localStorage.getItem("token"),
    });
  } 

  userName = () => this.state.userData?.attributes.first_name + this.state.userData?.attributes.last_name || '';
 
  onChangeUserDetails = (e: any, key: string) => {
    let updatedData: any = this.state.formData;
    updatedData[key] = e.target.value;
    this.setState({formData:updatedData});
  }

  onSaveUserDetails = () => {
    const errors: any[] = [];
    Object.keys(this.state.formData).forEach((key: any) => {
      if (!this.state.formData[key]) {
        errors.push(`Error: The field "${key}" cannot be empty or null.`);
      }
    });
    if (errors.length > 0) {
      toast.error(errors.join('\n'));
      return;
    } else {
      this.updateUserDetails();
    }
  }

  updateUserDetails = async () => {
    this.setState({ loading: true });
    const reqBody = {
      data: {
        first_name: this.state.formData.firstName || '',
        last_name: this.state.formData.lastName || '',
        job_title: this.state.formData.jobTitle || '',
        email: this.state.formData.email || '',
      }
    }
    this.updateUserDetailsApiCallId = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      endPoint: `/bx_block_admin/user_managements/${this.state.userId}`,
      body: reqBody,
      token: localStorage.getItem("token"),
    });
  }

  isSelectedCourse = (course: any) => this.state.selectedCourses.includes(course.id) || this.isAlreadyAssigned(course);
  isAlreadyAssigned = (course: any) => this.state.assignedIds.includes(course.id) && !this.state.selectedToUnassign.includes(course.id);

  handleCourseSelection = (course: any) => {
    let updatedSelection = this.state.selectedCourses;
    let unassigneData = this.state.selectedToUnassign;
    const isFromAssigned = this.state.assignedIds.includes(course.id);
    const isFromSelectedToUnassign = this.state.selectedToUnassign.includes(course.id);
    if (isFromAssigned) {
      if (isFromSelectedToUnassign) {
        unassigneData = unassigneData.filter(item => item != course.id)
      } else {
        unassigneData.push(course.id);
      }
    }else if (updatedSelection.includes(course.id)) {
      updatedSelection = updatedSelection.filter(item => item != course.id);
    } else {
      updatedSelection.push(course.id);
    }
    this.setState({ selectedCourses: updatedSelection, selectedToUnassign: unassigneData });
  }

  showAssignBtn=()=> this.state.selectedCourses.length || this.state.selectedToUnassign.length;

  isSelectedLearningPath = (learningPath: any) => this.state.selectedLearningPaths.includes(learningPath.id);
   
  isAlreadySelected = (course: any) => this.state.selectedCourses.includes(course.id);
  isFromAssigned = (course: any) => this.state.assignedIds.includes(course.id);

  handleLearningPathSelect = (e:any,learningPath: any) => {
    e?.stopPropagation();
    let updatedLearningPaths = this.state.selectedLearningPaths;
    let updatedCourses: any[] = this.state.selectedCourses;
    let unassigneData:any[]=this.state.selectedToUnassign;
    if (updatedLearningPaths.includes(learningPath.id)) {
      updatedLearningPaths = updatedLearningPaths.filter((path: any) => path != learningPath.id);
      learningPath.attributes.courses.forEach((course: any) => {
        if (this.isAlreadySelected(course) && !this.isFromAssigned(course)) {
          updatedCourses = updatedCourses.filter(item => item != course.id)
        } else if (this.isFromAssigned(course)) {
          unassigneData.push(course.id);
        }
      });
    } else {
      updatedLearningPaths.push(learningPath.id);
      updatedCourses = this.state.selectedCourses;
      learningPath.attributes.courses.forEach((course: any) => {
        if (!this.isAlreadySelected(course) && !this.isFromAssigned(course)) {
          updatedCourses.push(course.id);
        }
      })
    }
    this.setState({ selectedCourses: updatedCourses,selectedToUnassign:unassigneData,selectedLearningPaths:updatedLearningPaths});
  }

  updateCourses = async (method: string = 'POST') => {
    const data = {
      course_ids: method === 'POST' ? this.state.selectedCourses : this.state.selectedToUnassign,
      account_ids: [this.state.userId],
    }
    if (method === 'DELETE') {
      this.setState({ selectedToUnassign: [] });
    }
    this.assignCoursesApiCallId = await apiCall({
      method: method,
      contentType: "application/json",
      endPoint: `/bx_block_account_groups/assign_courses`,
      body: data,
      token: localStorage.getItem("token"),
    });
  }

  assignSelected = async () => {
    if (this.state.selectedCourses.length) {
      this.updateCourses();
    } else if (this.state.selectedToUnassign.length) {
      this.updateCourses('DELETE');
    }
  }

  createFormBody = () => {
    this.setState({
      formData: {
        firstName: this.state.userData.attributes.first_name,
        lastName: this.state.userData.attributes.last_name,
        jobTitle: this.state.userData.attributes.job_title,
        email: this.state.userData.attributes.email,
      }
    })
  }

  handleUserDetailsResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ userData: responseJson.data }, () => {
        this.createFormBody();
      });
    } else if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors || responseJson.error);
    }
  }

  handleUserCoursesResponse = (responseJson: any) => {
    if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors);
    } else if (responseJson) {
      this.setState({ assignedCourses: responseJson.length ? responseJson : [] }, () => {
        let assignedIds: any[] = [];
        this.state.assignedCourses.forEach(course => {
          assignedIds.push(course.course_id)
        });
        this.setState({ assignedIds: assignedIds })
      });
    }
  }

  refreshData = () => {
    this.setState({ selectedCourses: [],selectedLearningPaths:[], selectedToUnassign: [], assignedCourses: [], assignedIds: [] },()=>{
      this.getLearningPaths();
    })
  }

  handleAssignCoursesResponse = (responseJson: any) => {
    if (responseJson?.message) {
      if (this.state.selectedToUnassign.length) {
        this.updateCourses('DELETE');
        return;
      }
      toast.success('Courses updated successfully!');
      this.refreshData();
    } else if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors);
    }
  } 

  handleUpdateUserResponse = (responseJson: any) => {
    if (responseJson?.message) {
      toast.success(responseJson.message);
    } else if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors);
    }
  }

  handleLearningPathsResponse = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ filteredLearningPaths: responseJson.data });
      this.getUserAssignedCourses(this.state.userId);
    } else if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors);
    }
  }
  
  onTabSwitch = (tab: string) => {
    this.setState({ currentTab: tab });
  }

  isSelectedTab = (tab: string) => this.state.currentTab === tab;
  
  onRedirect = () => {
		this.props.navigation.navigate("Clients");
	}

	onRedirectToClient = () => {
		this.props.navigation.navigate("ManageClient");
	}

  onRedirectToUser = () => {
		this.props.navigation.navigate("ClientManagement");
	}
  // Customizable Area End
}