import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { apiCall } from "../../../../packages/components/src/ApiCall.web";
import { toast } from "react-toastify";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage:number
  tableData:any
  sortConfig:any
  overAllData:any;
  metaData: {
    total_pages: number;
    current_page: number;
    total_count: number;
    page:number;
  };
  searchUsers:string;
  isLoadingTable:boolean;
  isLoadingPage:boolean;
  clientList: any;
  selectedClients: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserAnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllUserAnalyticsApiCallId:string =""
  getClientListApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentPage:0,
      tableData :[],
      sortConfig: {
        key: null,
        direction: "asc", // or "desc"
      },
      overAllData:{},
      metaData: {
        total_pages: 0, 
        current_page: 1,
        total_count: 0,
        page:0,
      },
      searchUsers:"",
      isLoadingTable:false,
      isLoadingPage:false,
      clientList: [],
      selectedClients: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

 // Customizable Area Start
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async componentDidMount() {
    this.setState({isLoadingPage:true})
    this.getAllUserData()
    this.getAllClients()
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getAllUserAnalyticsApiCallId){
        if (responseJson.error) {
          toast.error(responseJson.error)
        } else {
          this.handleGetUserResponse(responseJson)
        }  
      } if (apiRequestCallId === this.getClientListApiCallId) {
        if (responseJson.error) {
          toast.error(responseJson.error)
        } else {
          this.receiveAllClientResponse(responseJson)
        } 
      }
    }
    // Customizable Area End
  }
  handleChangePageUserTable = (
    event: React.ChangeEvent<unknown>,
    newPage: number,)=>{
    this.setState({currentPage:newPage - 1,isLoadingTable:true})
  }

  getAllClients = async () => {
    this.getClientListApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/clients/client_list`,
    });
  }

  receiveAllClientResponse = (response: any) => {
    this.setState({
      clientList: response.clients.data,
    })
  }

  handleChangeTabUserAnalytics=(value:string)=>{
    this.props.navigation.navigate(value)
  }
  /* istanbul ignore next */
   handleSort = (key:string) => {
    this.setState((prevState) => {
      const { tableData, sortConfig } = prevState;
      const direction =
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc";

      const sortedData = [...tableData].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === "asc" ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      });

      return {
        tableData: sortedData,
        sortConfig: { key, direction },
      };
    });
  };

  getAllUserData = async () => {
    const {searchUsers}=this.state
    this.getAllUserAnalyticsApiCallId = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      endPoint: `/bx_block_projectreporting/user_analytics`,
      token: localStorage.getItem('token'),
    });
  }

  handleGetUserResponse =(response: any) => {
    this.setState({
      tableData:response.course_details,
      overAllData:response.overall_stats,
      metaData:{
        ...this.state.metaData,
        current_page: response.meta.current_page,
        total_count: response.meta.total_count,
        total_pages: response.meta.total_pages,
      },
      isLoadingTable:false,
      isLoadingPage:false,
     })
    
  }

  /* istanbul ignore next */
  handleCheckboxToggle = (clientId: number) => {
    this.setState(
      (prevState) => {
        const isSelected = prevState.selectedClients.includes(clientId);
        const updatedSelectedClients = isSelected
          ? prevState.selectedClients.filter((id: number) => id !== clientId)
          : [...prevState.selectedClients, clientId];

        return { selectedClients: updatedSelectedClients };
      },
      () => {
        this.getSearchUserAnalytics();
        this.setState({ currentPage: 1 });
      }
    );
  };

   /* istanbul ignore next */
   handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({
      metaData: {
        ...this.state.metaData,
        current_page: value,
        page: value - 1
      },
      isLoadingTable:true
    });
    
    this.getAllUserAnalyticsApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/user_analytics?page=${value}&per_page=10`,
    });
  };

  /* istanbul ignore next */ 
  getSearchUserAnalytics = async () => {
    let clientIdsParam = "";
    if (this.state.selectedClients.length > 0) {
      const clientIdsString = this.state.selectedClients.join(",");
      clientIdsParam = `?client_ids=${clientIdsString}`;
    }
    this.getAllUserAnalyticsApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/user_analytics${clientIdsParam}`,
    });
    this.setState({isLoadingPage:true})
  }

  /* istanbul ignore next */
  renderClientNames = (selected: any) => {
    if (!selected || this.state.selectedClients.length === 0) {
      return 'All Users';
    }

    return this.state.clientList
      .filter((client: any) => selected.includes(client.id))
      .map((client: any) => client.attributes.company_name)
      .join(', ');
  };
}
// Customizable Area End
