import React from "react";

import {
  Container,
  // Customizable Area Start
    Modal,
    Select as MuiSelect,
    MenuItem,
    Box,
    Button,
    IconButton,
    Typography,
    Pagination,
    TableFooter,
    TextField,
    styled,
    InputAdornment,
    DialogTitle,
    Select,
    FormControl
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Search } from '@mui/icons-material';
import Sidebar from "./../../../components/src/sidebar/Sidebar.web";
import Loader from "../../../components/src/Loader.web";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "react-calendar/dist/Calendar.css";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledDatePicker = styled(DatePicker)`
  outline: none; 
  backgroundColor:'#f8fafc';
  padding:10px;
  input {
  height:50px
    box-shadow: none !important;
    padding: 10px; 
    backgroundColor:'#f8fafc';
  }`;
// Customizable Area End

import AuditTrailDetailController, {
  Props,
} from "./AuditTrailDetailController";

export default class AuditTrailDetail extends AuditTrailDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  startResult = () => (this.state.auditTrail.meta.current_page - 1) * this.state.itemsPerPage + 1;

  endResult = () => Math.min(this.startResult() + this.state.itemsPerPage - 1, this.state.auditTrail.meta.total_count);

  renderDateRangePicker = () => {
    return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'relative', zIndex: 2000 }}>
      <StyledDatePicker
        selected={this.state.startDate}
        data-test-id={'startDate'}
        onChange={(date) => this.setStartDate(date)}
        selectsStart
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        placeholderText="Start Date"
        dateFormat="MMMM dd, yyyy"
        isClearable
      />
      <StyledDatePicker
        selected={this.state.endDate}
        onChange={(date) => this.setEndDate(date)}
        data-test-id={'endDate'}
        selectsEnd
        startDate={this.state.startDate}
        endDate={this.state.endDate}
        minDate={this.state.startDate}
        placeholderText="End Date"
        dateFormat="MMMM dd, yyyy"
        isClearable
      />
    </Box>
    )
  }

  renderModalMenu = () => {
    return (
      <Modal
        open={this.state.filterModalVisible}
        onClose={this.closeFilterModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      ><FilterAudits>
          <FilterAuditsHeader>
            <Typography className="title">Filter audits</Typography>
            <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.closeFilterModal} />
          </FilterAuditsHeader>
          <FilterAuditsContent>
            <Box className="dialog-content">
              {this.state.showAllFilters && <> <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }}>
                  <FormControl fullWidth variant="outlined">
                    <Typography id="demo-simple-select-label">Select action type</Typography>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      data-test-id={'actionSelect'}
                      value={this.state.selectedActionType}
                      onChange={this.handleActionTypeChange}
                      label="Select audit action"
                      placeholder="Select audit action"
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D6D3D1',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D6D3D1',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#D6D3D1',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            padding: 10,
                            overflowY: 'auto',
                          },
                        },
                      }}
                    >
                      {this.state.auditActions?.map((item: any, index: number) => (
                        <MenuItem key={index} sx={{ padding: '5px' }} value={item}>{item}</MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Box>
                <Box sx={{ alignContent: 'center', alignItems: 'center' }}>
                  {this.state.selectedActionType && <IconButton sx={{ marginLeft: '5px', marginTop: '20px' }} data-test-id={'removeAction'} onClick={() => { this.removeActionTypeFilter() }}><CloseIcon></CloseIcon></IconButton>}
                </Box>
              </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box sx={{ width: '100%' }}>
                    <FormControl fullWidth variant="outlined">
                      <Typography id="demo-simple-select-label">Select admin user</Typography>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        data-test-id={'adminSelect'}
                        value={this.state.selectedAdminUser}
                        onChange={this.handleAdminUserChange}
                        label="Select admin user"
                        placeholder="Select admin user"
                        sx={{
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D6D3D1',
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D6D3D1',
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D6D3D1',
                          },
                        }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              padding: 10,
                              overflowY: 'auto',
                            },
                          },
                        }}
                      >
                        {this.state.adminNames?.map((item: any, index: number) => (
                          <MenuItem sx={{ padding: '5px' }} key={index} value={item}>{item.full_name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl></Box>
                  <Box sx={{ alignContent: 'center', alignItems: 'center' }}>
                    {this.state.selectedAdminUser && <IconButton sx={{ marginLeft: '5px', marginTop: '20px' }} data-test-id={'removeAdmin'} onClick={() => { this.removeAdminUserFilter() }}><CloseIcon></CloseIcon></IconButton>}
                  </Box>
                </Box></>}
              <Box>
                <Typography id="demo-simple-select-label">Select Start and End date</Typography>
                {this.renderDateRangePicker()}
              </Box>
            </Box>
          </FilterAuditsContent>
          <FilterAuditsFooter>
            <CancelButton onClick={this.closeFilterModal}>Cancel</CancelButton>
            <DoneButton data-test-id="applyFilter" type="submit" onClick={()=>{this.applyFilter()}}>Apply</DoneButton>
          </FilterAuditsFooter>
        </FilterAudits>
      </Modal>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
      <Box sx={webStyles.mainWrapper}>
         <Box sx={webStyles.mainBlock}>{this.renderModalMenu()}
         <Loader loading={this.state.loading} />
         <Box>
           <Sidebar navigation={this.props.navigation} current='settings' />
         </Box>
            <Box sx={webStyles.pageContent} data-testid="pageContent">
              <Box sx={webStyles.contentHeader}>
                <Box sx={webStyles.breadcrumbButton}>Audit trail
                  <Box data-test-id="dateFilter" onClick={()=>{this.openFilterModal(false)}} >
                    <Typography sx={webStyles.dateString}>{this.getDateString()}<IconButton><ArrowDropDownIcon/></IconButton></Typography></Box>
                </Box>
                <Box>
                  <DownloadButton
                    onClick={() => {this.exportCSV()}}
                    data-test-id="downloadCSV"
                  >Download CSV report
                  </DownloadButton>
                </Box>
              </Box>
              <Box display="flex" flexDirection={'row'} alignItems="center" justifyContent="space-between" bgcolor="#f5f5f5" sx={{paddingBottom:'20px',height:'56px'}}>
                <Box sx={webStyles.searchBox}>
                  <TextField
                    InputProps={{
                      sx: {
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                          height:'100%',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '100%',
                          display:'flex',
                          alignItems:'center',
                        },
                        '& input': {
                          paddingLeft:'16px',
                          lineHeight:'24px',
                          fontSize: '16px',
                        },
                        backgroundColor: 'white'
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    placeholder="Search"
                    data-test-id='searchInput'
                    sx={{ width: "100%", height: "100%", marginLeft: "4px" }}
                    value={this.state.searchQuery}
                    onChange={(e) => this.onChangeSearchQuery(e.target.value)} /></Box>
                <Box sx={{ display: 'flex', alignItems: "center" }}>
                 <Box data-test-id="allFilter" display="flex" alignItems="center" sx={{ marginRight: "20px" }} onClick={()=>{this.openFilterModal(true)}}>
                   <Typography sx={webStyles.filterText}>Filter</Typography>
                   <IconButton aria-label="filter">
                     <FilterListIcon />
                   </IconButton>
                 </Box>

                 <Box sx={{ display: 'flex', alignItems: 'center', }}>
                   <Typography sx={webStyles.filterText}>Sort by:</Typography>
                   <MuiSelect
                     data-test-id="sortSelect"
                     value={this.state.sortBy}
                     onChange={(e: any) => this.handleSortChange(e.target.value)}
                     defaultValue="relevance"
                     disableUnderline
                     variant='standard'
                     sx={{
                       paddingLeft: '10px',
                       backgroundColor: 'transparent',
                       '& .MuiSelect-icon': {
                         color: 'gray',
                       },
                       '& .MuiOutlinedInput-root': {
                         backgroundColor: 'transparent',
                         border: '1px solid #ccc',
                         '& input': {
                           backgroundColor: 'transparent',
                           padding: '',
                           border:'none'
                         },
                       },
                     }}
                   >
                     <MenuItem value="User(A-Z)">User (A-Z)</MenuItem>
                     <MenuItem value="User(Z-A)">User (Z-A)</MenuItem>
                     <MenuItem value="Date">Date</MenuItem>
                     <MenuItem value="Action">Action</MenuItem>
                   </MuiSelect>
                 </Box>
               </Box>
             </Box>
             <Box sx={{padding:'0px',margin:'0px'}}>
               <Paper style={webStyles.tableViewStyle}>
                  <TableContainer sx={{
                    maxHeight: 400,
                    overflowY: 'auto',
                    width: '100%',
                  }}>
                   <Table stickyHeader>
                     <TableHead sx={{zIndex:0}}>
                       <TableRow>
                         <TableCell sx={{...webStyles.tableHeaderText, minWidth:'180px'}}>Date and time</TableCell>
                         <TableCell sx={{...webStyles.tableHeaderText, minWidth:'180px'}}>User</TableCell>
                         <TableCell sx={{...webStyles.tableHeaderText, minWidth:'310px'}}>Action</TableCell>
                         <TableCell sx={{...webStyles.tableHeaderText, minWidth:'310px'}}>Description</TableCell>
                       </TableRow>
                     </TableHead>
                     <TableBody>
                        {this.state.auditTrail?.data?.map((account: any, index: number) => (
                          <TableRow
                            key={account.id}
                            style={{
                              backgroundColor: account.isSelected ? '#f8fafc' : '#FFFFFF',
                            }}
                          >
                            <TableCell sx={webStyles.tableContentText}> {this.convertTimestampToCustomFormat(account.attributes.updated_at)}</TableCell>
                            <TableCell sx={webStyles.tableContentText}>{account.attributes.admin_user_name}</TableCell>
                            <TableCell sx={webStyles.tableContentText} >{account.attributes.action}</TableCell>
                            <TableCell sx={webStyles.tableContentText}>{account.attributes.description}</TableCell>
                          </TableRow>
                        ))}
                     </TableBody>
                     <TableFooter>
                          {this.state.auditTrail?.meta && <TableRow>
                            <TableCell colSpan={7} align="center" sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fff', zIndex: 2 }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div style={{ flex: 1 }}></div>
                                <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
                                  <Pagination
                                    data-test-id="pagination"
                                    count={this.state.auditTrail.meta.total_pages}
                                    page={this.state.auditTrail.meta.current_page}
                                    onChange={(event, value) => this.getAuditDetail(value)}
                                    siblingCount={1}
                                    boundaryCount={1}
                                  />
                                </div>
                                <div style={{ flex: 1, textAlign: 'right' }}>
                                  {`${this.startResult()}-${this.endResult()} of ${this.state.auditTrail.meta.total_count} results`}
                                </div>

                              </div>
                            </TableCell>
                          </TableRow>}
                        </TableFooter>

                   </Table>
                 </TableContainer>
               </Paper>
             </Box>
           </Box>
         </Box>
       </Box>
       <Box sx={webStyles.footerBlock}>
         <Box sx={webStyles.leftFooter}>
           <Box>Terms and Conditions</Box>
           <Box>Privacy Policy</Box>
           <Box></Box>
         </Box>

         <Box>
           <Box>
           © 2024 Thinkspike, All Rights Reserved</Box>
         </Box>
       </Box>
   </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const FilterAudits = styled(Box)({
  width: 600,
  backgroundColor: "#FFFFFF",
  position: 'absolute',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px",
});

const DoneButton = styled(Button)({
  margin: "15px !important",
  width: "95px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});

const CancelButton = styled(Button)({
  margin: "15px !important",
  width: "95px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  background: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const FilterAuditsHeader = styled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 30px",
  borderBottom: "1px solid #E2E8F0",
  "& .title": {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "28px",
      padding: "10px 0",
  }
})

const FilterAuditsContent = styled(Box)({
  margin: "16px 0",
  padding: "24px 40px 24px 40px",
  "& .dialog-content": {
      display: "flex",
      flexDirection: "column",
      gap: 16,
  },
  "& .label-title": {
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "700",
      lineHeight: "22px",
      color: "#334155",
      marginBottom: "4px"
  },
  "& .label-title-assessment":{
      fontFamily: "Heebo",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "32px",
      color: "##1C1C1C",
      marginBottom: "4px"
  }
})

const FilterAuditsFooter = styled(Box)({
  borderTop: "1px solid #E2E8F0",
  padding: "17px 23px 2px",
  display: "flex",
  justifyContent: "flex-end",
})

const DownloadButton = styled(Button)({
  fontFamily: 'Heebo',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '400',
  color: '#fff',
  padding: '10px 16px',
  borderRadius: '8px',
  backgroundColor: '#000000',
  "&:hover": {
      backgroundColor: "black",
  },
});
const webStyles = {
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  filterText:{
    fontFamily: 'Heebo',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '22px',
    textAlign: 'left',
    color:' #334155'
  },
  dateString: {
    marginLeft:'24px',
    fontFamily: 'Heebo',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '24px',
    textAlign: 'left',
    color: '#475569'
  },
  tableHeaderText: {
    fontFamily: 'Heebo',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#6A6A6A',
    paddingTop:'4px',
    paddingBottom:'4px'
  },
  tableContentText:{
    fontFamily: 'Heebo',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#232323',
    paddingTop:'8px',
    paddingBottom:'8px'
  },
  searchBox: {
    minWidth: '830px',
    display: 'flex',
    maxHeight: '56px',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid rgba(214, 211, 209, 1)'
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow:1
  },
  rightFooter: {},
  modalRowViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dropdownViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 280,
    justifyContent: 'space-between',
    padding: 10,
    border: '1px solid #ccc',
    borderRadius: 5,
  },
  modalButtonViewStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '10px 0px',
  },
  tableViewStyle: {
    overflow: 'hidden',
    borderRadius:"6px"
  },
  tableContainerStyle: {
    maxHeight: 600,
    zIndex:0,
    width:'100%',
  },
  tableButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerButtonViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
  btnAddAccounts: {
    backgroundColor: '#005500',
    marginLeft: 10,
  },
  btnDeleteAccounts: {
    backgroundColor: '#990000',
    marginLeft: 10,
  },
  checkCircleRed: {
    height: 10,
    width: 10,
    backgroundColor: 'red',
    borderRadius: 5,
  },
  innerTableBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrow: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
  },
  dropdownListContainer: {
    maxHeight: 200,
    overflow: 'auto',
    width: 300,
  },
  errorMsg: {
    color: 'red',
    margin: 10,
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6',

  },
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor:'#f6f6f6',
  },
  pageContent: {
    display: 'flex',
    overflowX:'auto',
    flexDirection: 'column', 
    alignItems:'left',
    paddingLeft:'21.5px',
    paddingRight:'40.5px',
    width:"100%",
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    textAlign:"center",
    justifyContent:'center',
    color:"black"
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height:'92px',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 4px'
  },
  breadcrumbButton: {
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    fontSize: '24px',
    fontWeight:'bolder',
    fontFamily:'Heebo'
  },
};
// Customizable Area End
