import React from 'react';

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    styled,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    MenuItem,
    Checkbox,
    ListItemText,
    Pagination,
    CircularProgress,
    AppBar,
    Tabs,
    Tab,
    Popover,
    Chip,
    StyledEngineProvider,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ArrowDropUp, ArrowDropDown, KeyboardArrowDown, FilterList, Close } from '@mui/icons-material';
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import CustomSelect from '../../../../packages/components/src/CustomSelect.web'


const  StyledMenuItem = styled(MenuItem)(({ theme,selected }) => ({
    padding: 0,
    borderRadius: "2px",
    border:"1px solid #D7D7D7",
    backgroundColor:"#fff",
    margin:"10px",
    "&:hover": {
      backgroundColor: "#fff",
    },
    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "500",
      color:"#000",
      marginLeft:"10px"
    },
}));

const theme2 = createTheme({
    palette: {
      secondary: {
        main: '#fff'
      },
      primary: {
        contrastText: "#fff",
        main: "#000",
      },
    },
    typography: {
      subtitle1: {
        margin: "20px 0px",
      },
      h6: {
        fontWeight: 500,
      },
    },
  });

// Customizable Area End

import ClientWideAnalyticsController, {
    Props,
} from './ClientWideAnalyticsController';


export default class ClientWideAnalytics extends ClientWideAnalyticsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    tabConfigurations = [
        { label: 'User Analytics', value: 'UserAnalytics' },
        { label: 'Client-Wide Analytics', value: 'ClientWideAnalytics' },
        { label: 'Course Analytics', value: 'CourseAnalytics' },
        { label: 'Feedback Aggregation', value: "Analytics" },
        { label: 'Reports', value: 'ReportsAnalytics' },
      ];
    // Customizable Area End

    render() {
        // Customizable Area Start
        const {
            clientsCount,
            usersCount,
            totalCoursesAssigned,
            totalCoursesCompleted,
            accreditationsAchieved,
            averageUserCompletionRate,
            totalLearningPathCompleted,
            totalLearningHours,
            tableData,
            order,
            orderBy,
            clientList,
            selectedClients,
            currentPage,
            rowsPerPage
        } = this.state;

        const totalRows = tableData.length;
        const startIndex = (currentPage - 1) * rowsPerPage + 1;
        const endIndex = Math.min(startIndex + rowsPerPage - 1, totalRows);

        const columns = [
            { id: "clientName", label: "Client Name" },
            { id: "users", label: "Users" },
            { id: "courseAssigned", label: "Courses Assigned" },
            { id: "coursesCompleted", label: "Courses Completed" },
            { id: "accreditationAchieved", label: "Accreditation Achieved" },
            { id: "avgCompletionRate", label: "Average User Completion Rate" },
            { id: "totalLearningPathCompleted", label: "Total Learning Path Completed" },
            { id: "totalLearningHours", label: "Total Learning Hours" },
        ];

        const renderTabs = () => {
            return (
                <Box width={"fit-content"}>
                <AppBar position="static" style={{ maxHeight:'60px',padding:'10px' ,width: "60vw", boxShadow: 'none', borderRadius: '4px' }} color="secondary">
                  <Tabs
                    data-testID="Tabs"
                    textColor="secondary"
                    value="ClientWideAnalytics"
                    aria-label="analytics tabs"
                    TabIndicatorProps={{
                      style: {
                        display: 'none'
                      },
                    }}
                    style={{ gap: '10px' }}
                  >
                    {this.tabConfigurations.map((tab, index) => (
                      <Tab
                      data-test-id={`tab-${index}`}
                        key={index}
                        onClick={() => this.handleChangeTab(tab.value)}
                        label={
                          <Typography
                              sx={{
                                textTransform: 'none',
                                fontSize: '12px',
                                fontFamily: 'Heebo-bold',
                                lineHeight: '18px',
                                padding: '12px 0',
                                width:'max-content',
                                fontWeight: '700',
                              }}
                            >
                              {tab.label}
                            </Typography>
                        }
                        value={tab.value}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderBottom: 'none',
                          },
                          borderRadius: '4px',
                          color: tab.value === 'ClientWideAnalytics' ? 'white' : '#6A6A6A',
                          backgroundColor: tab.value === 'ClientWideAnalytics' ? 'black' : 'inherit',
                          boxSizing:'border-box',
                          flex:1,
                          padding:'0 16px',
                          minHeight:0,
                          height:'100%',
                          maxHeight:'60px',
                        }}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Box>
            )
        }
        const renderCards = () => {
            return (
                <GridContainer>
                    <Card>
                        <LabelText>Clients</LabelText>
                        <LabelValue>{clientsCount}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Users</LabelText>
                        <LabelValue>{usersCount}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Courses Assigned</LabelText>
                        <LabelValue sx={{color:'#F59E0B'}}>{totalCoursesAssigned}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Courses Completed</LabelText>
                        <LabelValue>{totalCoursesCompleted}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Accreditations Achieved</LabelText>
                        <LabelValue sx={{color:'#F59E0B'}}>{accreditationsAchieved}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Average User Completion Rate</LabelText>
                        <LabelValue sx={{color:'#F59E0B'}}>{averageUserCompletionRate}%</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Learning Path Completed</LabelText>
                        <LabelValue sx={{color:'#059669'}}>{totalLearningPathCompleted}</LabelValue>
                    </Card>
                    <Card>
                        <LabelText>Total Learning Hours</LabelText>
                        <LabelValue sx={{color:'#059669'}}>{totalLearningHours}h</LabelValue>
                    </Card>
                </GridContainer>
            )
        }

        const renderClientWideAnalytics = () =>{
            return(
                <Box display={"flex"} overflow={'hidden'}>
                   
                        <Box>
                            <Sidebar current='analytics' navigation={this.props.navigation} />
                        </Box>
                        <Box
                        gap={"16px"}
                        flexDirection={"column"}
                        padding={"24px 48px"}
                        display={"flex"}
                        width={"100%"}
                        sx={{ backgroundColor: '#F0F0F0', overflow: 'auto' }}>
                        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
                        <Box display={"flex"} gap="10px" alignItems={"center"}>
          <Typography
            style={{
              lineHeight: '32px', 
              fontSize: '24px', 
              fontWeight: '700', 
               color: '#1C1C1C'
               }}
          >Analytics
          </Typography>
          <>
            <Typography
              style={{ 
                fontWeight: '700', 
                color: '#475569', 
                fontSize: '16px', 
                lineHeight: '24px', 
              }}
            >
              Jul 30 - Aug 5
              </Typography>
            <KeyboardArrowDown style={{color:"#475569"}}/>
          </>
                        </Box>
                       <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
                       <Button
                        style={{ 
                         padding: '10px 16px', 
                        fontSize: '16px', 
                        color: '#fff', 
                        backgroundColor: '#000', 
                        textTransform: 'none',
                        lineHeight: '24px',
                        borderRadius: '4px', 
                        fontWeight: '700',
                         }}
                        >Export data
                        </Button>
                       </Box>
                       </Box>
                           
                       {renderTabs()}



                            <div style={{ padding: '1rem 0rem', display: 'flex', alignItems: 'center', columnGap: '10px', justifyContent: 'space-between' }}>
                                <StyledSelect
                                    displayEmpty
                                    data-test-id="dropdown"
                                    IconComponent={(props) =><ArrowDropDownIcon {...props} style={{ color: "black" }} />}
                                    value={selectedClients}
                                    multiple
                                    sx={{ width: '90%' }}
                                    renderValue={(selected: any) => this.renderClientNames(selected)}
                                    MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            '&.MuiPaper-root': {
                                              marginTop:"0.5%",
                                              border: '1px solid #ccc',
                                              boxShadow: 'none',
                                              height:"40%"
                                            },
                                          },
                                        },
                                        anchorOrigin: {
                                          vertical: "bottom",
                                          horizontal: "left",
                                        },
                                        transformOrigin: {
                                          vertical: "top",
                                          horizontal: "left",
                                        },
                                      }}
                                >
                                    <StyledMenuItem sx={{ display: 'none' }} value="" >
                                        All Client
                                    </StyledMenuItem>
                                    {clientList.map((client: any) => {
                                        return (
                                            <StyledMenuItem key={client.id} value={client.id}>
                                                <ListItemText key={client.id} primary={client.attributes.company_name} />
                                                <Checkbox
                                                    data-testId={`client-cb-${client.id}`}
                                                    checked={selectedClients.includes(client.id)}
                                                    onChange={() => this.handleCheckboxToggle(client.id)}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: 'black',
                                                            borderRadius: '6px',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                                            },
                                                        },
                                                    }}
                                                />
                                            </StyledMenuItem>
                                        );
                                    })}

                                </StyledSelect>

                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Box height={"100%"}>
                                   <Button
              data-test-id="filter-box"
              style={{
                fontFamily: 'Heebo', height: '100%',
                display: 'flex', textAlign: 'center', gap: '10px',
                alignItems: 'center', fontWeight: '700',
                padding: '10px 16px', borderRadius: '4px',
                fontSize: '16px', lineHeight: '24px',
                backgroundColor: 'transparent', color: '#000',
                textTransform: 'none'
              }}
              onClick={(e)=>{this.setState({anchorFilter:e.currentTarget})}}
            >
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#000' }}
              >
                Filter
              </Typography>
              <FilterList />
                                   </Button>
                                   </Box>
                                  <Popover
                                  open={Boolean(this.state.anchorFilter)}
                                  anchorEl={this.state.anchorFilter}
                                  onClose={()=>{this.setState({anchorFilter:null})}}
                                  slotProps={{
                                   paper: {style:{ width: '100%',maxWidth:'50vw',height:'auto', overflow:'visible' }},
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                  horizontal: 'left',
                                  }}
                                  >
              <Box width={'100%'} height={'100%'} padding={'20px'} boxSizing={'border-box'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <CustomSelect placeholder={'Course Title'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                <CustomSelect placeholder={'Accreditation status'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                <CustomSelect placeholder={'Location'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                </Box>
                <Box>
                  <Chip 
                  label='Course Title - Ascending' 
                  onDelete={()=>{}} 
                  deleteIcon={<Close style={{color:'black',width:'16px',height:'16px'}}/>} 
                  sx={{borderRadius:'4px',border:'1px solid #A8A29E',margin:'2% 5px'}}/>
                </Box>
              </Box>
                                   </Popover>  
                                 </div>
                                </div>
                            </div>

                            {this.state.isLoading ?
                                <Box sx={{ width: '100%', height: '100vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                    <CircularProgress
                                        style={{
                                            height: '100px',
                                            width: '100px',
                                            boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                                            borderRadius: '50%'
                                        }}
                                        sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
                                    />
                                </Box>
                                :
                                <div>
                                    <Box sx={{ padding: '1rem 0rem' }}>
                                        {renderCards()}
                                    </Box>

                                    <Box sx={{ padding: '1rem 0rem',marginBottom:'2rem' }}>
                                        <TableContainer sx={{ maxHeight: 400, overflowY: 'auto', borderRadius: '6px',background:'white' }} component={Paper}>
                                            <Table sx={{ minWidth: 1250}}>
                                                <TableHead>
                                                    <TableRow>
                                                        {columns.map((column) => (
                                                            <ColumnNameCell key={column.id}>
                                                                <div style={{ display: "flex", alignItems: "center", width: 'fit-content' }}>
                                                                    <span style={{width: column.id !=="clientName" ? "min-content":""}}>{column.label}</span>
                                                                    <div style={{ display: "flex", flexDirection: "column",alignItems:"center" }}>
                                                                       {column.id !=="clientName" && <ArrowDropUp
                                                                            data-test-id={`sort-icon-${column.id}`}
                                                                            fontSize='small'
                                                                            style={{
                                                                                marginBottom: "-10px",
                                                                                cursor: "pointer",
                                                                                color: orderBy === column.id && order === "asc" ? "black" : "gray",
                                                                            }}
                                                                            onClick={() => this.handleSort(column.id)}
                                                                        />}
                                                                        <ArrowDropDown
                                                                            data-test-id={`dsc-icon-${column.id}`}
                                                                            fontSize='small'
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                color: orderBy === column.id && order === "desc" ? "black" : "gray",
                                                                            }}
                                                                            onClick={() => this.handleSort(column.id)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </ColumnNameCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {tableData.slice(startIndex - 1, endIndex).map((row: any, index: number) => (
                                                        <TableRow key={index}>
                                                            <TabValueCell>{row.clientName}</TabValueCell>
                                                            <TabValueCell>{row.users}</TabValueCell>
                                                            <TabValueCell>{row.courseAssigned}</TabValueCell>
                                                            <TabValueCell>{row.coursesCompleted}</TabValueCell>
                                                            <TabValueCell>{row.accreditationAchieved}</TabValueCell>
                                                            <TabValueCell>{row.avgCompletionRate}%</TabValueCell>
                                                            <TabValueCell>{row.totalLearningPathCompleted}</TabValueCell>
                                                            <TabValueCell>{row.totalLearningHours}</TabValueCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            </TableContainer >
                                            <TableContainer sx={{ background:'white',borderBottomLeftRadius: '6px',borderBottomRightRadius:"6px" }} component={Paper}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem 1.5rem' }}>
                                                <span></span>
                                                <CustomPagination
                                                    data-test-id='pagination'
                                                    size='small'
                                                    siblingCount={0}
                                                    boundaryCount={1}
                                                    count={Math.ceil(totalRows / rowsPerPage)}
                                                    page={currentPage}
                                                    onChange={this.handlePageChange}
                                                />
                                                <Typography sx={{ fontSize: "12px" }}>
                                                    {startIndex}-{endIndex} of {totalRows} results
                                                </Typography>
                                            </Box>
                                            </TableContainer>
                                    </Box>
                                </div>
                            }
                        </Box>   
                  </Box>
            )
        }
        
        return (
            <StyledEngineProvider injectFirst>
             <ThemeProvider theme={theme2}>
                  {renderClientWideAnalytics()}
                  <Box>
                  <AdminFooter/>
                  </Box>
             </ThemeProvider>
            </StyledEngineProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const TabButton = styled(Button)({
    backgroundColor: 'white',
    fontFamily: 'Heebo',
    fontWeight: '700',
    textTransform: 'none',
    color: '#6A6A6A',
    fontSize: '16px',
    padding: '6px 10px'
})
const BlackButton = styled(Button)({
    textTransform: 'none',
    color: 'white',
    fontFamily: 'Heebo',
    backgroundColor: 'black',
    fontWeight: 700,
    height: 'fit-content',
    fontSize: '14px',
    '&:hover': {
        backgroundColor: 'black',
        color: 'white',
    },
})
const Card = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: '16px',
    paddingBottom: "10px",
    backgroundColor: 'white',
    borderRadius: '8px',
    borderBottomRightRadius: '32px'
})
const GridContainer = styled(Box)({
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '16px',
});
const LabelText = styled(Typography)({
    fontSize: '14px',
    fontFamily: 'Heebo-Bold',
    color: '#6A6A6A'
})
const LabelValue = styled(Typography)({
    fontSize: '1.6rem',
    fontFamily: 'Heebo',
    fontWeight: '700'
})
const StyledSelect = styled(Select)({
    backgroundColor: 'white',
    borderRadius: '4px',

    '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
        backgroundColor: 'white',
        borderRadius: '4px',
    },
})
const ColumnNameCell = styled(TableCell)({
    fontSize: '13px',
    lineHeight: '1.5',
    fontWeight: 'bold',
    color: "#475569",
    padding: '8px',
    paddingLeft: '12px'
})
const TabValueCell = styled(TableCell)({
    color: "#0F172A",
    fontFamily: 'Heebo',
    fontSize: '14px',
    fontWeight: '400'
})
const CustomPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        textDecoration:"underline",
        "&.Mui-selected": {
            backgroundColor: "transparent",
            color: "#6200EA",
            fontWeight: "700",
            textDecoration:"none",
        }, 
    },
    "& .MuiPaginationItem-ellipsis": {
        textDecoration: "none",
     },
})
const webStyles = {
    mainWrapper: {
      display:"flex",
      overFlow:"hidden"
    },
    mainBlock: {
        display: 'flex',
        backgroundColor: '#f6f6f6',
        flexDirection: 'row',
    },
    footerBlock: {
        display: 'flex',
        padding: '24px 52px 24px 52px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        color: '#fff',
        backgroundColor: '#1C1C1C',
    },
    leftFooter: {
        gap: '24px',
        display: 'flex',
        flexGrow: 1
    },
}
// Customizable Area End
