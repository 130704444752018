import React from 'react';

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  styled,
  TextField,
  Divider,
  Grid,
  InputBase,
  Dialog,
  
} from '@mui/material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import { KeyboardArrowRight } from '@mui/icons-material';
// Customizable Area End

import ClientProfileController, {
  Props,
} from './ClientProfileController';
import { InfoIcon, uploadImagelarge, uploadImageMedium, uploadImageSmall } from './assets';


export default class ClientProfile extends ClientProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { logoLarge, logoMedium, logoSmall} = this.state;
    return (
      <>
            <Box sx={webStyles.mainWrapper1}>
                <Box sx={webStyles.mainBlock1}>
                    <Box>
                        <Sidebar current='clients' navigation={this.props.navigation}/>
                    </Box>
                            <Box sx={webStyles.rightSection}>
                                <Box sx={{ display: 'flex',  alignItems: 'center', columnGap: '10px', justifyContent: 'space-between', padding: '1rem 2rem',}}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography onClick={this.NavigationClient} data-test-id='nav-client' sx={{ fontSize: '24px', cursor: "pointer" }}>Clients</Typography>
                                        <KeyboardArrowRight />
                                        <Typography sx={{ fontSize: '24px', fontWeight: 'bolder' }}>{this.state.clientName} Profile</Typography>
                                    </div>
                                    <BlackButton data-test-id='save-btn' onClick={this.handleSave}>Save</BlackButton>
                                </Box>

                                <Box sx={{ width: '80%', padding: '1rem 2rem' }}>

                                    <div>
                                        <Typography style={{color:'#64748B',fontWeight:'700'}}>COMPANY LOGO</Typography>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                                    <div>
                                    <Box sx={styles.logoContainer}>
                        {[
                          { size: 'large', resolution: '500px x 500px', logo: logoLarge, inputRef: this.fileInputLarge,style:{width:'310px',height:'310px'} },
                          { size: 'medium', resolution: '250px x 250px', logo: logoMedium, inputRef: this.fileInputMedium , style:{width:'250px',height:'250px'}},
                          { size: 'small', resolution: '100px x 100px', logo: logoSmall, inputRef: this.fileInputSmall, style:{width:'100px',height:'100px'} },
                        ].map(({ size, resolution, logo, inputRef,style }) => (
                          <Box key={size} sx={styles.logoWrapper[size as 'large' | 'medium' | 'small']}>
                            <input
                              type="file"
                              accept=".png, .jpg, .jpeg"
                              style={{ display: "none" }}
                              ref={this.assignRef(size as "large" | "medium" | "small")}
                              onChange={this.handleFileChange(size as "large" | "medium" | "small")}
                            />
                            {logo ? (
                              <Box sx={styles.uploadedLogo}>
                                <Box sx={{...styles.logo,...style}}><img src={logo} style={{
                                    width: '100%',  
                                    height: '100%', 
                                    borderRadius: '8px', 
                                    objectFit: 'contain', 
                                    }} alt={`Company Logo ${size}`} />
                                </Box>
                                <Box sx={styles.buttonWrapper}>
                                  <Box sx={styles.reuploadBtn} onClick={this.handleReuploadClick(inputRef as HTMLInputElement | null | null)}>Reupload</Box>
                                  <Box
                                    sx={styles.deleteBtn}
                                    data-test-id="delete-btn"
                                    onClick={this.handleDeleteLogo(size as 'large' | 'medium' | 'small')}
                                  >
                                    Delete
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              <Box>
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  data-test-id="drag-and-drop"
                                  onDragOver={this.handleDragOver}
                                  onDrop={this.handleDropEvent(size as 'large' | 'medium' | 'small')}
                                  sx={styles.logoPlaceholder}
                                  
                                >
                                <Box sx={styles.dummyPhoto}>
                                <img
                                    src={
                                      size === 'large'
                                        ? uploadImagelarge
                                        : size === 'medium'
                                        ? uploadImageMedium
                                        : uploadImageSmall
                                    }
                                    alt={`Dummy ${size} logo`}
                                    
                                  />         
                                  </Box>           
                                </Box>
                                <Box sx={styles.uploadDetails}>
                                <Button 
                                  onClick={this.handleFileUpload(inputRef as HTMLInputElement | null)}
                                  sx={{
                                    width: '142px',
                                    height: '46px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    fontWeight:'700',
                                    color: 'black',
                                    fontFamily: "Heebo-Bold",
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                      boxShadow: 'none',
                                    }
                                }}>
                                  Upload
                                </Button>
                                <Box
                                  sx={styles.infoIcon}
                                  onClick={this.handleInfo(resolution)}
                                >
                                  <img src={InfoIcon} style={{width:'20px', height:'20px'}} alt='information'/>
                                </Box>
                              </Box>
                            </Box>
                            )}
                          </Box>
                        ))}
                      </Box>
                    {this.state.touched.companyLogoLarge && this.state.errors.companyLogoLarge && (
                      <div className="text-danger">{this.state.errors.companyLogoLarge}</div>
                    )}
                    {this.state.touched.companyLogoMedium && this.state.errors.companyLogoMedium && (
                      <div className="text-danger">{this.state.errors.companyLogoMedium}</div>
                    )}
                    {this.state.touched.companyLogoSmall && this.state.errors.companyLogoSmall && (
                      <div className="text-danger">{this.state.errors.companyLogoSmall}</div>
                    )}
                    {this.state.selectedResolution && (
                  <Box
                    sx={{
                      marginLeft:'25%',
                      backgroundColor: '#F0F0F0',
                      borderRadius: '10px',
                      width:'453px',
                      height:'38px',
                    }}
                  >
                    <Typography variant="h6" sx={{ alignItems:'center', justifyContent:'center',marginBottom: '10px',fontSize:'14px', fontWeight:'400', padding:'8px 8px 8px 8px'  }}>
                      Please upload ({this.state.selectedResolution}) for optimal resolution.
                    </Typography>
                  </Box>
                )}
                                    </div>

                                        </div>
                                    </div>

                                </Box>
                                <Divider />
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        columnGap: '8rem',
                                        margin: '1rem 0',
                                        padding: '0 2rem',
                                        width: '70%'
                                    }}
                                >
                                    <Typography style={{color:'#64748B',fontWeight:'700'}}>COMPANY DETAILS</Typography>
                            <div style={{ width: '60%' }}>
                                <TextWrapper>
                                    <TextHeading>Company name</TextHeading>
                                    <TextField
                                        data-test-id='c-name'
                                        onChange={(event) => this.handleChange(event, 'company-name')}
                                        value={this.state.companyName}
                                        fullWidth
                                        sx={{
                                            borderRadius:'10px',
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                                '& input': {
                                                    padding: '12px',
                                                },
                                            },
                                        }}
                                    />
                                    {this.state.companyNameError && (
                                        <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                            Company name is required
                                        </p>
                                    )}
                                </TextWrapper>

                                <TextWrapper>
                                    <TextHeading>Contact name</TextHeading>
                                    <TextField
                                        data-test-id='contact-name'
                                        onChange={(event) => this.handleChange(event, 'contact-name')}
                                        value={this.state.contactName}
                                        fullWidth
                                        sx={{
                                            borderRadius:'10px',
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                                '& input': {
                                                    padding: '12px',
                                                },
                                            },
                                        }}
                                    />
                                    {this.state.contactNameError && (
                                        <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                            Contact name is required
                                        </p>
                                    )}
                                </TextWrapper>

                                <TextWrapper>
                                    <TextHeading>Contact job title</TextHeading>
                                    <TextField
                                        data-test-id='job-name'
                                        onChange={(event) => this.handleChange(event, 'contact-job-title')}
                                        value={this.state.contactJobTitle}
                                        fullWidth
                                        sx={{
                                            borderRadius:'10px',
                                            backgroundColor: 'white',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '10px',
                                                '& input': {
                                                    padding: '12px',
                                                },
                                            },
                                        }}
                                    />
                                    {this.state.contactJobTitleError && (
                                        <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                            Job title is required
                                        </p>
                                    )}
                                </TextWrapper>

                                        <TextWrapper>
                                            <TextHeading >Contact email</TextHeading>
                                            <TextField
                                                data-test-id='email'
                                                onChange={this.handleEmailChange}
                                                value={this.state.contactEmail}
                                                fullWidth
                                                sx={{
                                                    borderRadius:'10px',
                                                    backgroundColor: 'white',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '10px',
                                                        '& input': {
                                                            padding: '12px',
                                                        },
                                                    },
                                                }}
                                            />
                                            {this.state.emailError && (
                                                <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                                    Please enter a valid email address
                                                </p>
                                            )}
                                        </TextWrapper>
                                        <TextWrapper>
                                    <TextWrapper>
                                        <TextHeading>Contact phone</TextHeading>
                                        <TextField
                                            data-test-id='phone'
                                            onChange={this.handlePhoneChange}
                                            value={this.state.contactNumber}
                                            fullWidth
                                            sx={{
                                                borderRadius:'10px',
                                                backgroundColor: 'white',
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '10px',
                                                    '& input': {
                                                        padding: '12px',
                                                    },
                                                },
                                            }}
                                        />
                                        {this.state.contactNumberError && (
                                            <p style={{ color: 'red', marginTop: '4px', fontSize: '0.875rem' }}>
                                                Phone number must be exactly 12 digits
                                            </p>
                                        )}
                                    </TextWrapper>

                                        </TextWrapper>
                                    </div>

                                </div>
                            </Box>
                </Box>

                <Box sx={webStyles.footerBlock}>
                    <Box sx={webStyles.leftFooter}>
                        <Box>Terms and Conditions</Box>
                        <Box>Pravicy Policy</Box>
                        <Box></Box>
                    </Box>
                    <Box>
                        <Box>� 2024 All Rights Reserved</Box>
                    </Box>
                </Box>
            </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ProfileOption = styled(Typography)({
    cursor: "pointer",
    textDecoration:'underline',
    fontFamily:'Heebo',
    fontWeight:'700'
})
const TextWrapper = styled('div')({
    marginBottom: '1rem',
})
const TextHeading = styled(Typography)({
    marginBottom:'5px',
    fontWeight:"bold",
    fontFamily:'Heebo',
    color:'#334155',
    size:'14px',
  })
const BlackButton = styled(Button)({
    textTransform: 'none',
    color: 'white',
    fontFamily: 'Heebo',
    backgroundColor: 'black',
    fontWeight: 400,
    height: 'fit-content',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  })
const webStyles = {
    error: {
        color: 'red',
        marginTop: '4px',
        fontSize: '0.875rem' 
    },
    mainWrapper1: {
        backgroundColor: '#f6f6f6',
        flex: 1,
        flexDirection: 'col',

    },
    mainBlock1: {
        display: 'flex',
        width:'100%',
        height: "calc(100vh - 72px)",
        flexDirection: 'row',
        backgroundColor: '#f6f6f6',
    },
    rightSection: {
        width: "100%",
        flexDirection: "column",
        display: "flex",
        overflow: "auto",
    },
    footerBlock: {
        display: 'flex',
        padding: '24px 52px 24px 52px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        color: '#fff',
        backgroundColor: '#1C1C1C',
    },
    leftFooter: {
        gap: '24px',
        display: 'flex',
        flexGrow: 1
    },
};
const styles = {
    labelTitle: {
      fontSize: '14px',
      color: '#64748B',
      paddingBottom: '1px',
      fontFamily: 'Heebo-SemiBold',
    },
    logoContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    logoWrapper: {
      large: {
        gap: '3px',
        marginTop: '15px',
        padding: '20px 15px',
        cursor: 'pointer',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
      medium: {
        gap: '3px',
        marginTop: '15px',
        padding: '20px 10px',
        cursor: 'pointer',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
      small: {
        gap: '3px',
        marginTop: '15px',
        padding: '15px 7px',
        cursor: 'pointer',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    uploadedLogo: {
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '17px 15px 20px',
      borderRadius: '8px',
    },
    logo: {
      borderRadius: '4px',
      objectFit: 'cover',
    },
    buttonWrapper: {
      display: 'flex',
      marginTop: '17px',
      gap: '15px',
    },
    reuploadBtn: {
      cursor: 'pointer',
      fontSize: '14px',
      textTransform: 'none',
      fontFamily: 'Heebo-SemiBold',
      color: 'black',
      textDecoration: 'underline',
    },
    deleteBtn: {
      cursor: 'pointer',
      fontSize: '14px',
      textTransform: 'none',
      fontFamily: 'Heebo-SemiBold',
      color: 'red',
      textDecoration: 'underline',
    },
    logoPlaceholder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      height: '350px',
      cursor: 'pointer',
    },
    dummyPhoto: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '5px',
      marginTop: '10px',
    },
    uploadBtn: {
      width: '142px',
      height: '46px',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      fontWeight: '700',
      color: 'black',
      fontFamily: 'Heebo-Bold',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        boxShadow: 'none',
      },
    },
    infoIcon: {
      cursor: 'pointer',
      marginTop: '10px',
    },
    resolutionBox: {
      marginLeft: '25%',
      backgroundColor: '#F0F0F0',
      borderRadius: '10px',
      width: '453px',
      height: '38px',
    },
    resolutionText: {
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '10px',
      fontSize: '14px',
      fontWeight: '400',
      padding: '8px',
    },
  };
// Customizable Area End
