// Customizable Area Start
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const ExportIcon = require('../assets/ExportIcon.png').default
export const DeselectIcon = require('../assets/DeselectIcon.png').default
export const CourseThumbnail = require('../assets/CourseThumbnail.png').default
export const courseImg = require('../assets/courseImage.jpeg').default;
export const uploadImagelarge = require('../assets/uploadLarge.jpeg').default;
export const uploadImageMedium = require('../assets/uploadMedium.jpeg').default;
export const uploadImageSmall = require('../assets/uploadSmall.jpeg').default;
export const InfoIcon = require('../assets/infoIcon.jpeg').default;
// Customizable Area End