import React from "react";
// Customizable Area Start
import { Box, Button, Typography, styled,Grid,Pagination,Skeleton } from "@mui/material";
import Header from "../../../components/src/Header.web";
import { myLearningCourseImg,} from "./assets";
import HtmlReactParser from 'html-react-parser';
import DOMPurify from 'dompurify';
import {TimerOutlined,ArrowBackIosNew,ArrowForwardIos} from '@mui/icons-material'
// Customizable Area End
import DashboardController, {
  Props,
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  parsedString = (data: any) => {
    const cleanHtml = DOMPurify.sanitize(data);
    return HtmlReactParser(cleanHtml);
  }

  renderHTML = (data: any) => {
    const sanitizedDOM = DOMPurify.sanitize(data, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Wrapper>
      <Box>
        <Header
          navigation={this.props.navigation}
          islogin={true}
          isHeader={true}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', padding: '30px', justifyContent: 'space-between' }}>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between' ,width:'100%'}}
            >
              <Box width={"914px"} display={"flex"} flexDirection={"column"} gap={"16px"}>
                <Box display={"flex"} gap={"16px"}  sx={{backgroundColor:this.state.welcomeContent.heading_color ?? 'transparent'}}>
                  {this.state.welcomeContent.heading ? <Box>{this.parsedString(this.state.welcomeContent.heading)}</Box> :
                    <Typography
                      style={{ fontSize: "32px", fontWeight: '300', lineHeight: '47px', fontFamily: "Heebo-Light" }}
                    >
                      Welcome {" "}
                      <span style={{ fontWeight: '500', fontFamily: "Heebo-Regular", color: '#232323' }}>
                        {this.state.username},
                      </span>
                      {" "}from Builder.Ai
                    </Typography>}
                </Box>
                {this.state.welcomeContent?.context ? <Box sx={{backgroundColor:this.state.welcomeContent.welcome_context_color??'transparent'}}>{this.parsedString(this.state.welcomeContent.context)}</Box> : <Typography>
                  Here you can access courses designed to enhance your skills and knowledge.
                  Explore the wide range of learning paths we have curated just for you.
                </Typography>}
              </Box>
              <Box width={"290px"}>
               {this.state.welcomeContent.company_logo?.url ?<img height={100} width={100} src={this.state.welcomeContent.company_logo.url} alt="builder_logo" />: <img  src="/builderLogo.svg" alt="builder_logo" />}
                {this.state.welcomeContent?.company_logo_context ? <Box sx={{backgroundColor:this.state.welcomeContent.context_color}}><WrapperDiv>{this.parsedString(this.state.welcomeContent.company_logo_context)}</WrapperDiv></Box> :    <Typography style={{
                  color: '#4C4C4C', fontWeight: "400", fontSize: '16px', lineHeight: '23.5px',
                  fontFamily: "Heebo-Regular"
                }}
                  paddingTop="20px">
                  "This portal is brought to you in
                  collaboration with Builder.ai and Join
                  Talent, tailored for your learning needs."
                </Typography>}
              </Box>
            </Box>

            <Typography variant="h4" sx={{ fontWeight: '500', padding: '10px 0px' }}>Your Learning Paths</Typography>
            <Box
              display={"flex"} justifyContent={"space-between"} padding={"10px 0px 20px 0px"}
            >
              <Box  mr={'20px'} sx={{textWrap:'nowrap',width:'max-content', display: 'flex', gap: '16px', padding: '15px 0px', alignItems: 'center',overflow:'auto',scrollbarWidth:'thin' }} id="learning-path-buttons-container">
                <Button
                  sx={{
                    ...styles.commonButton,
                    ...(this.state.selectedLearningPathId === 0 ? styles.blackButton : {})
                  }}
                  data-test-id='all-courses-btn'
                  onClick={() => this.handleSelectLearningPath(0)}
                >
                  All Courses ({this.state.totalCourses})
                </Button>
                {this.state.allLearningPath?.map((item:any)=>{
                  return (<Button 
                    key={item.id}
                    onClick={()=>this.handleSelectLearningPath(item.id)}
                  data-test-id={`learning-path-btn-${item.id}`}
                  sx={{
                      ...styles.commonButton,
                    ...(this.state.selectedLearningPathId === item.id ? styles.blackButton : {})
                    }}>
                  {item.title} ({item.courses.length})
                </Button>)
                })}
              </Box>
              {this.state.totalCourses > 5 && (
              <Box display={'flex'} justifyContent={'space-between'} alignItems={"center"} sx={{ gap: "32px" }}>
                <Box sx={{
                  borderRadius: '100%',
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  height: '40px', width: '40px',cursor:'pointer'
                }}
                  display={'flex'} justifyContent={'center'} alignItems={"center"}
                  onClick={this.handleScroll('left')}
                >
                  <ArrowBackIosNew
                    style={{
                      borderRadius: '50%',
                      color: 'black',
                      backgroundColor: "#fff",
                      boxShadow: '0px 2px 20px 0px #00000014',
                      padding: '15px',
                      zIndex: 10,
                      cursor:'pointer'
                    }} />
                </Box>
                <Box sx={{
                  borderRadius: '50%',
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  height: '40px', width: '40px',cursor:'pointer'
                }}
                  display={'flex'} justifyContent={'center'} alignItems={"center"}
                  onClick={this.handleScroll('right')}
                >
                  <ArrowForwardIos
                    style={{
                      borderRadius: '50%',
                      color: 'black',
                      backgroundColor: "#fff",
                      boxShadow: '0px 2px 20px 0px #00000014',
                      padding: '15px',
                      cursor:'pointer'
                    }}
                  />
                </Box>
              </Box>
                )}
            </Box>


            <Grid container spacing={4} marginTop={-5} marginBottom={'5rem'}>
              {this.state.isLoadingCourses ? 
              [1,2,3,4,5].map(item=>(
                <Grid item xs={2.4} key={item}> 
                  <Skeleton variant="rectangular" height={260} style={{borderRadius:'5px'}} />
                </Grid>
              ))
              :
              this.state.allCourses?.map((course: any) => (
                <Grid item xs={2.4} key={course.id}> 
                  <Box
                    onClick={() => {
                      this.props.navigation.navigate('CourseDescription',{path:{courseId:course.id}})
                      localStorage.setItem("assign_course_id",course.assign_course_id)
                    }}
                    data-test-id={'course-' + course.id}
                    sx={styles.courseCard}>
                    <BoxThumbnail>
                      <img src={course.imageUrl || myLearningCourseImg} alt="ux_courses" />
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '10px',
                          height: '65px',
                          boxSizing: 'border-box'
                        }}
                      />
                      <Typography
                        style={{
                          display: 'flex',
                          color: '#fff',
                          padding: '10px',
                          fontSize: '14px',
                          alignItems: 'center'
                        }}
                      >
                        <TimerOutlined style={{ marginRight: '5px' }} />
                        {course.duration} Hours
                      </Typography>
                    </BoxThumbnail>
                    <Box sx={styles.courseCardDescription}>
                      <Box sx={{ fontSize: '16px' }}>{this.renderHTML(course.title)}</Box>
                      <Box sx={{ fontSize: '12px', color: '#171717' }}>
                        {course.lessonCount} lessons
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))
              }
            </Grid>

            {this.state.totalCourses > 5 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
              <Pagination
                color="primary"
                count={this.state.totalPages}
                disabled={this.state.totalPages<2}
                onChange={(e,page)=>this.handlePageChange(page)}
                data-test-id='course-pagination'
                sx={{
                  '& .MuiPaginationItem-root': {
                    textDecoration: 'none'
                  },
                  '& .MuiPaginationItem-ellipsis': {
                    textDecoration: 'unset ',
                  },
                  '& .Mui-selected, .Mui-selected:hover': {
                    color: 'black !important',
                    textDecoration: 'unset ',
                    border:'1px solid black',
                    borderRadius:'4px',
                    padding:'8px 16px',
                    backgroundColor: '#fff !important',
                  },
                  "& .Mui-disabled":{
                    color:"rgba(0,0,0,0.26) !important",
                    borderColor:"rgba(0,0,0,0.26)"
                  }
                }}
                page={this.state.currentPage}
                size="large"
              />
            </Box>
              )}
          </Box>
        </Header >
      </Box >
      </Wrapper>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper = styled(Box)(({ theme }) => ({
  ".ql-size-huge": {
    fontSize: "32px"
  },
  ".ql-size-large": {
    fontSize: '24px'
  },
  '.hide': {
    display: 'none'
  },
  "p": {
    margin: '0px',
  },
  ".popup_edit": {
    display: 'flex',
    flexDirection: 'row',
    background: '#FFFFFF',
    width:'270px',
    borderRadius:'4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  ".editor-container":{
      position:'absolute',
      zIndex:10,
      overflow:'hidden',
      background: '#FFFFFF',
      borderRadius:'4px',
      maxWidth:'700px',
      maxHeight:'600px',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  }
}));

const BoxThumbnail = styled(Box)({
  boxShadow: 'inset 0px -130px 50px -85px rgba(0,0,0,0.9)',
  boxSizing:'border-box',
  position:'relative',
  width: '100%',
  display:'flex',
  flexDirection:'column',
  justifyContent:'space-between',
      height:'160px',
  "&>img":{
      position: 'absolute',
      width:'100%',
      height:'100%',
      zIndex:'-1'
  }
})
const WrapperDiv = styled('div')({
  '& p:first-of-type': {
    wordBreak: 'break-word',
  },
});
const styles = {
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },

  pageContent: {
    display: 'flex',
    flexDirection: 'column'
  },

  contentHeader: {
    height: '92px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 48px'
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  commonButton:{
    width:'max-content', 
    display:'flex',
    minWidth:'unset',
  textWrap:'nowrap', 
  color: 'black', 
  backgroundColor: 'white', 
  textTransform: 'none'
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700'
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  userContent: {
    margin: '25px',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px'
  },
  greetingBlock: {
    width: '1069px',
    display: 'flex',
    flexDirection: 'row',
    gap: '130px',
    padding: '24px 16px'
  },
  adminGreeting: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    fontSize: '32px',
    background: '#F0F0F0',
    whiteSpace: 'nowrap'
  },
  providerBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '40px',
    width: '1069px',
    borderRadius: '4px'
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    maxWidth: '350px',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
    cursor: 'pointer' 
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow: {
    whiteSpace: 'nowrap'
  }
}
// Customizable Area End
