// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from '../../../components/src/ApiCall.web';
import { SelectChangeEvent } from '@mui/material';
import { createRef } from "react";
import { toast } from "react-toastify";

export interface IValue {
  companyLogo: string;
  companyName: string;
  contactName: string;
  jobTitle: string;
  email: string;
  contactPhone: string;
}
interface NewUserData {
  firstName: string,
  lastName: string,
  jobTitle: string,
  email: string,
  client: string,
}
// Customizable Area End
export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface IClientList {
  id: string;
  attributes: {
    company_name: string;
  }
}
export interface S {
  // Customizable Area Start
  openDialog: string;
  loading: boolean;
  selectedLearningPaths: any[];
  selectedPackages: any[];
  companyLogo: string;
  contactError: string;
  emailError: string;
  newUserData: NewUserData;
  addNewUserModal: boolean;
  sortBy:string;
  notificationModalOpen: boolean;
  selectedClientOption: string;
  isLoading: boolean;
  notifications: any[];
  clientList: IClientList[];
  dashboardData: {
    left: number;
    live: number;
    time: number;
    assign: number;
  }
  // Customizable Area End
}
export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}
export default class AdminDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createClientApiCallId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement>;
  addNewUserDoneApiCallId: string = "";
  clientDashboardApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    //Customizable Area Start
    this.state={
      openDialog: "",
      loading: false,
      selectedLearningPaths: [],
      selectedPackages: [],
      companyLogo: "",
      contactError: "",
      emailError: "",
      newUserData: {
        client: "",
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
      },
      addNewUserModal: false,
      sortBy:"Relevance",
      notificationModalOpen: false,
      selectedClientOption: "all",
      isLoading: false,
      notifications: [
          {
            id: 1,
            message: "A new user, John Doe, has registered as a student. Click to review their profile.",
            timestamp: "Thursday 3:12pm",
            isRead:'true'
          },
          {
            id: 2,
            message: "A new course, ‘Cybersecurity Essentials,’ is awaiting your approval. Click to review details.",
            timestamp: "Thursday 3:12pm",
            isRead:'true'
          },
          {
            id: 3,
            message: "The LMS will undergo maintenance on Saturday from 1 AM to 3 AM. Ensure backups are up-to-date.",
            timestamp: "Thursday 3:12pm",
            isRead:'false'
          },
          {
            id: 4,
            message: "A weekly activity report for ‘Data Science Basics’ has been submitted. Click to analyze.",
            timestamp: "Thursday 3:12pm",
            isRead:'false'
          },
          {
            id: 5,
            message: "A flagged comment in the ‘Web Development’ discussion forum requires your moderation.",
            timestamp: "Thursday 3:12pm",
            isRead:'true',
          },
          {
            id: 6,
            message: "Instructor John Smith has submitted feedback regarding the assessment process. Click to review.",
            timestamp: "Thursday 3:12pm",
            isRead:'true',
          },
          {
            id: 7,
            message: "Instructor John Smith has submitted feedback regarding the assessment process. Click to review.",
            timestamp: "Thursday 3:12pm",
            isRead:'false'
          },
          {
            id: 8,
            message: "Your announcement to all enrolled students in ‘Advanced Marketing’ has been successfully delivered.",
            timestamp: "Thursday 3:12pm",
            isRead:'true'
          },
        ],
        clientList: [],
        dashboardData: {
          left: 0,
          live: 0,
          time: 0,
          assign: 0
        }
      };
      this.fileInputRef = createRef<HTMLInputElement>();

      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.createClientApiCallId) {
        this.handleClientResponse(responseJson);
      } else if (apiRequestCallId === this.addNewUserDoneApiCallId) {
        this.handleUserResponse(responseJson);
      } else if (apiRequestCallId === this.clientDashboardApiCallId) {
        this.setState({
          dashboardData: {
            left: responseJson.courses_left_to_complete ?? 0,
            live: responseJson.live_users ?? 0,
            time: responseJson.total_learning_time ?? 0,
            assign: responseJson.user_assigned_courses ?? 0,
          }
        });
        if (responseJson.clients && responseJson.clients.data && Array.isArray(responseJson.clients.data)) {
          this.setState({ clientList: responseJson.clients.data });
        }
        this.setState({ isLoading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleClientResponse = (responseJson: { errors: string | string[]; data: any; }) => {
    if (responseJson.errors && Array.isArray(responseJson.errors)) {
      if (responseJson.errors.includes("Contact email has already been taken")) {
        this.setState({ emailError: "*Contact email has already been taken" });
      }
      if (responseJson.errors.includes("Contact phone has already been taken")) {
        this.setState({ contactError: "*Contact phone has already been taken" });
      }
      if (responseJson.errors.includes("Contact phone must be a valid phone number with 11 to 13 digits")) {
        this.setState({ contactError: "*Contact phone must be a valid phone number with 11 to 13 digits" });
      }
      if (responseJson.errors.includes("Contact phone Invalid or unrecognized phone number")) {
        this.setState({ contactError: "*Contact phone Invalid or unrecognized phone number" });
      }
    } else if (responseJson.data) {
      toast.success("Client created successfully!");
      const clientList = [responseJson.data, ...this.state.clientList];
      this.setState({clientList, openDialog: "", selectedLearningPaths: [], selectedPackages: [] });
    }
    this.setState({ loading: false });
  }

  handleUserResponse = (responseJson: { errors: { account: string[]; }[]; message: string | null | undefined }) => {
    if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0].account) {
      this.setState({ emailError: responseJson.errors[0].account[0] });
    } else {
      toast.success(responseJson.message);
      this.setState({
        addNewUserModal: false,
        emailError: "",
        newUserData: {
          client: "",
          firstName: "",
          lastName: "",
          jobTitle: "",
          email: "",
        }
      });
    }
    this.setState({ loading: false });
  }

  async componentDidMount() {
    this.getClientDashboardInfo();
  }

  createClient = async (values: IValue) => {
    this.setState({ loading: true });
    this.createClientApiCallId = await apiCall({
      method: "POST",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_account_groups/clients`,
      body: {
        "client": {
          "company_logo": [this.state.companyLogo],
          "company_name": values.companyName,
          "contact_name": values.contactName,
          "contact_job_title": values.jobTitle,
          "contact_email": values.email,
          "contact_phone": values.contactPhone
        }
      }
    });
  }
  handleLogo = (file: Blob | undefined) => {
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];
    if (!file) return;
    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          this.setState({ companyLogo: reader.result as string });
        }
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  }
  deleteLogo = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
    this.setState({ companyLogo: "" });
  }
  handleDragOverLogo = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
  };
  handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const file = event.dataTransfer.files[0];
      this.handleLogo(file);
    }
  }
  handleNewClick = () => {
    this.setState({ openDialog: "create" });
  }
  handleNewUserClick = () => {
    this.setState({ openDialog: "create" });
  }
  handleAuditTrailClick = () => {
    this.props.navigation.navigate('AuditTrail')
  };
  handleAnalyticsClick = () => {
    this.props.navigation.navigate('UserAnalytics')
  }
  handleNotificationClick = () => {
    this.props.navigation.navigate('Notifications')
  };
  handleEventManagementClick = () => {
    this.props.navigation.navigate('EventManagement')
  };
  handleNewCourseClick = () => {
    this.props.navigation.navigate('CourseCreation')
  };
  onNameChange = () => {
    this.setState({ emailError: "" });
  }

  onPhoneChange = () => {
    this.setState({ contactError: "" });
  }
  onCloseDialog = () => {
    this.setState({ openDialog: "", selectedLearningPaths: [], selectedPackages: [] });
  }

  handleAddNewUserModalClose = () => {
    this.setState({
      addNewUserModal: false,
      emailError: "",
      newUserData: {
        client: "",
        firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      }
    })
  }
  handleAddNewUserModalOpen = () => {
    this.setState({
      addNewUserModal: true,
    })
  }
  handleAddNewUserDoneClick = async () => {
    this.setState({ loading: true });
    const { newUserData } = this.state;
    const dataToSubmit = {
      data: {
        first_name: newUserData.firstName,
        last_name: newUserData.lastName,
        email: newUserData.email,
        job_title: newUserData.jobTitle,
        client_id: newUserData.client,
      },
    };

    this.addNewUserDoneApiCallId = await apiCall({
      method: "POST",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `bx_block_admin/user_managements`,
      body: dataToSubmit,
    });
  };
  handleUserDataChange = (field: keyof NewUserData, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    this.setState((prevState) => ({
      newUserData: {
        ...prevState.newUserData,
        [field]: event.target.value,
      },
    }));
    if(field === "email"){
      this.setState({ emailError: "" });
    }
  };
  handleSortByChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const sortBy = event.target.value as string;
    this.setState({ sortBy }, () => {
    });
  }
  handleNotificationModalOpen = () => { 
    this.setState({ notificationModalOpen: true }); 
  }; 
  handleNotificationModalClose = () => { 
    this.setState({ notificationModalOpen: false }); 
  }; 
  handleMarkAllAsRead = () => { 
    console.log("All messages are set to read"); 
  };

  getClientDashboardInfo = async (id?: string) => {
    this.setState({ isLoading: true });
    let url = "/bx_block_dashboard/dashboards/dashboart_client_info";
    if (id) {
      this.setState({ selectedClientOption: id });
      url = id !== "all" ? `${url}?client_id=${id}` : url;
    }
    this.clientDashboardApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: url,
    })
  }
  // Customizable Area End
}