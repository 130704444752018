import React from "react";
// ...

// Customizable Area Start
import { ThemeProvider, styled, createTheme } from "@mui/material/styles";
import { Box, Typography, Button, LinearProgress, MenuItem, Menu, IconButton, Divider, Paper, Checkbox,CircularProgress } from "@mui/material";
import { courseDescImg } from "./assets";
import {finalAssessmentIcon} from '../../assessmenttest/src/assets'
import { KeyboardArrowLeft, KeyboardArrowDown, ViewHeadline, RadioButtonUnchecked, CheckCircle,QuizOutlined } from '@mui/icons-material';

const theme = createTheme({
    palette: {
        primary: {
            main: "#000000",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
    },
});

interface LessonItemProps {
    title: string;
    checked: boolean;
    assessment:any;
    id:number
}
// Customizable Area End

import CourseDescriptionController, {
    Props,
} from "./CourseDescriptionController";

export default class CourseDescription extends CourseDescriptionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderLessonItem = ({ title, checked,...rest }: LessonItemProps) => {
        return (
            <Box display='flex' flexDirection='column' gap={'16px'} key={rest.id}>
                <Box display="flex" justifyContent={"space-between"} textAlign={"left"} gap="24px">
                    <Box display={"flex"} gap={"10px"} >
                        <ViewHeadline />
                        <Typography
                            style={{ fontFamily: 'Heebo', fontWeight: "500", fontSize: "18px" }}
                        >{title}</Typography>
                    </Box>
                    <Checkbox
                        data-testid="custom-checkbox"
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={Number(this.state.courseProcessDetail?.process) === 100}
                        sx={{
                            padding: 0,
                            height: '26px',
                            width: '26px',
                            pointerEvents:'none',
                            color: checked ? 'black' : 'grey.500',
                        }}
                    />
                </Box>
                {rest.assessment && 
                <Box display="flex" justifyContent={"space-between"} textAlign={"left"} gap="24px">
                    <Box display={"flex"} gap={"10px"} >
                        <QuizOutlined style={{ width: '24px', height: '24px' }} />
                        <Typography
                            style={{ fontFamily: 'Heebo', fontSize: "18px" , fontWeight: "500"}}
                        >{rest.assessment.title}</Typography>
                    </Box>
                    <Checkbox
                        data-testid="custom-checkbox1"
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={Number(this.state.courseProcessDetail?.process) === 100}
                        sx={{
                            padding: 0,
                            height: '26px',
                            color: checked ? 'black' : 'grey.500',
                            width: '26px',
                            pointerEvents:'none',
                        }}
                    />
                </Box>}
            </Box>
        );
    }
    // Customizable Area End

    render() {
        const requirementsArray = this.state?.courseDetail?.attributes?.course_requirements?.split('\r\n');
       
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {!this.state.courseDetail?
                 <Box sx={{ width: '100%', height: '100vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                 <CircularProgress
                   style={{
                     height: '100px',
                     width: '100px',
                     boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                     borderRadius: '50%'
                   }}
                   sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
                 />
               </Box>
                :
                <Box className="main_cont">
                    <Box display={"flex"} padding={"10px 16px"} justifyContent={"space-between"}>
                        <Box display={"flex"} alignItems={"center"}>
                                <Box display={"flex"} gap={"10px"} justifyContent={"space-between"}>
                                    <IconButton data-testID="myLearning" onClick={this.myLearning} sx={{borderRadius:'8px'}}>
                                        <KeyboardArrowLeft style={{ color: 'black' }} /> <Typography sx={{ color: 'black' }}>
                                            My learning
                                        </Typography>
                                    </IconButton>
                                </Box>
                           
                        </Box>
                        <Box>
                            <Box>
                                <Box display="flex" alignItems="center">
                                <img src="/builderLogo.svg" style={{
                                    height: "18px",
                                    marginRight: "15px"
                                }} />
                                <Button
                                    style={{ textTransform: 'none' }}
                                    data-testID="user"
                                    onClick={(event) => {
                                        this.setState({ anchorEl: event.currentTarget });
                                    }}
                                >
                                    <p>{localStorage.getItem("u")}</p>
                                    <KeyboardArrowDown />
                                </Button>
                                <CustomMenu
                                    disableScrollLock={true}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorEl={this.state.anchorEl}
                                    open={Boolean(this.state.anchorEl)}
                                    onClose={this.handleClose}
                                    slotProps={{
                                        paper: {
                                            elevation: 0,
                                            sx: {
                                                padding: '0',
                                                minWidth: '253px',
                                                borderRadius: "8px",
                                                border: "1px solid #F0F0F0",
                                                boxShadow: "0px 8px 32px 0px #0000000F",
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem>Settings</MenuItem>
                                    <MenuItem data-testID="logout" sx={{ color: "#DC2626" }} onClick={this.onLogout}>
                                        Log out
                                    </MenuItem>
                                </CustomMenu>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        height: '475px',
                        overflow: 'hidden',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        textAlign: 'left',
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backgroundImage: `url(${this.state?.courseDetail?.attributes?.course_description_image?.url || courseDescImg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            zIndex: 1,
                        }}
                    />
                    <Box
                        sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            backdropFilter: 'blur(5px)',
                            zIndex: 2,
                        }}
                    />
                    <Box
                        sx={{
                            position: 'relative',
                            zIndex: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            textAlign: 'left',
                            gap: 2,
                            width:'100%',
                            boxSizing:'border-box',
                            padding:'0 10%'
                        }}
                    >
                            {
                                <p dangerouslySetInnerHTML={{ __html: this.state?.courseDetail?.attributes?.title }} />
                            }
                        <Typography
                            style={{ fontFamily: 'Heebo', fontWeight: '400', fontSize: '16px', color: '#1C1C1C' }}
                        >
                            by {this.state.courseDetail?.attributes?.author_name}
                        </Typography>
                        <Box>
                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#000', color: '#fff', marginTop: '20px', width: '250px', borderRadius: '4px', textTransform: 'none',
                                    padding: '10px 16px', fontFamily: 'Heebo', fontSize: '16px', fontWeight: '700', lineHeight: '24px'
                                }}
                                onClick={this.handleResumeCourse}
                                data-testID='resume-btn'
                            >
                                {this.getButtonName()}
                            </Button>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography sx={{ mb: 1 }}>
                                {this.state.courseProcessDetail?.process}% Completed
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={this.state.courseProcessDetail?.process}
                                sx={{
                                    height: '10px',
                                    backgroundColor: 'white',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: 'black',
                                    },
                                }} />
                        </Box>
                    </Box>
                </Box>

                <Box display={"flex"} justifyContent={"space-evenly"} padding={"30px 10%"} >
                    <Box display={"flex"} width={"100%"} marginRight={'40px'}>
                        <Box sx={{ maxWidth: '1070px' }}  width='100%' display={"flex"} flexDirection={"column"} gap={"40px"}>
                            <Box dangerouslySetInnerHTML={{ __html: this.state.courseDetail.attributes.description }} />
                            <Box display="flex" flexDirection="column" gap="16px">
                            {this.state?.courseDetail?.attributes?.course_modules?.map((item:any,index:number)=>{
                                return (
                                    this.renderLessonItem(item)
                                // <LessonItem key={item.id} title={item.title} checked={this.checkCompletedPercentage(index)} {...item}/>
                                )
                            })}
                            {this.state?.courseDetail?.attributes?.final_assessment &&
                                <Box display="flex" justifyContent={"space-between"} textAlign={"left"} gap="24px">
                                    <Box display={"flex"} gap={"10px"} >
                                        <img src={finalAssessmentIcon} alt="finalAssessment" style={{ width: '24px', height: '24px' }} />
                                        <Typography
                                            style={{ fontFamily: 'Heebo', fontWeight: "500", fontSize: "18px" }}
                                        >{this.state?.courseDetail?.attributes?.final_assessment.title}</Typography>
                                    </Box>
                                    <Checkbox
                                        icon={<RadioButtonUnchecked />}
                                        checkedIcon={<CheckCircle />}
                                        checked={Number(this.state.courseProcessDetail?.process) === 100}
                                        sx={{
                                            padding: 0,
                                            height: '26px',
                                            color: this.state.courseProcessDetail?.process === 100 ? 'black' : 'grey.500',
                                            width: '26px',
                                            pointerEvents: 'none',
                                        }}
                                    />
                                </Box>
                            }
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Paper elevation={2} style={{ borderRadius: '4px' }}>
                            <Box padding="24px" display="flex" flexDirection="column" gap="40px">
                                <Box display="flex" gap="24px">
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        padding="16px"
                                        width="80px"
                                        height="70px"
                                        bgcolor="#f5f5f5"
                                        borderRadius="4px"
                                    >
                                        <Typography variant="h4" fontWeight="bold">{this.state?.courseDetail?.attributes?.course_modules?.length}</Typography>
                                        <Typography variant="subtitle1" color="textSecondary">{this.state?.courseDetail?.attributes?.course_modules?.length > 1 ? "Lessons" : "Lesson" }</Typography>
                                    </Box>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                        width="80px"
                                        padding="16px"
                                        bgcolor="#f5f5f5"
                                        height="70px"
                                        borderRadius="4px"
                                    >
                                        <Typography variant="h4" fontWeight="bold">{this.state?.courseDetail?.attributes?.duration}</Typography>
                                        <Typography variant="subtitle1" color="textSecondary">{this.state?.courseDetail?.attributes?.duration > 1 ? "Hours" : "Hour" }</Typography>
                                    </Box>
                                </Box>
                                <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
                                <Box>
                                    <Typography 
                                        style={{fontFamily:'Heebo',fontWeight:'500',fontSize:'14px',color:'#141615'}}
                                    >Course Requirements</Typography>
                                </Box>
                                <Divider />
                                <Box display="flex" flexDirection="column" gap="8px">
                                    {requirementsArray?.map((requirement:string, index:number) => (
                                        <Typography
                                        style={{fontFamily:'Heebo',fontWeight:'400',fontSize:'12px',color:'#6A6A6A'}}
                                        key={index}>
                                            <p dangerouslySetInnerHTML={{__html:requirement}}/>
                                        </Typography>
                                    ))}
                                </Box>
                                </Box>

                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Box>
                }
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    // custom style definitions
    descriptionText: {
        fontFamily: "Crimson Text",
        fontSize: "20px",
        fontWeight: "400",
        lineHeight: "32px",
        color: '#1C1C1C'
    }
};

const CustomMenu = styled(Menu)({
    "& .menu-item": {
        fontSize: "14px",
        margin: "0px 8px",
        borderRadius: "4px",
        fontFamily: "Heebo-Regular",
    },
});
// Customizable Area End
