import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../../packages/components/src/ApiCall.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  clientName: string,
  tableData: any,
  order: string,
  orderBy: string,
  clientsCount: number,
  usersCount: number,
  totalCoursesAssigned: number,
  totalCoursesCompleted: number,
  accreditationsAchieved: number,
  averageUserCompletionRate: number,
  totalLearningPathCompleted: number,
  totalLearningHours: number,
  page: number,
  clientList: any,
  selectedClients: any,
  currentPage: number,
  rowsPerPage: number,
  isLoading: boolean,
  anchorFilter:HTMLButtonElement | null
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientWideAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClientWideAnalyticsApiCallId = '';
  getClientListApiCallId = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: '0',
      clientName: '',
      tableData: [],
      order: 'asc',
      orderBy: 'clientName',
      clientsCount: 0,
      usersCount: 0,
      totalCoursesAssigned: 0,
      totalCoursesCompleted: 0,
      accreditationsAchieved: 0,
      averageUserCompletionRate: 0,
      totalLearningPathCompleted: 0,
      totalLearningHours: 0,
      page: 0,
      clientList: [],
      selectedClients: [],
      currentPage: 1,
      rowsPerPage: 10,
      isLoading: false,
      anchorFilter:null,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    
    await Promise.all([
      this.getClientWideAnalytics(),
      this.getAllClients()
    ]);
  }


  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getClientWideAnalyticsApiCallId) {
        this.receiveClientWideAnalyticsResponse(responseJson)
      }
      if (apiRequestCallId === this.getClientListApiCallId) {
        this.receiveAllClientResponse(responseJson)
      }

    }
  };
  handleSort = (property: any) => {
    const { order, orderBy, tableData } = this.state;

    const { isAsc, newOrder } = this.getSortOrder(orderBy, property, order);

    const sortedData = [...tableData].sort((a, b) => {
      const aValue = typeof a[property] === "string" ? a[property].toLowerCase() : a[property];
      const bValue = typeof b[property] === "string" ? b[property].toLowerCase() : b[property];

      if (aValue < bValue) return isAsc ? -1 : 1;
      if (aValue > bValue) return isAsc ? 1 : -1;
      return 0;
    });

    this.setState({
      order: newOrder,
      orderBy: property,
      tableData: sortedData,
    });
  };

  getSortOrder = (currentOrderBy: any, property: any, currentOrder: string) => {
    const isAsc = currentOrderBy === property && currentOrder === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    return { isAsc, newOrder };
  };
  
  
  handleCheckboxToggle = (clientId: number) => {
    console.log(clientId);
    this.setState(
      (prevState) => {
        const isSelected = prevState.selectedClients.includes(clientId);
        const updatedSelectedClients = isSelected
          ? prevState.selectedClients.filter((id: number) => id !== clientId)
          : [...prevState.selectedClients, clientId];

        return { selectedClients: updatedSelectedClients };
      },
      () => {
        this.getClientWideAnalytics();
        this.setState({ currentPage: 1 });
      }
    );
  };
  renderClientNames = (selected: any) => {
    if (!selected || this.state.selectedClients.length === 0) {
      console.log("inside all ")
      return 'All Clients';
    }

    return this.state.clientList
      .filter((client: any) => selected.includes(client.id))
      .map((client: any) => client.attributes.company_name)
      .join(', ');
  };

  getAllClients = async () => {
    this.getClientListApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/clients/client_list`,
    });
    this.setState({isLoading:true})
  }
  getClientWideAnalytics = async () => {
    let clientIdsParam = "";
    if (this.state.selectedClients.length > 0) {
      const clientIdsString = this.state.selectedClients.join(",");
      clientIdsParam = `?client_ids=${clientIdsString}`;
    }
    this.getClientWideAnalyticsApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_mixpanel_integration/client_analytics/user_counts${clientIdsParam}`,
    });
    this.setState({isLoading:true})
  }

  receiveAllClientResponse = (response: any) => {
    this.setState({
      clientList: response?.clients?.data
    })
  }
  
  receiveClientWideAnalyticsResponse = (responseJson: any) => {
    this.setState({
      isLoading: false,
      clientsCount: responseJson?.total_clients,
      usersCount: responseJson?.total_users_overall,
      totalCoursesAssigned: responseJson?.total_assigned_courses_overall,
      totalCoursesCompleted: responseJson?.total_completed_courses_overall,
      averageUserCompletionRate: responseJson?.total_completion_percentage,
      totalLearningHours: responseJson?.total_learning_hours,
    })

    const transformedData = responseJson?.client_user_counts.map((client: any) => ({
      clientName: client.client_name,
      users: client.user_count,
      courseAssigned: client.assigned_courses_count,
      coursesCompleted: client.completed_courses_count,
      accreditationAchieved: 0,
      avgCompletionRate: client.completion_percentage,
      totalLearningPathCompleted: 0,
      totalLearningHours: `${client.learning_hours} hours`
    }));
    this.setState({ tableData: transformedData || [] })
  }

  handlePageChange = (even: any, newPage: any) => {
    this.setState({
      currentPage: newPage,
    });
  }
  handleChangeTab = (tab:string)=>{
    this.props.navigation.navigate(tab)
  }
  // Customizable Area End
}
