const { baseURL } = require("../../framework/src/config.js");
interface ApiResponse<T> {
  success: boolean;
  message?: string;
  data?: T;
}
export const uploadFileToApi = async <T>(
    file: File,
    isImage:boolean = true
  ) => {
    try {
      // Check if the file is valid
      if (!file) {
        throw new Error("No file provided for upload.");
      }
  
      // Create a FormData object and append the file
      const formData = new FormData();
      isImage? formData.append("image", file):formData.append("video", file);
  
      // Make an API request to upload the file
      const response = await fetch(`${baseURL}/bx_block_videos2/videos/upload`, {
        method: "POST",
        body: formData,
      });
  
      // Handle non-200 HTTP responses
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API Error: ${errorData.message || "Unknown error"}`);
      }
  
      // Parse and return the JSON response
      const responseData: ApiResponse<T> = await response.json();
      return responseData;
    } catch (error) {
      // Handle errors and return a consistent failure response
      return error
    }
  };