// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ElementKey, KeyValue, MixpanelApiResponse } from "./Service";
import { apiCall } from "../../../components/src/ApiCall.web";

export const configJSON = require("./config");

export interface Props {
  // id: string;
  navigation: any;
}

export interface ICourse {
  id: number | string;
  duration: string | number | null;
  completed: number | string;
  title: string;
  number_of_lessons: number;
  user_process: number;
  total_course_modules: string | number;
  description: string;
  thumbnail_image: {
    url?: string | null;
  }
}

export interface ICourseData {
  id: string | number;
  duration: number;
  course_process: number;
  title: string;
  total_course_modules: string | number;
  description: string;
}
export interface ILearningPathCourse {
  course: ICourseData;
  images: {
    thumbnail?: string;
  };
}

interface S {
  loading: boolean;
  isLoadingData: boolean;
  isLoading: boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  chartValues?: object;
  countryData: number[];
  learningPathData: any[];
  accreditationData: any[];
  analyticsInfo: {
    "course_assigned": number,
    "complete_course": number,
    "left_course": number,
    "total_time": number,
    "avg_assign": number,
    "avg_time": number,
    "assign_modules": number
  };
}

interface SS {
  // id: any;
}

export default class MixpanelIntegrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCoursesApiCallId: string = "";
  getfetchListAPICalledId: string = "";
  apiGetAccessTokenCallId: string = "";
  getAnalyticsInfoApiCallId: string = "";
  getAccreditationInfoApiCallId: string = "";
  getLearningPathInfoApiCallId: string = "";

  constructor(props: Props) {

    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      loading: false,
      isLoadingData: false,
      isLoading: false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      countryData: [],
      learningPathData: [],
      accreditationData: [],
      analyticsInfo: {
        "course_assigned": 0,
        "complete_course": 0,
        "left_course": 0,
        "total_time": 0,
        "avg_assign": 0,
        "avg_time": 0,
        "assign_modules": 0
      },
      chartValues: {
        labels: ["1", "2", "3", "4", "5"],
        datasets: [
          {
            label: "Users Gained",
            data: [10, 20, 30, 40, 50],
          },
        ],
      },
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getallCourses = (): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiendpointData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  async componentDidMount() {
    this.getAnalyticsInfo();
    this.getLearningPathInfo();
    this.getAccreditationInfo();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getAnalyticsInfoApiCallId) {
        this.setState({ analyticsInfo: responseJson, loading: false });
      } else if (apiRequestCallId === this.getAccreditationInfoApiCallId) {
        if (responseJson.data) {
          this.setState({ accreditationData: responseJson.data });
        }
        this.setState({ isLoading: false });
      } else if (apiRequestCallId === this.getLearningPathInfoApiCallId) {
        if (responseJson.learning_paths) {
          this.setState({ learningPathData: responseJson.learning_paths });
        }
        this.setState({ isLoadingData: false });
      }
    }
  }

  async handleApiCallId(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getfetchListAPICalledId) {
        // TODO document why this block is empty
      }
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.apiGetAccessTokenCallId) {
        let CountryList = responseJson.country
        let FilteredCountry = []
        for (const element of CountryList) {
          FilteredCountry.push(Object.values(element)[0])
        }
        this.setState({ countryData: FilteredCountry as [] });
      }
      if (responseJson && !responseJson.errors && apiRequestCallId != null && apiRequestCallId === this.getCoursesApiCallId && responseJson !== undefined) {
        this.handleResponse(responseJson, apiRequestCallId)
      }
    }
  }

  handleResponse(responseJson: MixpanelApiResponse, apiRequestCallId: String) {
    let gettingArray: { city: string, accounts: number }[] = [];
    responseJson?.country?.map((keyValue: KeyValue) => {
      for (let keyOfValue in keyValue) {
        gettingArray.push({ city: keyOfValue, accounts: keyValue[keyOfValue] });
      }
    });
    const filteredArr: string = JSON.stringify(gettingArray?.filter(
      (keyOfFilter?: { city: string, accounts: number }) => {
        if (keyOfFilter === undefined) {
          return false;
        }
        return isNaN(parseInt(keyOfFilter.city));
      }))

    let value: object[] = JSON.parse(filteredArr)
    if (this.state.chartValues === undefined) {
      return this.state.chartValues;
    }
    else {
      this.setState({
        chartValues: {
          labels: value?.map((keyElement: ElementKey) => typeof (keyElement.city) === "string" ? keyElement.city : "not valid city name"),
          datasets: [
            {
              label: "user granied",
              data: value?.map((keyElement: ElementKey) => typeof (keyElement.accounts) === "number" ? keyElement.accounts : 0),
            }],
        }
      })
    }
  }

  doButtonPressed() {
    let eventMsg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    eventMsg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(eventMsg);
  }
  apiCall = async () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetAccessTokenCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        // "token": token,
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiendpointData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getAnalyticsInfo = async () => {
    this.setState({ loading: true });
    this.getAnalyticsInfoApiCallId = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      token: localStorage.getItem('token'),
      endPoint: `/bx_block_analytics/user_analytics`,
    });
  }

  getAccreditationInfo = async () => {
    this.setState({ isLoading: true });
    this.getAccreditationInfoApiCallId = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      token: localStorage.getItem('token'),
      endPoint: `/bx_block_profile/user_accreditations/process_accredatation`,
    });
  }

  getLearningPathInfo = async () => {
    this.setState({ isLoadingData: true });
    this.getLearningPathInfoApiCallId = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      token: localStorage.getItem('token'),
      endPoint: `/bx_block_analytics/user_analytics/learning_path`,
    });
  }
}
// Customizable Area End
