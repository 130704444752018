import CourseAnalyticsController, { Props } from "./CourseAnalyticsController.web";
import { 
  Box, 
  Button, 
  Typography, 
  Grid, 
  LinearProgress,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Paper,
  TableHead,
  TableBody,
  AppBar,
  Tabs,Tab,Modal,
  Popover,CircularProgress,Chip,
  Pagination } from "@mui/material";
import  { tableCellClasses } from '@mui/material/TableCell';
import {  ArrowDropDown,FilterList, ArrowForwardIos,KeyboardArrowDown,Close } from '@mui/icons-material';
import Chart from "react-google-charts";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import CustomSelect from '../../../../packages/components/src/CustomSelect.web'
import {
  styled,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import React from 'react'
import AdminFooter from "../../../../packages/components/src/AdminFooter.web";
import TableSkeleton from '../../../../packages/components/src/TableSkeleton.web'
import {ImageSorting} from './assets'
import dayjs from 'dayjs'

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: '#fff'
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
export default class CourseAnalytics extends CourseAnalyticsController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

    renderHeaderWeb=()=>{
      return (
<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
                <Box display={"flex"} gap="10px" alignItems={"center"}>
                  <Typography
                    style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '24px', lineHeight: '32px', color: '#1C1C1C' }}
                  >Analytics</Typography>
                  <>
                    <Typography
                      style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '16px', lineHeight: '24px', color: '#475569' }}
                    >Jul 30 - Aug 5</Typography>
                    <KeyboardArrowDown />
                  </>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
                  <Button
                    style={{ fontFamily: 'Heebo', fontWeight: '700', padding: '10px 16px', borderRadius: '4px', fontSize: '16px', lineHeight: '24px', backgroundColor: '#000', color: '#fff', textTransform: 'none' }}
                  >Export data</Button>
                </Box>
              </Box>
      )
    }
    renderSearchSection = () => {
      return (
        <Box display={"flex"} gap={"20px"} alignItems={"center"} height={"60px"}>
          <Box 
          display={'flex'} 
          alignItems={'center'}
          style={{
            width: '100%', height: '100%'
          }}>
            <Box px={'16px'} py={'10px'}
              borderRadius={'4px'}
              boxSizing={'border-box'}
              data-test-id={'search-box-course'}
              onClick={(e)=>{this.setState({anchorSearch:e.currentTarget})}}
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                border: '1px solid #D6D3D1'
              }}>
              All Course
              <ArrowDropDown data-test-id='expand-indicator' style={{ color: 'black' }} />
            </Box>
            <Popover
              open={Boolean(this.state.anchorSearch)}
              data-test-id={'search-popover'}
              anchorEl={this.state.anchorSearch}
              onClose={()=>{this.setState({anchorSearch:null})}}
              slotProps={{
                paper: {style:{ width: '100%',maxWidth:'70vw',height:'auto',overflow:'visible' }},
                
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box width={'100%'} height={'100%'} padding={'20px'} boxSizing={'border-box'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={'10px'}>
                <CustomSelect placeholder={'Clients'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                <CustomSelect placeholder={'Course Completion Status'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                <CustomSelect placeholder={'Learning Path'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                <CustomSelect placeholder={'Devices'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                </Box>
                <Box>
                  <Chip 
                  label='Clients' 
                  onDelete={()=>{}} 
                  deleteIcon={<Close style={{color:'black',width:'16px',height:'16px'}}/>} 
                  sx={{borderRadius:'4px',border:'1px solid #A8A29E',margin:'0 5px'}}/>
                </Box>
              </Box>
            </Popover>

          </Box>
          <Box height={"100%"}>
            <Button
              data-test-id="filter-box"
              style={{
                fontFamily: 'Heebo', height: '100%',
                display: 'flex', textAlign: 'center', gap: '10px',
                alignItems: 'center', fontWeight: '700',
                padding: '10px 16px', borderRadius: '4px',
                fontSize: '16px', lineHeight: '24px',
                backgroundColor: 'transparent', color: '#000',
                textTransform: 'none'
              }}
              onClick={(e)=>{this.setState({anchorFilter:e.currentTarget})}}
            >
              <Typography
                style={{ fontFamily: 'Heebo', fontWeight: '700', fontSize: '14px', color: '#000' }}
              >
                Filter
              </Typography>
              <FilterList />
            </Button>
          </Box>
          <Popover
              open={Boolean(this.state.anchorFilter)}
              data-test-id={'filter-popover'}
              anchorEl={this.state.anchorFilter}
              onClose={()=>{this.setState({anchorFilter:null})}}
              slotProps={{
                paper: {style:{ width: '100%',maxWidth:'40vw',height:'auto', overflow:'visible' }},
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box width={'100%'} height={'100%'} padding={'20px'} boxSizing={'border-box'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} >
                <CustomSelect placeholder={'Location'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                <CustomSelect placeholder={'Devices'} styles={{flex:'1',margin:'0 5px'}} onChange={()=>{}}/>
                </Box>
                {/* <Box>
                  <Chip 
                  label='Clients' 
                  onDelete={()=>{}} 
                  deleteIcon={<Close style={{color:'black',width:'16px',height:'16px'}}/>} 
                  sx={{borderRadius:'4px',border:'1px solid #A8A29E',margin:'0 5px'}}/>
                </Box> */}
              </Box>
            </Popover>
        </Box>
      )
    }
    renderPieChart = ()=>{
      const data = [
        ["Devices", "Number"],
        ["Desktop", 80,],
        ["Mobile", 45],
        ["Tablet", 45],
        ["Other", 30]
      ];
      const options = {
        pieHole: 0.7,
        is3D: false,
        pieSliceText: 'none',
        legend: "none",
        colors: ['#7B61FF', '#0099FF', '#F59E0B', '#9D9D9D'],
        
      };
      const renderLegend=()=>{
        return (
          <LegendBox display={'flex'} flexDirection={'column'} flex={1} justifyContent={'center'}>
              {data.slice(1).map(item=>{
                return (
                  <LegendLine>
                    <div>
                      <div className={`legend-icon ${item[0]}`}></div>
                      <p className="legend-title">{item[0]}</p>
                      </div>
                    <p className="legend-value">{item[1]}</p>
                  </LegendLine>
                )
              })}
          
          </LegendBox>
        )
      }
      return (
        <CardOverView sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Devices breakdown</Typography>
            <Typography style={webStyles.contentOverview}>200 Devices</Typography>
          </Box>
          <PieChartBox display={'flex'} flex={2} height={'100%'} overflow={'hidden'} >
            <Box flex={1}>
              <Chart chartType="PieChart" width="100%"  height={'100%'} data={data} options={options} />
            </Box>
            {renderLegend()}
          </PieChartBox>
        </CardOverView>
      )
    }
    renderAnalyticsOverview =()=>{
        const {overallStats} = this.state
        return(
          <Grid container spacing={2} sx={{marginTop:'8px'}}>
            <Grid item xs={8} >
              <Grid container spacing={2} style={{height:'300px'}} >
                <Grid item xs={6} style={{maxHeight:'150px'}}>
                  <CardOverView display={'flex'} flexDirection={'column'}>
                    <Typography style={webStyles.titleOverview}>Average Time to Complete a Course</Typography>
                    <Typography style={{...webStyles.contentOverview,color:'#F59E0B'}}>{overallStats.avg_time_to_complete}h</Typography>
                  </CardOverView>
                </Grid>
                <Grid item xs={6} style={{maxHeight:'150px'}}>
                  <CardOverView display={'flex'} flexDirection={'column'}>
                    <Typography style={webStyles.titleOverview}>Total Learning Time</Typography>
                    <Typography style={webStyles.contentOverview}>{overallStats.total_learning_hours}h</Typography>

                  </CardOverView>
                </Grid>
                <Grid item xs={6} style={{maxHeight:'150px'}}>
                  <CardOverView display={'flex'} flexDirection={'column'}>
                    <Typography style={webStyles.titleOverview}>Average Assessment Attempts</Typography>
                    <Typography style={{...webStyles.contentOverview,color:'#F59E0B'}}>{overallStats.average_attempts_per_course}</Typography>

                  </CardOverView>
                </Grid>
                <Grid item xs={6} style={{maxHeight:'150px'}}>
                  <CardOverView display={'flex'} flexDirection={'column'}>
                    <Typography style={webStyles.titleOverview}>Average Assessment Score</Typography>
                    <Typography style={{...webStyles.contentOverview,color:"#FC5555"}}>{overallStats.average_assessment_score_per_course}%</Typography>

                  </CardOverView>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{maxHeight:'300px'}}>
              {this.renderPieChart()}
            </Grid>
          </Grid>
        )
    }

    renderWorldMapChart = () => {
      const data = [
        ["Country", "User"],
       
      ];
      const userData = [
        {location:'SP, Madrid',value:27},
        {location:'UK, London',value:32},
        {location:'USA, Florida',value:46},
        {location:'NL, Amsterdam',value:64},
        {location:'USA, New York',value:120},
      ]
      return (
        <div style={{marginTop:'30px',boxSizing:'border-box',overflow:'hidden', marginBottom:'30px'}}>
          <CardOverView sx={{ maxHeight: '350px',borderRadius:'0' }}>
            <Typography style={webStyles.titleOverview}>Location</Typography>
            <Typography style={webStyles.contentOverview}>142 <span style={{fontSize:'16px'}}>Users</span></Typography>
            <Box display={'flex'} height={'100%'} maxHeight={'250px'} >
              <MapContainer style={{ flex: '1', overflow:'scroll' }}>
                <Chart
                  chartType="GeoChart"
                  width="100%"
                  height='100%'
                  data={data}
                  options={{ 
                    legend: 'none',
                    colorAxis: { colors: ["#BABABA", "#BFBFBF", "#B1B1B1",'#AAAAAA','#969696','#626262'] }, 
                    datalessRegionColor:'#BABABA' }}
                />
              </MapContainer>
              <Box flex={1} px={'20px'}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={'10px'} mb={'10px'}  borderBottom={'1px solid #F0F0F0'} sx={{color:'#6A6A6A'}}>
                  <Typography fontSize={12}>Top 5 users locations</Typography>
                  <Typography fontSize={12}>Users</Typography>
                </Box>
                {userData.map(item=>{
                  return (
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} key={item.location}>
                      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                        <Typography sx={{ mb: 1 }}>
                          {item.location}
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={item.value / 150 * 100}
                          sx={{
                            height: '5px',
                              borderRadius:'5px',
                              backgroundColor: 'white',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: 'black',
                              borderRadius:'5px'
                            },
                          }} />
                      </Box>
                      <Typography>{item.value}</Typography>
                    </Box>
                  )
                })}
                
              </Box>
            </Box>
          </CardOverView>
        </div>
      )
    }
    renderTableData = ()=>{
    
      return (
        <TableContainer component={Paper}>
      <Box overflow={'scroll'}>
        <Table sx={{ minWidth: 1060, borderRadius:'5px' }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Course</StyledTableCell>
              <StyledTableCell  style={{minWidth:'110px'}}>Users completed</StyledTableCell>
              <StyledTableCell  style={{minWidth:'110px'}}>Didn’t completed</StyledTableCell>
              <StyledTableCell  style={{minWidth:'110px'}}>Completion rate</StyledTableCell>
              <StyledTableCell style={{minWidth:'100px'}}>Active time</StyledTableCell>
              <StyledTableCell >Device</StyledTableCell>
              <StyledTableCell >Location</StyledTableCell>
              <StyledTableCell style={{minWidth:'130px'}} >Date started</StyledTableCell>
              <StyledTableCell ></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { this.state.isLoadingTable ?
              <TableSkeleton rowsNum={10} columnsNum={9}/>
              :
             this.state.courseList.map((row) => (
              <StyledTableRow key={row.course_id} data-test-id={`row-${row.course_id}`} onClick={()=>{
                this.handleGetCourseUserInfo(row.course_id,1)
                this.setState({currentCourseId:row.course_id,isOpenCourseUserInfoModal:true,currentPageUserInfo:1})
              }}>
                <StyledTableCell style={{minWidth:'150px'}}>
                <div style={webStyles.courseCell}> {this.sanitizeText(row.course_name)}</div>
                </StyledTableCell>
                <StyledTableCell >{row.completed_users_count}</StyledTableCell>
                <StyledTableCell >{row.not_completed_users_count}</StyledTableCell>
                <StyledTableCell >{row.completion_rate}%</StyledTableCell>
                <StyledTableCell >{row.total_learning_hours}</StyledTableCell>
                <StyledTableCell >{'Android'}</StyledTableCell>
                <StyledTableCell >{'US'}</StyledTableCell>
                <StyledTableCell >{row.date_started?dayjs(row.date_started).format("DD.MM.YYYY hh:mm"):"Haven't started"}</StyledTableCell>
                <StyledTableCell ><ArrowForwardIos style={{width:'18px',height:'18px',color:'#6A6A6A'}}/></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
            <Box style={{display:'flex',padding:'20px', justifyContent:'space-between',alignItems:'center',width:'100%',boxSizing:'border-box'}}>
               <Box>
               </Box>
               <Pagination
                  color="primary"
                  count={this.state.courseAnalyticsMeta.total_pages}
                  onChange={this.handleChangePage}
                  page={this.state.currentPage}
                  size="large"
              data-test-id="course-analytics-pagination"
              sx={{
                '& .MuiPaginationItem-root':{
                  textDecoration:'underline'
                },
                '& .MuiPaginationItem-ellipsis':{
                  textDecoration:'unset ',
                },
                '& .Mui-selected': {
                  backgroundColor: 'transparent',
                  color: '#6200EA',
                  textDecoration:'unset ',
                },
                '& .Mui-selected:hover': {
                  backgroundColor: 'transparent',
                  textDecoration:'unset ',
                  color: '#6200EA',
                },
              }}
              />
              <Typography>
                {`${(this.state.currentPage-1)*10 + 1} - ${(this.state.currentPage*10)>this.state.courseAnalyticsMeta.total_count?this.state.courseAnalyticsMeta.total_count:this.state.currentPage*10} of ${this.state.courseAnalyticsMeta.total_count} results`}
              </Typography>
        </Box>
        {this.renderCourseDetailTable()}

    </TableContainer>
      )
    }
    renderCourseDetailTable = () => {
      return (
        <Modal
        data-test-id={"course-detail-modal"}
          open={this.state.isOpenCourseUserInfoModal}
          onClose={()=>{
            this.setState({isOpenCourseUserInfoModal:false,isLoadingTableUserInfo:true})
          }}
        >
        <TableContainer component={Paper} sx={modalStyle}>
          <Box overflow={'scroll'}>
            <Table sx={{ minWidth: 1060, borderRadius: '5px' }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ minWidth: '130px' }}>
                  <div style={webStyles.cellHeader}>Users Name
                    <img src={ImageSorting} onClick={() => this.handleSortUserInfoTable("user_name")} alt={`sort-btn`} />
                  </div>
                    </StyledTableCell>
                  <StyledTableCell style={{ minWidth: '130px' }}>
                    <div style={webStyles.cellHeader}>Clients
                    <img src={ImageSorting} onClick={() => this.handleSortUserInfoTable("client_name")} alt={`sort-btn`} />
                  </div>
                    </StyledTableCell>
                  <StyledTableCell style={{ minWidth: '110px' }}>
                    <div style={webStyles.cellHeader}>Location
                    <img src={ImageSorting} onClick={() => this.handleSortUserInfoTable("location")} alt={`sort-btn`} />
                  </div>
                    </StyledTableCell>
                  <StyledTableCell style={{ minWidth: '90px' }}>
                    <div style={webStyles.cellHeader}>Completed
                    <img src={ImageSorting} onClick={() => this.handleSortUserInfoTable("user_process")} alt={`sort-btn`} />
                  </div>
                    </StyledTableCell>
                  <StyledTableCell style={{ minWidth: '90px' }}>
                    <div style={webStyles.cellHeader}>Total Learning Hours
                    <img src={ImageSorting} onClick={() => this.handleSortUserInfoTable("total_learning_hours")} alt={`sort-btn`} />
                  </div>
                    </StyledTableCell>
                  <StyledTableCell style={{ minWidth: '90px' }}>
                    <div style={webStyles.cellHeader}>Assessment Attempts
                    <img src={ImageSorting} onClick={() => this.handleSortUserInfoTable("assessment_attempts")} alt={`sort-btn`} />
                  </div>
                    </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.isLoadingTableUserInfo ?
                  <TableSkeleton rowsNum={10} columnsNum={6} />
                  :
                  this.state.courseUserInfo.user_data.map((row,index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell > {this.sanitizeText(row.user_name)} </StyledTableCell>
                      <StyledTableCell >{this.sanitizeText(row.client_name)}</StyledTableCell>
                      <StyledTableCell >{row.location??"Unknown"}</StyledTableCell>
                      <StyledTableCell >{row.user_process}%</StyledTableCell>
                      <StyledTableCell >{row.total_learning_hours} hours</StyledTableCell>
                      <StyledTableCell >{row.assessment_attempts}</StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          <Box style={{ display: 'flex', padding: '20px', justifyContent: 'space-between', alignItems: 'center', width: '100%', boxSizing: 'border-box' }}>
            <Box>
            </Box>
            <Pagination
             sx={{
              '& .MuiPaginationItem-root':{
                textDecoration:'underline'
              },
              '& .Mui-selected, & .Mui-selected:hover': {
                color: '#6200EA',
                textDecoration:'unset ',
                backgroundColor: 'transparent',
              },
              '& .MuiPaginationItem-ellipsis':{
                textDecoration:'unset ',
              },
            }}
              color="primary"
              count={this.state.courseUserInfo.meta.total_pages}
              onChange={this.handleChangePageUserInfo}
              page={this.state.currentPageUserInfo}
              size="large"
              data-test-id="table-user-pagination"
            />
            <Typography>
              {`${(this.state.currentPageUserInfo - 1) * 10 + 1} - ${(this.state.currentPageUserInfo * 10) > this.state.courseUserInfo.meta.total_count ? this.state.courseUserInfo.meta.total_count : this.state.currentPage * 10} of ${this.state.courseUserInfo.meta.total_count} results`}
            </Typography>
          </Box>
        </TableContainer>
        </Modal>
      )
    }

    tabConfigurations = [
      { label: 'User Analytics', value:'UserAnalytics'},
      { label: 'Client-Wide Analytics',value:'ClientWideAnalytics' },
      { label: 'Course Analytics',value:'CourseAnalytics'},
      { label: 'Feedback Aggregation',  value:"Analytics"},
      { label: 'Reports', value:'ReportsAnalytics' },
    ];
    
    render() {
      return (
        <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <Box display={"flex"} overflow={'hidden'}>
              <Box>
                <Sidebar navigation={this.props.navigation} current="analytics" />
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                width={"100%"}
                padding={"24px 48px"}
                gap={"16px"}
                sx={{ backgroundColor: '#F0F0F0', overflow: 'auto' }}>
                {this.renderHeaderWeb()}
                <Box width={"fit-content"}>
                  <AppBar position="static" style={{ width: "60vw", boxShadow: 'none', borderRadius: '4px',maxHeight:'60px',padding:'10px' }} color="secondary">
                    <Tabs
                      value="CourseAnalytics"
                      data-testID="Tabs"
                      aria-label="analytics tabs"
                      textColor="secondary"
                      style={{ gap: '10px', }}
                      TabIndicatorProps={{
                        style: {
                          display: 'none'
                        },
                      }}
                    >
                      {this.tabConfigurations.map((tab, index) => (
                        <Tab
                          key={index}
                          value={tab.value}
                          data-test-id={`tab-${index}`}
                          onClick={() => this.handleChangeTab(tab.value)}
                          label={
                            <Typography
                              sx={{
                                fontFamily: 'Heebo-bold',
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '18px',
                                padding: '12px 0',
                                textTransform: 'none',
                                width:'max-content',
                              }}
                            >
                              {tab.label}
                            </Typography>
                          }
                          sx={{
                            backgroundColor: tab.value === 'CourseAnalytics' ? 'black' : 'inherit',
                            color: tab.value === 'CourseAnalytics' ? 'white' : '#6A6A6A',
                            flex:1,
                            borderRadius: '4px',
                            boxSizing:'border-box',
                            maxHeight:'60px',
                            padding:'0 16px',
                            minHeight:0,
                            height:'100%',
                            '&.Mui-selected': {
                              backgroundColor: 'black',
                              color: 'white',
                              borderBottom: 'none',
                            },
                          }}
                        />
                      ))}
                    </Tabs>
                  </AppBar>
                </Box>
                <Box paddingTop={"20px"}>
                 {this.state.isLoadingPage?
                  <Box sx={{ width: '100%', height: '100vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <CircularProgress
                    style={{
                      height: '100px',
                      width: '100px',
                      boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                      borderRadius: '50%'
                    }}
                    sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
                  />
                </Box>
                 :
                 <Box width={"100%"} display={'flex'} flexDirection={'column'} boxSizing={'border-box'} >
                 {this.renderSearchSection()}
                 {this.renderAnalyticsOverview()}
                 {this.renderWorldMapChart()}
                 {this.renderTableData()}
               </Box>
                 }
                </Box>
              </Box>
            </Box>
          <AdminFooter/>
        </ThemeProvider>
      </StyledEngineProvider>
        
      )
    }
}  
export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  boxShadow: 24,
};

export const MapContainer = styled(Box)({
  '-ms-overflow-style': 'none',  
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar':{
      width:0,
      height:0
    }
}) as typeof Box

export const PieChartBox = styled(Box)({
  '@media(max-width:1280px)':{
    flexDirection:'column',
    alignItems:'center'
  }
}) as typeof Box
export const LegendBox = styled(Box)({
  '@media(max-width:1280px)':{
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-around',
    flexWrap:'wrap'
  }
}) as typeof Box
export const CardOverView = styled(Box)({
  boxSizing:'border-box',
  backgroundColor:'#fff',
  borderRadius:'8px',
  width:'100%',
  height:'100%',
  borderBottomRightRadius:'32px',
  padding:'24px 32px',
  justifyContent:'space-between',
  boxShadow: '0px 4px 8px 0px #00000008'

}) as typeof Box
export const LegendLine = styled('div')({
  display:'flex',
  alignItems:'center',
  justifyContent:'space-between',
  fontSize:'12px',
  margin:'0 10px',
  maxHeight:'24px',
  '&>div':{
    display:'flex',
    alignItems:'center',
    marginRight:'10px'
  },
  '& .legend-icon':{
    width:'10px',
    height:'10px',
    borderRadius:'10px',
    marginRight:'15px',
    backgroundColor:'black'
  },
  '& .Desktop':{
    backgroundColor:'#7B61FF'
  },
  '& .Mobile':{
    backgroundColor:'#0099FF'

  },
  '& .Tablet':{
    backgroundColor:'#F59E0B'

  },
  '& .Other':{
    backgroundColor:'#9D9D9D'

  },
  '& .legend-title':{
    fontSize:'12px',
    lineHeight:'16px',
    color:"#0F172A",
    fontFamily:'Heebo'
  },
  '& .legend-value':{
    margin:0,
    fontFamily:'Heebo-bold',
    fontWeight:700
  }
})   as typeof Box 

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize:'14px',
  lineHeight:'22px',
  fontFamily:'Heebo',
  alignItems:'center',
  [`&.${tableCellClasses.head}`]: {
    color: "#475569",
    fontFamily:'Heebo-bold',
    fontWeight:'700'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
})) as typeof TableCell;

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
})) as typeof TableRow;

export const webStyles={
  
  titleOverview:{
    fontSize:'14px',
    color:'#6A6A6A',
    fontFamily:'Heebo-bold'
  } as React.CSSProperties,
  contentOverview:{
    fontSize:'30px',
    lineHeight:'34px',
    fontFamily:"Heebo-bold",
    fontWeight:700,
    color:'#0F172A'
  } as React.CSSProperties,
  courseCell:{
    padding:'2px 8px',
    backgroundColor:'black',
    borderRadius:'40px',
    color:"#fff",
    textTransform:'uppercase',
    fontSize:'12px',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'fit-content',
    fontFamily:'Heebo-bold',
    textWrap:'nowrap'
  } as React.CSSProperties,
 
  cellHeader:{
    display:'flex',
    alignItems:'center',
    width:'100%'
  } as React.CSSProperties
}