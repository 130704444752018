import React from "react";

import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import {
  StyledEngineProvider,
  styled
} from '@mui/material/styles';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { courseImg, timer } from "../../catalogue/src/assets";

import {
  imgChevronRight,
  imgWave,
  img3Dots,
  imgEdit,
  imgPaintBacket,
  imgTrashBin,
} from '../../landingpage/src/assets';
import ClientContentManagementController from "./ClientContentManagementController";
import HtmlReactParser from 'html-react-parser';
import Sidebar from "../../../components/src/sidebar/Sidebar.web";
import { SketchPicker } from 'react-color';
import DOMPurify from 'dompurify';
import { myLearningCourseImg } from "../../../blocks/dashboard/src/assets";
import {KeyboardArrowLeft, KeyboardArrowRight, TimerOutlined} from '@mui/icons-material'
import { imageArrowLeft ,iconCaretLeft, iconCaretRight} from "./assets";


const editorModules = {
  toolbar: [
    [{ 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'image'],
    [{ 'color': [] }, { 'background': [] }],
    ['clean']
  ],
};

const editorFormats = [
  'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'color', 'background'
];

export default class ClientContentManagement extends ClientContentManagementController {
  private adminGreetingTitle = React.createRef<HTMLDivElement>();
  private adminGreetingDescription = React.createRef<HTMLDivElement>();
  private providerLogo = React.createRef<HTMLDivElement>();
  private providerDescription = React.createRef<HTMLDivElement>();

  handlePageClick = (e: any) => {
    if (e.target == this.state.selectedBlock || !this.state.selectedBlock) return;

    this.setState((prevState) => ({ ...prevState, selectedBlock: null }));
    this.setState((prevState) => ({ ...prevState, editorState: '' }));
    this.setState({ isEditing: false });
  }

  setSelectedBlock = (e: any | null) => {
    e?.preventDefault();

    const currentTarget = e.currentTarget;

    this.setState((prevState) => ({ ...prevState, selectedBlock: currentTarget }));

    this.setState((prevState) => ({
      ...prevState, editorState: prevState.selectedBlock?.innerHTML
    }));
  }

  startEdit = (editingType: string) => {
    this.setState({
      showSaveButton: true,
      previousHTML: {
        adminGreetingTitle: this.adminGreetingTitle.current?.innerHTML,
        adminGreetingDescription: this.adminGreetingDescription.current?.innerHTML,
        providerLogo: this.providerLogo.current?.innerHTML,
        providerDescription: this.providerDescription.current?.innerHTML,
      }
    });

    this.setState({ editingType });

    this.setState({ isEditing: !this.state.isEditing });
  }

  remove = (key: string) => {
    if (!this.state.selectedBlock) return;
    this.setState({ selectedBlock: null });
    this.setState({ editorState: '' });
    this.setState({ keysToDelete: [...this.state.keysToDelete, key] })
  }
  /* istanbul ignore next */
  totalPages = () => {
    const { selectedPath, allCourses, response,itemsPerPage } = this.state;
    const totalList = selectedPath
       ? response?.learning_paths.find(path => path.id == selectedPath)?.courses
       : allCourses;
    return Math.ceil(totalList?.length / itemsPerPage);
  };
  /* istanbul ignore next */
  renderPageNumbers = () => {
    const { currentPage } = this.state;
    const totalPages = this.totalPages();
    const pages = [];
  
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) {
        pages.push('...');
      }
        const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
  
      if (currentPage < totalPages - 2) {
        pages.push('...');
      }
  
      pages.push(totalPages);
    }
  
    return pages.map((page, index) => {
      if (page === '...') {
        return (
          <Button
            key={`ellipsis-${index}`}
            disabled
            style={{
              color: '#000',
              minWidth: '40px',
              height: '37px',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            {page}
          </Button>
        );
      }
      return (
        <Button
          key={`page-${page}`}
          data-test-id={`PageNo${page}`}
          style={{
            color: 'black',
            backgroundColor: '#fff',
            minWidth: '40px',
            height: '37px',
            boxShadow: 'none',
            borderRadius: '4px',
            border: this.state.currentPage === page ? '1px solid #141615' : 'none',
          }}
          variant={this.state.currentPage === page ? 'contained' : 'outlined'}
          onClick={() => this.handlePageChange(page)}
        >
          {page}
        </Button>
      );
    });
  };

  /* istanbul ignore next */
  isEditingRender(showButtons: boolean) {
    return (
      showButtons &&
      <>
         <Button
          sx={styles.previewButton}
          data-testid='btnSave'
          onClick={() => this.handleShowPreview()}
        >
          Preview
        </Button>
        <Button
          sx={styles.saveButton}
          data-testid='btnSave'
          onClick={() => this.updateContent()}
        >
          Save
        </Button>
        <Button sx={styles.moreButton} color="info">
          <img src={img3Dots} alt="3dots" />
        </Button>
      </>
    )
  }
 
  PopupMenuRender = (condition: boolean, key: string) => {
    return (
      condition &&
      <Box className="popup_edit">
        <Button onClick={() => this.startEdit('full')}>
          <img src={imgEdit} alt="pencil" />
        </Button>
        <Button data-test-id={'color-editor'}onClick={() => this.startEdit('color')}>
          <img src={imgPaintBacket} alt="color" />
        </Button>
        <Button onClick={() => this.remove(key)}>
          <img src={imgTrashBin} alt="delete" />
        </Button>
      </Box>
    )
  }

  renderHTML = (data: any) => {
    if (data?.length) {
      return data.includes('<p') ? HtmlReactParser(data.replaceAll('<p>', '').replaceAll('</p>', '')) : data;
    }
  }

  ReactQuillRender = (condition: boolean, editorState: any, type: string = '') => {
    const bgColorState :()=>string = ()=>{
      switch (type) {
        case 'admin_greeting_title': return this.state.adminGreetingTitleBgColor
        case 'admin_greeting_desc': return this.state.adminGreetingDescriptionBgColor
        case 'provider_desc': return this.state.providerDescriptionBgColor
        default: break
      }
    }
    return (
      condition && (
        this.state.editingType === 'full'?
        <ReactQuill
        value={editorState}
        className={'editor-container'}
        onChange={(e) => this.handleEditorChange(e, type)}
        modules={editorModules}
        formats={editorFormats}
      />
        :
        <EditorColorPickerContainer>
				<SketchPicker
					data-test-id={`bg-color`}
					color={bgColorState()}
					onChangeComplete={(color: any) => this.handleBgColorChange(color,type)}
				/>
			</EditorColorPickerContainer>
      )
      
    )
  }

  parsedString = (data: any) => {
    const cleanHtml = DOMPurify.sanitize(data);
    return HtmlReactParser(cleanHtml);
  }

  /* istanbul ignore next */
  sanitizeData = (htmlString:string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };

  /* istanbul ignore next */
  handlePageChange = (newPage:any) => {
    if (newPage > 0 && newPage <= this.totalPages()) {
      this.setState({ currentPage: newPage });
    }
  }
  /* istanbul ignore next */
  renderPreview=()=>{
    const {editorState, selectedPath, allCourses, response,currentPage,itemsPerPage } = this.state;
     const coursesList = selectedPath
       ? response?.learning_paths.find(path => path.id == selectedPath)?.courses
       : allCourses;
       const totalPages = Math.ceil(coursesList?.length / itemsPerPage);
       const startIndex = (currentPage - 1) * itemsPerPage;         
    return(
      <Box sx={{padding:"30px",backgroundColor:"#f6f6f6"}}>
          <DefaultCustomButton onClick={this.handleClosePreview}  style={styles.backButtonStyle} data-test-id="back-btn">
            <img src={imageArrowLeft} alt="" />
             <span>Back</span>
           </DefaultCustomButton>
          <Box sx={[{...styles.pageContent},{marginRight:'48px'}]}>
              <Box
               sx={{ display: 'flex', justifyContent: 'space-between',marginTop:"2%",padding:'2%',backgroundColor:"#fff",width:'95%'}}
               >
             <Box  display={"flex"} flexDirection={"column"} gap={"16px"}>
               <Box display={"flex"} gap={"16px"} sx={{...styles.adminGreeting,backgroundColor:this.state.adminGreetingTitleBgColor,maxHeight:"auto"}}>
                 {this.state.allContent?.attributes?.welcome_heading_logo ? <><img src={this.state.allContent?.attributes?.welcome_heading_logo.url} alt="wave" /></> :!this.state.adminGreetingTitleHTML&& <img src={imgWave} alt="wave" />}
                 {this.state.adminGreetingTitleHTML ? <WrapperBox>{this.parsedString(this.state.adminGreetingTitleHTML)}</WrapperBox> :  <Typography
                     style={{ fontSize: "32px", fontWeight: '300', lineHeight: '47px', fontFamily: "Heebo-Light" }}
                   >
                     Welcome {" "} 
                     <span style={{ fontWeight: '500', fontFamily: "Heebo-Regular", color: '#232323' }}>
                       {this.state.ClientName},
                     </span>
                     {" "}from Builder.Ai
                   </Typography>}
               </Box>
               {this.state.adminGreetingDescriptionHTML !=="" ? 
               <WrapperBox sx={{...styles.greetingInfo,maxHeight:"auto",backgroundColor:this.state.adminGreetingDescriptionBgColor}}>
                {this.parsedString(this.state.adminGreetingDescriptionHTML)}</WrapperBox> : <Typography>
                 Here you can access courses designed to enhance your skills and knowledge.
                 Explore the wide range of learning paths we have curated just for you.
               </Typography>}
             </Box>
             <Box width={"290px"}>
              {this.state.providerLogoHTML ?<img height={100} width={100} src={this.state.providerLogoHTML} alt="builder_logo" />: <img  src="/builderLogo.svg" alt="builder_logo" />}
               {this.state.providerDescriptionHTML ? 
               <Box sx={{backgroundColor:this.state.providerDescriptionBgColor,padding:"0% 2%" }}>
               <WrapperDiv>{this.parsedString(this.state.providerDescriptionHTML)}</WrapperDiv>
               </Box> :    
               <Typography style={{
                 color: '#4C4C4C', fontWeight: "400", fontSize: '16px', lineHeight: '23.5px',
                 fontFamily: "Heebo-Regular"
               }}
                 paddingTop="20px">
                 "This portal is brought to you in
                 collaboration with Builder.ai and Join
                 Talent, tailored for your learning needs."
               </Typography>}
             </Box>
              </Box>
                           
             <Box sx={{...styles.pathTitle,marginTop:"2%",fontSize: '30px',}}>Your Learning Paths</Box>
                <Box style={{display:"flex",marginTop:"2%",justifyContent:"space-between",alignItems:"baseline"}}>
                  <Box sx={{...styles.filterButtonsBlock}}>
                    <Box
                      sx={{
                        ...(this.handleStyle(styles, null)),
                        ...styles.filterButton,
                      }}
                      onClick={() => this.handlePathClick(null)}
                    >
                      All Courses ({this.state.allCourses.length})
                    </Box>
                     {response?.learning_paths?.map((tab) => (
                      <Box
                        key={tab.id}
                        sx={{
                          ...(this.handleStyle(styles,tab.id)),
                          ...styles.filterButton,                          
                        }}
                        onClick={() => this.handlePathClick(tab.id)}
                        color="info"
                      >
                        {tab.title} ({tab.courses.length})
                      </Box>
                    ))} 
                  </Box>
                  <Box  alignItems={"center"} sx={{ gap: "32px" }} display={'flex'} justifyContent={'space-between'}>
                    <Box sx={{
                      height: '40px', width: '40px',
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                      borderRadius: '100%',
                    }}
                    justifyContent={'center'} alignItems={"center"} display={'flex'} 
                    >
                       <img src={iconCaretLeft} alt="left" /> 
                    </Box>
                <Box sx={{
                  height: '40px', width: '40px',
                  borderRadius: '50%',
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                }}
                alignItems={"center"} display={'flex'} justifyContent={'center'} 
                >
                  <img src={iconCaretRight} alt="right" />
                </Box>
              </Box>
                 </Box>
               
                 <Grid container spacing={4} marginTop={1} marginBottom={'1rem'}>
                {coursesList?.slice(startIndex, startIndex + itemsPerPage).map((course: any) => (
                <Grid item xs={3} key={course.id}> 
                  <Box
                    sx={styles.courseCard}>
                    <BoxThumbnail>
                      <img src={course.images?.url || myLearningCourseImg} alt="ux_courses" />
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '10px',
                          height: '65px',
                          boxSizing: 'border-box'
                        }}
                      />
                      <Typography
                        style={{
                          display: 'flex',
                          color: '#fff',
                          padding: '10px',
                          fontSize: '14px',
                          alignItems: 'center',
                          zIndex:1,
                        }}
                      >
                        <TimerOutlined style={{ marginRight: '5px' }} />
                        {course.duration} Hours
                      </Typography>
                    </BoxThumbnail>
                    <Box sx={styles.courseCardDescription}>
                      <Box 
                      dangerouslySetInnerHTML={{ __html: this.sanitizeData(course.title) }}
                       sx={{ fontSize: '16px',                        
                        "display": "block",
                        "overflow": "hidden",
                        "text-overflow": "ellipsis",
                        "-webkit-box-orient": "vertical",
                        "-webkit-line-clamp": "1",
                        fontWeight:"600"
                       }}/>
                      <Box sx={{ 
                        fontSize: '12px',
                        color: '#171717',
                        }}>
                        {course.lessons} lessons
                      </Box>
                      <Box 
                       dangerouslySetInnerHTML={{ __html: this.sanitizeData(course.description) }}
                       sx={{ 
                        fontSize: '16px',
                        "-webkit-line-clamp": "3",
                        "text-overflow": "ellipsis",
                        "overflow": "hidden",
                        "display": "-webkit-box",
                        "-webkit-box-orient": "vertical",
                        }}/>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
          <IconButton
            data-test-id="LeftBtn"
            onClick={() => this.handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <KeyboardArrowLeft
              sx={{ fontSize: 30, color: currentPage === 1 ? '#D7D7D7' : '#1C1C1C' }}
            />
          </IconButton>
          {this.renderPageNumbers()}
          <IconButton
            data-test-id="RightBtn"
            onClick={() => this.handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <KeyboardArrowRight
              sx={{ fontSize: 30, color: currentPage === totalPages ? '#D7D7D7' : '#1C1C1C' }}
            />
          </IconButton>
            </Box>


          </Box>
          </Box>
    )
  }
  renderCard = (course: any,index:number) => {
    return (<Box
      padding={"10px"}
      key={course.id}
      style={{
        maxWidth: '300px',
        width: '300px',
        minWidth: '300px',
        height: '350px'
      }}
    >
      <Card style={{
        width: '100%',
        height: '100%',
        position: 'relative'
      }}>
        <Box
          position="relative"
          >
          <Box
            style={{
              position: 'relative',
              height: '160px',
              width: '100%',
              backgroundSize: 'cover',
              backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${course?.images?.url ? this.courseImgUrl(course) : courseImg})`,
              backgroundPosition: 'center',
            }}
          />
          <Box
            position="absolute"
            left={0}
            top={0}
            height="100%"
            width="100%"
            flexDirection="column"
            display="flex"
            justifyContent="space-between"
            p={1}
          >
            <Box
              display={"flex"}
              padding={"5px"}
              alignItems={"center"}>
              <img src={timer} height={"16px"} width={"16px"} alt="" />
              <Typography
                color={"white"}
                margin={"10px"}>
                {course.duration} Hours
              </Typography>
            </Box>
          </Box>
        </Box>
        <CardContent>
          <Typography variant="h6" component="div" sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 2,
          }}> {this.sanitizeData(course.title)}</Typography>
          <Typography variant="body2" color="text.secondary"> {course.lessons} Lessons</Typography>
          <Typography variant="body2" color="text.secondary" sx={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            WebkitLineClamp: 3,
          }}>
            {this.sanitizeData(course.description)}
          </Typography>
        </CardContent>
      </Card>
    </Box>)
  }

  render() {
    const { editorState, isEditing, selectedBlock, selectedPath, allCourses, response,showPreview } = this.state;
    const isAdminGreetingTitle = selectedBlock == this.adminGreetingTitle.current;
    const isAdminGreetingDescription = selectedBlock == this.adminGreetingDescription.current;
    const isProviderLogo = selectedBlock == this.providerLogo.current;
    const isProviderDescription = selectedBlock == this.providerDescription.current;
    const isAdminGreetingTitleEditing = isAdminGreetingTitle && isEditing;
    const isAdminGreetingDescriptionEditing = isAdminGreetingDescription && isEditing;
    const isProviderLogoEditing = isProviderLogo && isEditing;
    const isProviderDescriptionEditing = isProviderDescription && isEditing;
    const isShowUpdatedBranding = (template: any) => template;

     const coursesList = selectedPath
       ? response?.learning_paths.find(path => path.id == selectedPath)?.courses
       : allCourses;
    return (
      <StyledEngineProvider>
        <Wrapper onClick={this.handlePageClick} data-testid="mainWrapper">
         {showPreview ? 
          <>
          {this.renderPreview()}
          </>
         :(
          <Box sx={styles.mainBlock}>
            <Box>
              <Sidebar navigation={this.props.navigation} current='clients' />
            </Box>
            <Box sx={styles.pageContent} data-testid="pageContent">
              <Box sx={styles.contentHeader}>
                <Box sx={styles.navigation}>
                  <Box sx={styles.breadcrumbButton}
                  data-test-id="client-navigate"
                  onClick={()=>{this.props.navigation.navigate('Clients')}}
                  >
                    Clients
                    </Box>
                  <img src={imgChevronRight} alt="rightangle" />
                  <Box sx={styles.currentNavigationPage} 
                  data-test-id='manageClient-navigate'
                  onClick={()=>{this.props.navigation.navigate('ManageClient')}}>
                    {this.state.ClientName}
                    </Box>
                  <img src={imgChevronRight} alt="rightangle" />
                  <Box sx={styles.currentNavigationPage}>Content Management</Box>
                </Box>
                <Box sx={styles.leftActions}>
                  {this.isEditingRender(this.state.showSaveButton)}
                </Box>
              </Box>

              <Box sx={styles.userContent}>
                <Box sx={styles.greetingBlock} width="100%" data-testid="greetingBlock">
                  <Box onClick={(e: any) => e.stopPropagation()}>
                    {this.PopupMenuRender(isAdminGreetingTitle, 'admin_greeting_title')}
                    {this.ReactQuillRender(isAdminGreetingTitleEditing, editorState, 'admin_greeting_title')}
                    <Box
                      sx={{...styles.adminGreeting,backgroundColor:this.state.adminGreetingTitleBgColor,maxHeight:"auto"}}
                      className={isAdminGreetingTitleEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.adminGreetingTitle}
                      data-testid="greetingTitle"
                    >
                      {isShowUpdatedBranding(this.state.adminGreetingTitleHTML) ?
                        <WrapperBox>{HtmlReactParser(this.state.adminGreetingTitleHTML)}</WrapperBox>
                        : <Box sx={styles.greetingBg}>
                          <Box> <img style={{ maxHeight: '60px', maxWidth: '200px' }} src={imgWave} alt="wave"></img></Box>
                          <Box><div>{this.state.allContent?.attributes?.heading}</div></Box>
                        </Box>}
                    </Box>

                    {this.PopupMenuRender(isAdminGreetingDescription, 'admin_greeting_desc')}
                    {this.ReactQuillRender(isAdminGreetingDescriptionEditing, editorState, 'admin_greeting_desc')}
                    <Box
                      sx={{...styles.greetingInfo,backgroundColor:this.state.adminGreetingDescriptionBgColor,maxHeight:"auto"}}
                      className={isAdminGreetingDescriptionEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.adminGreetingDescription}
                      data-testid="greetingDescription"
                    >
                      {isShowUpdatedBranding(this.state.adminGreetingDescriptionHTML) ?
                        <WrapperBox> {HtmlReactParser(this.state.adminGreetingDescriptionHTML)}</WrapperBox> :
                        <p>{this.state.allContent?.attributes?.context}</p>
                      }
                    </Box>
                  </Box>

                  <Box sx={styles.providerBlock} onClick={(e: any) => e.stopPropagation()} >
                    <Box
                      className={isProviderLogoEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      sx={{ maxHeight: '60px', maxWidth: '200px', overflow: 'hidden' }}
                      ref={this.providerLogo}
                      data-testid='providerLogo'
                    >
                      {this.state.providerLogoHTML ? 
                        <img style={{ maxHeight: '60px', maxWidth: '200px' }} src={this.state.providerLogoHTML} alt="builder_logo" />
                      :
                        <img style={{ maxHeight: '60px', maxWidth: '200px' }} src="/builderLogo.svg" alt="builder_logo" />}
                    </Box>
                    {this.PopupMenuRender(isProviderDescription, 'provider_desc')}
                    {this.ReactQuillRender(isProviderDescriptionEditing, editorState, 'provider_desc')}
                    <Box
                      className={isProviderDescriptionEditing ? 'hide' : ''}
                      onContextMenu={this.setSelectedBlock}
                      onClick={this.setSelectedBlock}
                      ref={this.providerDescription}
                      sx={{backgroundColor:this.state.providerDescriptionBgColor}}
                      data-testid="providerDescription"
                    >{isShowUpdatedBranding(this.state.providerDescriptionHTML) ?
                      <WrapperDiv> {HtmlReactParser(this.state.providerDescriptionHTML)}</WrapperDiv> :
                      <p>{this.state.allContent?.attributes?.company_logo_context}</p>}
                    </Box>
                  </Box>
                </Box>
                  <Box sx={styles.pathTitle}>Your Learning Paths</Box>
                <Box>
                  <Box sx={styles.filterButtonsBlock}>
                    <Box
                      sx={{
                        ...styles.filterButton,
                        ...(this.handleStyle(styles, null))
                      }}
                      data-test-id="all-courses-btn"
                      onClick={() => this.handlePathClick(null)}
                    >
                      All Courses ({this.state.allCourses.length})
                    </Box>
                     {response?.learning_paths?.map((path) => (
                      <Box
                        key={path.id}
                        sx={{
                          ...styles.filterButton,
                          ...(this.handleStyle(styles,path.id))
                        }}
                      data-test-id={`path-btn-${path.id}`}
                      color="info"
                        onClick={() => this.handlePathClick(path.id)}
                      >
                        {path.title} ({path.courses.length})
                      </Box>
                    ))} 
                  </Box>
                </Box>

                <Box>
                  <Box sx={styles.courseCardsBlock}>
                        {coursesList?.map((course: any, index: number) => (
                          <Grid item xs={3} key={course.id}>
                            {this.renderCard(course, index)}</Grid>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>)}

          <Box sx={styles.footerBlock}>
            <Box sx={styles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Pravicy Policy</Box>
              <Box></Box>
            </Box>

            <Box>
              <Box>© 2024 All Rights Reserved</Box>
            </Box>
          </Box>
        </Wrapper>
      </StyledEngineProvider>
    )
  }
}
const EditorColorPickerContainer = styled(Box)({
	position: 'absolute',
	top: '100px',
});
const Wrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  ".ql-size-huge": {
    fontSize: "32px"
  },
  ".ql-size-large": {
    fontSize: '24px'
  },
  '.hide': {
    display: 'none'
  },
  "p": {
    margin: '0px',
  },
  ".popup_edit": {
    display: 'flex',
    flexDirection: 'row',
    background: '#FFFFFF',
    width: '270px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  ".editor-container": {
    position: 'absolute',
    zIndex: 10,
    background: '#FFFFFF',
    borderRadius: '4px',
    maxWidth: '700px',
    maxHeight: '600px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  }
}));

const WrapperDiv = styled('div')({
  '& p:first-of-type': {
    wordBreak: 'break-word',
  },
  '& img':{
    width:"100%"
  }
});

const WrapperBox = styled('div')({
  '& img':{
    width:"100%"
  }
});

const BoxThumbnail = styled(Box)({
  boxShadow: 'inset 0px -130px 50px -85px rgba(0,0,0,0.9)',
  boxSizing:'border-box',
  position:'relative',
  width: '100%',
  display:'flex',
  flexDirection:'column',
  justifyContent:'space-between',
      height:'160px',
  "&>img":{
      position: 'absolute',
      width:'100%',
      height:'100%',
      zIndex:""
  }
})

const DefaultCustomButton = styled(Button)({
  textTransform: "none",
});

const baseButtonStyle = {
  fontFamily: "Inter",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "22px",
  padding: "6px 0px",
  color: "#1C1C1C",
};

const styles = {
  mainBlock: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6',
    height: '100%'
  },
  backButtonStyle: {
    ...baseButtonStyle,
    fontFamily: "Heebo-Regular",
    fontWeight: 400,
    fonstSize: "16px",
    lineHeight: "24px",
    gap: 8,
    "& img": {
        width: 24,
        height: 24,
    }
   },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff'
  },
  leftFooter: {
    display: 'flex',
    gap: '24px'
  },
  rightFooter: {},

  sideBar: {
    height: '974px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 16px 24px 40px',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff'
  },
  sideBarTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  sideNavList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  sideNavItem: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '4px 16px',
    borderRadius: '8px'
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth:'thin',
    backgroundColor: '#f6f6f6',
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px 48px'
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px'
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    padding: '4px 8px'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  learningPathBtn:{
    '&:hover': {
      backgroundColor: '#fafafa',
    },
    textTransform: 'none',
    backgroundColor:'transparent',
    color:'black',
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700',
    textTransform:"capitalize"
  },

  previewButton: {
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': {
      backgroundColor: 'lightgray',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700',
    textTransform:"capitalize"
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  userContent: {
    padding: '0 40px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '26px',
    overflowY:'scroll',
    height:'100%'
  },
  greetingBlock: {
    boxSizing:'border-box',
    display: 'flex',
    flexDirection: 'row',
    padding: '24px 16px',
    justifyContent: 'space-between',
    background: '#FFFFFF',
  },
  adminGreeting: {
    background: '#F0F0F0',
    display: 'inline-block',
    fontSize: '32px',
    whiteSpace: 'wrap',
    marginBottom: '16px',
    padding: '4px',
    maxHeight: '400px',
    overflow: 'hidden',
    borderRadius: '4px'
  },
  greetingBg: {
    background: '#F0F0F0',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '4px'
  },
  greetingInfo: {
    display: 'flex',
    paddingLeft: '4px',
    flexDirection: 'column',
    maxWidth: '700px',
    maxHeight: '400px',
    overflow: 'hidden'
  },
  coursesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  providerBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '290px',
    marginLeft: '16px'
  },
  editorContainer: {
    position: 'absolute',
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  pathTitle: {
    fontSize: '24px',
    fontWeight: '500'
  },

  filterButtonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    width:'70vw',
    padding:'10px 0 20px',
    overflowX:'scroll',
    scrollbarWidth:'thin'
  },
  filterButton: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    borderRadius:'4px',
    padding: '8px 16px 4px',
    fontSize: '14px',
    width:'max-content',
    minWidth:'140px',
    maxHeight:'40px',
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '40px',
    borderRadius: '4px',
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '100%',
    height: '350px',
    maxWidth: '350px',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow: {
    whiteSpace: 'nowrap'
  }
}

const flexRow = {
  display: 'flex',
  flexDirection: 'row'
}
