import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { toast } from "react-toastify";
import {uploadFileToApi} from '../../../components/src/UploadFile.web';
type LogoSize = 'large' | 'medium' | 'small';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  clientName:string,
  showClientProfile:boolean,
  companyName:string,
  contactName:string,
  contactJobTitle:string,
  contactEmail:string,
  contactNumber:string,
  companyLogoUrl:string,
  isLogoChanged: boolean;
  fileInputRef:any,
  logoFile:any,
  emailError:boolean,
  companyNameError: boolean,
  logoError: boolean;
  contactNameError: boolean,
  contactJobTitleError: boolean,
  contactNumberError:boolean,
  logoLarge: any;
  logoMedium: any;
  logoSmall: any;
  errors: {
    companyLogoLarge?: string;
    companyLogoMedium?: string;
    companyLogoSmall?: string;
  };
  touched: {
    companyLogoLarge?: boolean;
    companyLogoMedium?: boolean;
    companyLogoSmall?: boolean;
  };
  selectedResolution: string | null;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSelectedClientDetailsApiCallId='';
  saveApiCallId = '';
  fileInputLarge?: HTMLInputElement | null;
  fileInputMedium?: HTMLInputElement | null;
  fileInputSmall?: HTMLInputElement | null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id:'0',
      clientName:'',
      showClientProfile:false,
      companyName:'',
      contactName:'',
      contactJobTitle:'',
      contactEmail:'',
      contactNumber:'',
      companyLogoUrl:'',
      isLogoChanged: false,
      logoFile:null,
      fileInputRef:React.createRef(),
      emailError:false,
      companyNameError: false,
      logoError: false,
      contactNameError: false,
      contactJobTitleError: false,
      contactNumberError:false,
      logoLarge: null,
      logoMedium: null,
      logoSmall: null,
      errors: {},
      touched: {},
      selectedResolution: '',
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getSelectedClientDetails();
  }


  receive = async (from: string, message: Message) => {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getSelectedClientDetailsApiCallId) {
        this.receiveClientDetailsResponse(responseJson);
      }
      if (apiRequestCallId === this.saveApiCallId) {
        this.receiveSaveResponse(responseJson);
      }
    }
  };
  
  getSelectedClientDetails = () => {
    let token = localStorage.getItem('token')
    let selectedClientId = localStorage.getItem('SelectedClientID')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/clients/${selectedClientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    this.getSelectedClientDetailsApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveClientDetailsResponse = (response:any) => {
    this.setState({
      clientName:response.data.attributes.company_name,
      companyName:response.data.attributes.company_name,
      contactName:response.data.attributes.contact_name,
      contactJobTitle:response.data.attributes.contact_job_title,
      contactEmail:response.data.attributes.contact_email,
      contactNumber:response.data.attributes.contact_phone,
      logoLarge:response.data.attributes.company_logo_large,
      logoMedium:response.data.attributes.company_logo_medium,
      logoSmall:response.data.attributes.company_logo_small,
    })
  }
  handleDelete = () => {
    if(this.state.fileInputRef.current) {
      this.state.fileInputRef.current.value = "";
    }
    this.setState({ companyLogoUrl: '', logoError: true, isLogoChanged: true, logoFile:null });
  };

  handleImageUpload = (event:any) => {
    const file = event.target.files[0];
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];
    if (!file) return;
    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const img = new Image();
        img.onload = () => {
          this.setState({
            isLogoChanged: true,
            logoError: false,
            companyLogoUrl: reader.result as string
          });
        }
        img.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Please select a valid PNG, JPG or JPEG image.");
    }
  };
  handleChange = (event:any,field:string) => {
    const value = event.target.value;

    if (field === 'company-name') {
        this.setState({
            companyName: value,
            companyNameError: value.trim() === '' 
        });
    }
    if (field === 'contact-name') {
        this.setState({
            contactName: value,
            contactNameError: value.trim() === '' 
        });
    }
    if (field === 'contact-job-title') {
        this.setState({
            contactJobTitle: value,
            contactJobTitleError: value.trim() === '' 
        });
    }
  }
  handleEmailChange = (event:any) => {
    const email = event.target.value;
    const isValidEmail = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);
    
    this.setState({
      contactEmail: email,
      emailError: !isValidEmail, 
    });
  }
  handlePhoneChange = (event: any) => {
    const value = event.target.value;

    if (/^\d*$/.test(value) && value.length <= 12) {
      this.setState({
        contactNumber: value,
        contactNumberError: value.length !== 12,
      });
    }
  }
  handleSave = () => {
    if(!this.validateFields())
    {
      return
    } 
    let reqBody = {
      "client": {
      "company_logo_large":this.state.logoLarge ??'',
      "company_logo_medium":this.state.logoMedium ?? '',
      "company_logo_small":this.state.logoSmall?? '',
      "company_name":this.state.companyName,
      "contact_name":this.state.contactName,
      "contact_email":this.state.contactEmail,
      "contact_job_title":this.state.contactJobTitle,
      "contact_phone":this.state.contactNumber
      }
    }
    let token = localStorage.getItem('token')
    let selectedClientId = localStorage.getItem('SelectedClientID')
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.saveApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/clients/${selectedClientId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reqBody)
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  receiveSaveResponse = (response:any) => {
    if(response?.meta?.errors)
    {
      toast.error(response?.meta.errors)
      return;
    }
    if (response.errors && Array.isArray(response.errors) && response.errors.length > 0) {
      response.errors.map((err: string) => {
        toast.error(err);
      });
    } else {
      toast.success(response?.meta.message);
      this.setState({ isLogoChanged: false });
    }
  }
  NavigationClient = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationClientMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  validateFields = () => {
    const {
      companyNameError,
      contactNameError,
      contactJobTitleError,
      contactNumberError,
      logoError
    } = this.state;

    if(logoError) {
      toast.error("Company logo is required");
      return false;
    }
    if (companyNameError) {
      toast.error("Company name is required");
      return false;
    }
    if (contactNameError) {
      toast.error("Contact name is required");
      return false;
    }
    if (contactJobTitleError) {
      toast.error("Job title is required");
      return false;
    }
    if (contactNumberError) {
      toast.error("Phone number must be exactly 12 digits and cannot be empty");
      return false;
    }
    
    return true;
  };
  assignRef = (size: LogoSize) => (ref: HTMLInputElement | null) => {
    if (size === 'large') {
      this.fileInputLarge = ref;
    } else if (size === 'medium') {
      this.fileInputMedium = ref;
    } else if (size === 'small') {
      this.fileInputSmall = ref;
    }
  };
  handleLogoUpload = async (file: File, size: LogoSize) => {
    const res: any = await uploadFileToApi(file);
    if (res) {
      this.setState((prevState) => ({
        logoLarge: size === 'large' ? res.image_url : prevState.logoLarge,
        logoMedium: size === 'medium' ? res.image_url : prevState.logoMedium,
        logoSmall: size === 'small' ? res.image_url : prevState.logoSmall,
      }));
    }
  };
  
  handleFileChange = (size: LogoSize) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      this.handleLogoUpload(file, size);
    }
  };
  handleReuploadClick = (inputRef: HTMLInputElement | null) => () => {
    if (inputRef) {
      inputRef.click();
    }
  };
  handleDeleteLogo = (size: LogoSize) => () => {
    this.setState({
      ...this.state,
      [`logo${size.charAt(0).toUpperCase() + size.slice(1)}`]: null
    });
  };
  handleDrop = (event: React.DragEvent<HTMLDivElement>, size: LogoSize) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      this.handleLogoUpload(file, size);
    }
  };
  handleDropEvent = (size: LogoSize) => (e: React.DragEvent<HTMLDivElement>) => {
      this.handleDrop(e, size);
  };
  handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };
  handleFileUpload = (inputRef: HTMLInputElement | null) => () => {
    if (inputRef) {
      inputRef.click();
    }
  }
  handleInfo = (resolution : string) => () => {

    this.setState((prevState) => ({
      selectedResolution: prevState.selectedResolution === resolution ? null : resolution,
    }));
  }
  // Customizable Area End
}
