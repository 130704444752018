import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Grid,
  Input,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import ContactusController, { Props } from "./ContactusController";

// Customizable Area Start
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  TablePagination,
  InputBase,
  TextField,
  Dialog, DialogTitle, DialogContent, DialogActions, FormLabel, Divider, IconButton, Popover
} from "@mui/material";
import { MoreVert, FilterList, KeyboardArrowDown } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Sidebar from "./../../../components/src/sidebar/Sidebar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: 'white', 
  '&:hover': {
    backgroundColor: '#F0F0F0'
  },
}));
// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }
// Customizable Area Start
  renderCategoriesDropdown = (currentItem:any) => {
    return(
    <Select
      labelId={`category-label-${currentItem.id}`}
      id={`select` + currentItem.id}
      key={`select` + currentItem.id}
      label={'category'}
      value={this.getCategoryVal(currentItem)}
      data-test-id="changeCategory"
      renderValue={(value) => value}
      onChange={(e) => this.onChangeCategory(currentItem, e.target.value)}
      sx={{ fontFamily:"Heebo-Bold",fontSize:"12px",lineHeight:"18px",
        minWidth:'120px',
        '& .MuiSelect-select': {
          padding: '9px 14px !important', 
          '&:focus': {
            outline: 'none',
            border: 'none',
          },
        },
        '&:focus-visible': {
          outline: 'none',
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '300px',
            overflowY: 'auto',
            backgroundColor: '#FFFFF'
          },
        },
      }}
    >
      <CustomMenuItem value={'Category'} sx={styles.menuItemContainer}><Box sx={styles.categoryText}>{'Category'}</Box></CustomMenuItem>
      {this.state.availableCategories?.map((item: any) =>
        <CustomMenuItem value={item.attributes.name} sx={styles.menuItemContainer}><Box>{item.attributes.name}</Box>
          < Box><IconButton
            aria-label="delete"
            data-test-id="delCategory"
            onClick={(e) => this.onDeleteCategory(e, item)}
          >
            <DeleteOutlineIcon />
          </IconButton></Box></CustomMenuItem>
      )}
      <MenuItem value="addNew">
        <Box data-test-id="changeCategory" onClick={(e) => this.onChangeCategory('', "", true)} sx={styles.newCategoryText}>Add category</Box>< Box></Box></MenuItem>

    </Select>)
  }

  renderNewCategoryInput = () => {
    return (
      <TextField
        autoFocus
        label='Enter new category name'
        margin="dense"
        type="text"
        fullWidth
        data-test-id="nameChange"
        value={this.state.newCategoryName}
        onChange={(e) => this.handleCategoryNameChange(e.target.value)}
      />
    )
  }
  DeleteQueryModal = () => {
    return (
        <CustomDialog
            open={this.state.isDeletingQuery}
            onClose={this.handleDeleteQueryModalClose}
            className="delete-popup"
        >
            <DialogTitle className="dialog-title">
                <CloseIcon className="close-icon" data-testID="close-Modal" onClick={this.handleDeleteQueryModalClose} />
            </DialogTitle>
            <DialogContent className="heading">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box padding="0px 5px 0px 15px">
                            <Typography className="delete-title">Are you sure you want to delete this question?</Typography>
                            <Typography className="delete-description">This action can't be undone and won't be accessible anymore.</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="dialog-action">
                <CancelButton
                    onClick={this.handleDeleteQueryModalClose}
                >
                    No
                </CancelButton>
                <DoneButton
                    onClick={() =>this.showHeaderActionButtons()?this.deleteQueries(): this.deleteQueries(false, this.state.currentMoreItem?.id)}
                    data-testID="deleteBtn"
                    className="done-btn"
                >
                    Yes
                </DoneButton>
            </DialogActions>
        </CustomDialog>
    );
}
// Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box sx={styles.mainWrapper}>
            <Box sx={styles.mainBlock}>
              <Box>
                <Sidebar navigation={this.props.navigation} current='contact_us_queries' />
              </Box>

              <Box sx={styles.pageContent} data-testid="pageContent">
                <Box sx={styles.contentHeader}>
                  <Box sx={styles.navigation}>
                    <Box sx={styles.breadcrumbButton}>Contact Us</Box>
                  </Box>
                </Box>

                <Box sx={styles.selectOptions}>
                  <Box sx={{ display: 'flex', flexDireaction: 'row' }}>  {this.showHeaderActionButtons() ? <><Box fontStyle={{ fontSize: '16px', fontWeight: '400' }}>
                    <CustomCheckboxContactUs
                      checked={true}
                      data-test-id='btnUnselect'
                      onChange={() => this.unSelectAllQueries()}
                      color="info"
                    />
                    {this.getItemsSelectedString()}</Box>
                    <Box sx={styles.markReadBox} data-test-id='btnMark' onClick={() => this.markAsReadUnread(true)}>            
                      <Typography textAlign="center" sx={{fontWeight:700,fontSize:'16px',padding:'4px 14px', lineHeight:'24px',color:'#1C1C1C'}}>
                    Mark as read</Typography></Box>
                    <Box sx={styles.deleteBox} data-test-id='btnDelete' onClick={() => this.setState({isDeletingQuery:true})}>            
                      <Typography sx={{fontWeight:700,fontSize:'16px',padding:'4px 14px', lineHeight:'24px', color:'#DC2626'}} textAlign="center" >
                    Delete</Typography></Box>
                  </>
                    : null} </Box>
                  <Box sx={styles.headerOptions} >
                    <Box sx={styles.filterContainer}>

                      <Box>Filter</Box>
                      <IconButton data-test-id='btnFilter' onClick={(e) => this.handlePopoverClick(e, 'filter')}>
                        <FilterList />
                      </IconButton>
                      <Popover
                        key={'filter_popover'}
                        data-test-id='btnPopover'
                        id={this.filterPopoverId()}
                        style={{ boxShadow: 'none' }}
                        open={this.isFilterPopover()}
                        anchorEl={this.state.anchorElFilter}
                        onClose={() => this.handlePopoverClose()}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {this.filterKeys.map((item, index) => (
                          <MenuItem key={'filterId' + index} data-test-id='btnOptions' onClick={() => this.handleFilter(item)}>{item.label}</MenuItem>)
                        )}

                      </Popover>
                    </Box>
                    <Box sx={styles.sortByRow} >
                      <Box>Sort by:</Box>
                      <Box data-test-id='btnSort' sx={styles.relevText} onClick={(e) => this.handlePopoverClick(e, 'sort')}>{this.state.currentSortKey}</Box>
                      <Box data-test-id='btnSort' onClick={(e) => this.handlePopoverClick(e, 'sort')}><KeyboardArrowDown /></Box>
                    </Box>
                    <Box>

                      <Popover
                        data-test-id='btnPopover'
                        key={'sort_popover'}
                        id={this.sortPopoverId()}
                        style={{ boxShadow: 'none' }}
                        open={this.isSortPopover()}
                        anchorEl={this.state.anchorElSort}
                        onClose={() => this.handlePopoverClose()}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        {this.sortKeys.map((item, index) => (
                          <MenuItem key={'sortId' + index} data-test-id='btnOptions' onClick={() => this.handleSort(item)}>{item.label}</MenuItem>)
                        )}
                      </Popover>
                    </Box>
                  </Box>
                </Box>
                <Box sx={styles.tableContainer}>

                  <TableContainer component={Paper}>
                    <Table sx={{
                      borderCollapse: 'collapse',
                      border: 'none',
                      backgroundColor: '#ffffff',
                      boxShadow: 'none'
                    }
                    }>
                      <TableHead>

                        <TableRow>
                          <TableCell sx={styles.cellWrapper}>
                                <CustomCheckboxContactUs
                                data-test-id={'select-all-check'}
                                checked={this.state.isSelectAll}
                                onClick={()=>{
                                  this.handleSelectAllCheckboxToggle();
                                }}
                                  sx={{
                                    '&.Mui-checked': {
                                      color: 'black',
                                      borderRadius: '6px',
                                      '&:hover': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                      },
                                    },
                                  }}
                                />
                              </TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Subject and Message</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Category</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Requester</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Client</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Date & Time</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Status</Box></TableCell>
                          <TableCell sx={styles.cellHeader}><Box sx={styles.headerText}>Reply</Box></TableCell>
                          <TableCell sx={styles.cellHeader}></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ padding: '0px', border: 'none', height: '10px' }} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!this.state.queriesToRender?.length ? (
                          <TableRow>
                            <TableCell colSpan={3} style={{ textAlign: 'center' }}>
                              <Typography variant="h6" color="textSecondary">
                                No Data Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ) : <>
                          {this.state.queriesToRender?.slice(this.state.page * 10, this.state.page * 10 + 10).map((row, index) => (
                            <> <TableRow key={row.id} sx={{ justifyContent:"center",borderBottom: 'none', backgroundColor: this.getBGColor(row)}}>
                              <TableCell sx={styles.cellWrapper}>
                                <CustomCheckboxContactUs
                                  checked={this.isSelectedRow(row.id)}
                                  onChange={() => this.setSelectedQueries(row.id, index)}
                                  data-testid={`checkbox-${row.id}`}
                                />
                              </TableCell>
                              <TableCell sx={{ ...styles.cellWrapper}}><Box sx={styles.cellContainer}>
                                <Box sx={{ display: 'fex', flexDirection: 'row' }}>
                                  <Box sx={{ ...styles.name, fontFamily: this.isSelectedRow(row.id) ? 'Heebo-Bold' : 'Heebo-Regular' }} >{row.attributes.subject}</Box>
                                   {row.attributes?.replied ? <Typography sx={styles.repliedBox}>REPLIED</Typography> : null}
                                   </Box>
                                <Box sx={styles.descriptionContainer}>
                                  <Typography
                                    variant="body1"
                                    sx={styles.descriptionText}
                                  >
                                    {row.attributes.description}
                                  </Typography>
                                </Box></Box></TableCell>
                              <TableCell sx={styles.cellWrapper}>
                                <Box>
                                  {this.renderCategoriesDropdown(row)}
                                </Box>
                              </TableCell>
                              <TableCell sx={styles.cellWrapper}><Box sx={styles.cellContainer}><Box sx={{ ...styles.name2, fontFamily: this.isSelectedRow(row.id) ? 'Heebo-Bold' : 'Heebo-Regular' }}>{row.attributes.name}</Box><Box sx={styles.descriptionText1}>{row.attributes.email}</Box></Box></TableCell>
                              <TableCell sx={styles.cellWrapper}><Box sx={{ ...styles.cellWrapper1, fontFamily: this.isSelectedRow(row.id) ? 'Heebo-Bold' : 'Heebo-Regular' }}>{row.attributes.company}</Box></TableCell>
                              <TableCell sx={styles.cellWrapper}><Box sx={{ ...styles.name1, fontFamily: this.isSelectedRow(row.id) ? 'Heebo-Bold' : 'Heebo-Regular' }}>{this.formatDate(row.attributes.created_at)}</Box></TableCell>
                              <TableCell sx={styles.cellWrapper}>
                                <FormControl fullWidth variant="outlined" sx={{ border: 'none', borderWidth: '0px' }}>
                                  <Select
                                    labelId={`status-label-${row.id}`}
                                    value={row.attributes.status}
                                    data-test-id="changeCategorySelect"
                                    onChange={(e) => this.updateStatus(row.id, e.target.value)}
                                    sx={{
                                      ...this.isCompletedQuery(row) ? styles.statusContainerSuccess : styles.statusContainer,
                                      borderRadius: '4px',
                                      '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                      },
                                    }}label=""
                                  >
                                    <MenuItem sx={{ fontSize: '10px',fontFamily:"Heebo-Medium" }} value="in_progress">INPROGRESS</MenuItem>
                                    <MenuItem sx={{ fontSize: '10px' ,fontFamily:"Heebo-Medium"}} value="pending">PENDING</MenuItem>
                                    <MenuItem sx={{ fontSize: '10px',fontFamily:"Heebo-Medium" }} value="completed">COMPLETED</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                              <TableCell sx={styles.cellWrapper}><Box data-test-id='btnReply' sx={styles.replyContainer} onClick={() => this.openModal(row)}> <Box sx={styles.replyText}>Reply</Box></Box></TableCell>
                              <TableCell sx={styles.cellWrapper} key={'more_' + row.id} >
                                <Box>
                                  <IconButton key={'btn-more' + row.id} data-test-id='btnMore' onClick={(e) => this.handlePopoverClick(e, 'more', row)}
                                    sx={{
                                      borderRadius: '50%',
                                      width: 40,
                                      height: 40,
                                      backgroundColor: '#F0F0F0',
                                    }}
                                  >
                                    <MoreVert />
                                  </IconButton>
                                  <Popover
                                    data-test-id='btnPopover'
                                    key={'more_popover' + row.id}
                                    id={this.morePopoverId() + row.id}
                                    style={{ boxShadow: 'none' }}
                                    open={this.isMorePopover()}
                                    anchorEl={this.state.anchorEl}
                                    onClose={() => this.handlePopoverClose()}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }}
                                  >
                                    <Typography sx={{ p: 2 }}>
                                      <MenuItem key={'action_a'} data-test-id='btnOptions' onClick={() => this.openModal(row)}>View</MenuItem>
                                      <MenuItem key={'action_b'} data-test-id='btnOptions' onClick={() => this.markAsReadUnread(true, false, row.id)}>Mark as read</MenuItem>
                                      <MenuItem key={'action_c'} data-test-id='btnOptions' onClick={() => this.markAsReadUnread(false, false, row.id)}>Mark as unread</MenuItem>
                                      <MenuItem key={'action_c'} data-test-id='btnOptions' onClick={() => this.setState({isDeletingQuery:true})}><Box sx={{ color: '#DC2626' }}>Delete</Box></MenuItem>
                                    </Typography>
                                  </Popover>
                                </Box>
                              </TableCell>
                            </TableRow>
                              <TableRow>
                                <TableCell sx={{ padding: '0px', border: 'none', height: '10px' }} />
                              </TableRow>
                            </>
                          ))}</>}
                      </TableBody>

                    </Table>
                  </TableContainer>
                </Box>
                <Dialog open={this.isOpenNewCatDialog()} onClose={() => { }}>
                  <DialogTitle>Add New Category</DialogTitle>
                  <DialogContent>
                    {this.renderNewCategoryInput()}
                  </DialogContent>
                  <DialogActions>
                    <CancelButton data-test-id="btnClose" onClick={this.toggleAddNewCategoryModal}>
                      Cancel
                    </CancelButton>
                    <DoneButton
                      type="submit"
                      data-test-id="btnClose"
                      onClick={() => this.addNewCategory()}
                    >
                      Add
                    </DoneButton>
                  </DialogActions>
                </Dialog>

                <CustomDialog
                  data-test-id="dialogContainer"
                  open={this.isReplyModalOpen()}
                  scroll="body"
                  sx={{ paddingTop: '10px', paddingBottom: '10px' }}
                  onClose={() => this.closeModal()}
                >
                  <DialogTitle className="dialog-title">
                    <ArrowBackIcon data-test-id="btnClose" className="arrow-icon" onClick={() => this.closeModal()} />
                    <Typography className="heading">Reply to: {this.state.currentModalItem?.name}</Typography>
                    <CloseIcon className="close-icon" data-test-id="btnClose" onClick={() => this.closeModal()} />
                  </DialogTitle>
                  <DialogContent className="content">
                    <Box sx={styles.modalDesc}><Box sx={styles.modalText}>{this.state.currentModalItem.attributes?.description}</Box></Box>

                    <Box sx={[styles.modalItemCol, { marginTop: '10px' }]}>
                      <Box fontStyle={styles.modalName}>{this.state.currentModalItem.attributes?.name}</Box><Box fontStyle={styles.modalName}>{this.state.currentModalItem.attributes?.company}</Box>
                    </Box>
                    <Box sx={styles.modalItemCol}>
                      <Box fontStyle={styles.modalSubInfo}>{this.state.currentModalItem.attributes?.job_title}</Box><Box fontStyle={styles.modalSubInfo}>{this.state.currentModalItem.attributes?.email}</Box>
                    </Box>
                    <Divider sx={{ marginTop: '20px' }} flexItem />

                    <Box sx={{ paddingTop: '20px' }}>
                      <FormControl fullWidth variant="outlined" sx={{}}>
                        <FormLabel component="legend">Category</FormLabel>
                        {this.renderCategoriesDropdown(this.state.currentModalItem)}
                        {this.state.showNewCategoryInput && <Box sx={{ display: 'flex', flex: '1', flexDirection: 'row' }}> {this.renderNewCategoryInput()}
                        <DoneButton
                          type="submit"
                          data-test-id="btnClose"
                          sx={{ margin: '10px' }}
                          onClick={() => this.addNewCategory()}
                        >
                            Add
                          </DoneButton></Box>}
                      </FormControl>
                    </Box><Box sx={{ display: 'flex', flex: '1', flexDirection: 'row' }}>
                      <Box>{this.isReplied() ? 'Replied' : ''}</Box>
                    </Box>
                    <Box sx={{ marginTop: '20px' }}>
                      <FormControl fullWidth variant="outlined" >
                        <FormLabel component="legend">Your reply</FormLabel>
                        {!this.state.isReplied ?
                          <TextField
                            variant="outlined"
                            value={this.state.replyText}
                            data-test-id="nameChange"
                            onChange={(e: any) => this.onChangeReplyText(e)}
                            multiline
                            rows={4}

                            sx={{ minHeight: '160px' }}
                          /> : <p>{this.state.replyText}</p>
                        }</FormControl>
                    </Box>
                  </DialogContent>
                  {this.isReplied() ? null : <DialogActions className="dialog-action" sx={{ marginBottom: '10px' }}>
                    <CancelButton data-test-id="btnClose" onClick={this.closeModal}>
                      Cancel
                    </CancelButton>
                    <DoneButton
                      type="submit"
                      data-test-id="btnDone"
                      onClick={() => this.sendReply()}
                    >
                      Reply
                    </DoneButton>
                  </DialogActions>}
                </CustomDialog>
                <Box sx={styles.paginationContainer}>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={this.state.queriesToRender.length}
                    rowsPerPage={10}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                  /></Box>
              </Box>
            </Box>
            <Box sx={styles.footerBlock}>
              <Box sx={styles.leftFooter}>
                <Box>Terms and Conditions</Box>
                <Box>Privacy Policy</Box>
                <Box></Box>
              </Box>

              <Box>
                <Box>© 2024 All Rights Reserved</Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
        {this.DeleteQueryModal()}
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const CustomCheckboxContactUs = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    width: '20px',
    backgroundColor: 'white',
    height: '20px',
    borderRadius: '6px',
    border: '1px solid black',
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      borderColor: 'black',
      backgroundColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M5 12l5 5L20 7")',
      strokeWidth: 2,
      stroke: 'white',
      fill: 'none',
    },
  },
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styles = {
  mainWrapper: {
    display: 'grid',
     gridTemplateRows: 'auto 1fr auto' ,
     height:"90vh"
  },
  mainBlock: {
    display: 'flex',
    width:'100%',
    flexDirection: 'row',
    backgroundColor:'#ffffff',
  },
  cellContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '312px',
  },
  cellWrapper: {
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding:'6px 8px'
  },
  cellWrapper1: {
    fontSize:"14px",
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding:'6px 8px'
  },
  cellHeader: {
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    height:'49px',
    padding:'5px 8px',
    fontWeight:'700',
    fontSize:'14px',
    color: '#475569',
    backgroundColor:'white',
    minWidth:'80px',
    borderBottom:'1px solid #D7D7D7'
  },
  cellInfo: {
    fontSize: '12px',
    fontWeight: '700',
    width:'100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  modalItemCol:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalName: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '22px'
  },
  modalSubInfo: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px'
  },
  descriptionContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '280px',
  },
  descriptionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    color: '#64748B',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontFamily:"Heebo-Regular",
    lineHeight:"22px"
  },
  descriptionText1: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    color: '#64748B',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontFamily:"Heebo-Regular",
    lineHeight:"18px"
  },
  menuItemContainer: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "space-between",
    backgroundColor: 'white',
  },
  categoryContainer: {
    minWidth: '100px',
    maxHeight: '200px',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '8px',
    border: '1px',
    '& .MuiSelect-select': {
      '&:focus': {
        outline: 'none',
        border: 'none',
      },
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
  categoryText: {
    fontSize: '12px',
    fontFamily:"Heebo-Bold",
    lineHeight: '18px',
    textAlign: 'left',
  },
  repliedBox: {
    backgroundColor: '#E7E5E4',
    padding: '2px 8px 2px 8px',
    borderRadius: '40px',
    fontSize: '12px',
    maxHeight: '18px',
    color: '#78716C',
    fontWeight: 700,
    marginLeft: '10px',
    textAlign: 'center',
  },
  subject: {
    color: '#0F172A',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '28px',
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  name: {
    fontSize: '18px',
    lineHeight: '18px',
    paragraph:"8px",
    color: "#1C1C1C",
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  name1: {
    fontSize: '12px',
    lineHeight: '18px',
    color: "#000000",
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  name2: {
    fontSize: '18px',
    lineHeight: '24px',
    paragraph:"8px",
    color: "#1C1C1C",
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  headerText: {
    fontFamily:'Heebo',
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 700,
    color: '#475569',
    maxWidth: '170px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  tableContainer: {
    display:"flex",
    flexGrow:"0.5",
    flexDirection:"column",
    overflowX: 'auto',
    },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  statusContainer: {
    height: '22px',
    fontSize: '12px',
    fontFamily:"Heebo-Bold",
    lineHeight:'18px',
    color: '#D97706',
    justifyContent: 'center',
    alignContent:'center',
    textAlign: 'center',
    backgroundColor: '#FEF3C7',
    border:'none',
    borderColor:"#FEF3C7",
    borderRadius: '26px',
  },
  newCategoryText: {
    color: '#059669',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  statusContainerSuccess:{
    height: '22px',
    fontSize: '12px',
    fontFamily:"Heebo-Bold",
    lineHeight:'18px',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#D1FAE5',
    borderRadius: '26px',
    borderColor:"#D1FAE5",
    border:'none',
    color: '#059669'
  },
  completed: {
    background: '#D1FAE5'
  },
  pending: {
    background: '#FEF3C7'
  },
  modalDesc: {
    backgroundColor: '#F0F0F0',
    padding: '12px',
  },
  modalText: {
    color: '#44403C',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400'
  },
  statusText: {
    fontFamily: 'Heebo',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    textAlign: 'left',
  },
  replyContainer: {
    backgroundColor: '#000000',
    width: '91px',
    height: '24px',
    textAlign: 'center',
    borderRadius: '4px',
    cursor: "pointer"
  },
  replyText: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily:"Heebo-Bold",
  },
  sortByRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '30px'
  },
  selectOptions: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
    justifyContent: 'space-between'
  },
  headerOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: '20px'
  },
  deleteBox: {
    borderRadius: '4px',
    height:'31px',
    border: '1px solid #FC5555',
    marginLeft: '16px',
    justifyContent:'center',
    backgroundColor:'#FFFFFF',
    cursor:'pointer'
  },
  markReadBox: {
    borderRadius: '4px',
    height:'31px',
    border: '1px solid #A8A29E',
    marginLeft: '16px',
    justifyContent:'center',
    cursor:'pointer'
  },
  relevText: {
    padding: '8px',
    fontWeight: '700',
    fontSize: '16px'
  },
  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow:1
  },
  rightFooter: {},

  sideBar: {
    height: '974px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '30px 16px 24px 40px',
    border: '1px solid #f5f5f5',
    backgroundColor: '#fff'
  },
  sideBarTop: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
  },
  sideNavList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px'
  },
  evenRow: {
    backgroundColor: '#f5f5f5'
  },
  oddRow: {
    backgroundColor: '#ffffff'
  },
  sideNavItem: {
    height: '48px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    padding: '4px 16px',
    borderRadius: '8px'
  },
  logoutButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    color: 'red',
    flexGrow: '1',
    justifyContent: 'start',
    fontSize: '16px',
    textTransform: 'none'
  },
  pageContent: {
    display: 'flex',
    overflowX:'auto',
    flexDirection: 'column',
    width:'100%'
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '24px',
    paddingTop:'30px',
    paddingBottom:'23px'
      },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '16px',
  },
  currentNavigationPage: {
    fontWeight: '700',
    fontSize: '24px',
    padding: '4px 8px'
  },
  breadcrumbButton: {
    fontSize: '24px',
    fontStyle:'Heebo',
    fontWeight:700,
    lineHeight:'32px',
    padding: '4px 8px',
    color:'#1C1C1C'
  },
  leftActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    fontSize: '24px'
  },
  blackButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    textTransform: 'none'
  },
  saveButton: {
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: '#333333',
    },
    height: '44px',
    padding: '10px 16px 6px',
    fontWeight: '700'
  },
  moreButton: {
    color: 'black',
    padding: '10px'
  },

  pathTitle: {
    fontSize: '24px',
    fontWeight: '500'
  },

  filterButtonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px'
  },
  filterButton: {
    padding: '8px 16px 4px',
    fontSize: '16px'
  },
  courseCardsBlock: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '40px',
    borderRadius: '4px'
  },
  courseCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    width: '240px'
  },
  courseCardDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '0px 16px 24px 16px'
  },
  iconsStyle: {
    minWidth: '1px'
  },
  textRow: {
    whiteSpace: 'nowrap'
  }
}

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '720px',
    width: '100%',
    borderRadius: "8px 8px 25px",
    [theme.breakpoints.down('sm')]: {
      margin: "0px",
      maxWidth: "100% !important",
      borderRadius: "0px",
      height: "100vh",
    }
  },
  "& .MuiGrid-root > .MuiGrid-item": {
    paddingTop: "7px",
  },
  "& .dialog-title": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 16px 10px 30px",
    borderBottom: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 15px 25px 20px",
      borderBottom: "0px",
      justifyContent: "normal",
    },
    "& .heading": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        fontSize: "14px",
        textAlign: "center",
        textTransform: "uppercase",
      },
    },
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
      [theme.breakpoints.down('sm')]: {
        display: "none"
      },
    },
    "& .arrow-icon": {
      cursor: "pointer",
      display: "none",
      [theme.breakpoints.down('sm')]: {
        display: "block"
      },
    }
  },
  "& .content": {
    padding: "28px 40px !important",
    [theme.breakpoints.down('sm')]: {
      padding: "15px !important",
    },
    "& .mt5": {
      marginTop: "5px",
      fontSize: "16px",
      fontFamily: "Heebo-Regular",
    }
  },
  "& .contact-info": {
    fontSize: "16px",
    color: "#0F172A",
    marginBottom: "15px",
    fontFamily: "Heebo-Regular"
  },
  "& .label-title": {
    fontSize: "14px",
    color: "#9D9D9D",
    fontFamily: "Heebo-Bold",
    [theme.breakpoints.down('sm')]: {
      color: "#6A6A6A"
    },
  },
  "& .cool-gray": {
    color: "#64748B"
  },
  "& .dialog-action": {
    padding: "10px 16px 0px",
    borderTop: "1px solid #E2E8F0",
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },
  "& .send-btn": {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "block"
    },
  },
  "&.delete-popup": {
    "& .MuiPaper-root": {
      borderRadius: "8px 8px 32px 8px",
    },
    "& .dialog-title": {
      justifyContent: "end",
      borderBottom: "none",
    },
    "& .delete-title": {
      lineHeight: "2",
      marginBottom: "10px",
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
      letterSpacing: "-0.5px"
    },
    "& .delete-description": {
      fontSize: "18x",
      fontFamily: "Heebo-Regular",
      letterSpacing: "-0.5px"
    },
    "& .done-btn":{
        borderRadius: "8px !important",
        backgroundColor: "#FC5555 !important",
    },
    " & .dialog-action": {
      padding: "20px",
    }
  },
}));

const CustomInput = styled(InputBase)({
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
    padding: "7px 8px",
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    padding: "5px 8px",
  }
});

const CancelButton = styled(Button)({
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  width: "80px",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "7px 15px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});

const SendButton = styled(Button)({
  textTransform: "none",
  marginTop: "50px",
  color: "#64748B !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  fontFamily: "Heebo-Bold",
  borderRadius: "8px !important",
  backgroundColor: "#F0F0F0",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

// Customizable Area End
