import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
  Paper,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableFooter,
  TableRow,
  Pagination,
  Select as MuiSelect,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon, MoreVert as MoreVertIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import Sidebar from "../../../components/src/sidebar/Sidebar.web";
import AdminFooter from "../../../components/src/AdminFooter.web";
import { DeselectIcon } from "./assets";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationList() {
    const notifications = [
      {
        primary: "A new user, John Doe, has registered as a student. Click to review their profile.",
        secondary: "Thursday 06:09PM",
      },
      {
        primary: "A new course, ‘Cybersecurity Essentials,’ is awaiting your approval. Click to review details.",
        secondary: "Wednesday 03:06AM",
      },
      {
        primary: "The LMS will undergo maintenance on Saturday from 1 AM to 3 AM. Ensure backups are up-to-date.",
        secondary: "Wednesday 07:54PM",
      },
      {
        primary: "A weekly activity report for ‘Data Science Basics’ has been submitted. Click to analyze.",
        secondary: "Tuesday 11:32AM",
      },
      {
        primary: "A flagged comment in the ‘Web Development’ discussion forum requires your moderation.",
        secondary: "Monday 01:32AM",
      },
      {
        primary: "50 new students have enrolled in ‘AI Fundamentals’ this week. Click to view enrollment details.",
        secondary: "Sunday 03:57PM",
      },
      {
        primary: "Instructor John Smith has submitted feedback regarding the assessment process. Click to review.",
        secondary: "Saturday 09:18PM",
      },
      {
        primary: "Your announcement to all enrolled students in ‘Advanced Marketing’ has been successfully delivered.",
        secondary: "Friday 10:58PM",
      },
    ];

    return notifications.map((notification, index) => (
      <ListItem key={index}>
        <ListItemIcon>
          <Checkbox
            data-testid="checkbox-select"
            onChange={() => this.handleCheckboxChange(index)}
            sx={{
              '&.Mui-checked': {
                color: 'black',
              },
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={notification.primary}
          secondary={notification.secondary}
        />
        <IconButton onClick={this.handleClick}>
          <MoreVertIcon />
        </IconButton>
      </ListItem>
    ));
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box display={"flex"} flexDirection="column" minHeight="100vh">
            <Box display={"flex"} flexGrow={1} overflow="hidden">
              <Box>
                <Sidebar
                  navigation={this.props.navigation}
                  current="admin_dashboard"
                />
              </Box>
              <Box sx={{ flexGrow: 1, padding: 2, overflow:'auto' }}>
                <Typography variant="h4" color="black" mb={2}>
                  Notifications
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  {this.state.selectedNotifications.size > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      mb: 2,
                    }}
                  >
                    <img src={DeselectIcon} style={{ marginRight: '20px' }} data-test-id="deselect-btn" />
                    <Typography>{this.state.selectedNotifications.size} item{this.state.selectedNotifications.size > 1 ? 's' : ''} selected</Typography>
                    <Button
                      variant="outlined"
                      sx={{ borderColor: "neutral.400", color: "black", opacity: 0.9, textTransform: "none" }}
                    >
                      Mark as read
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ opacity: 0.9, textTransform: "none" }}
                    >
                      Delete
                    </Button>
                  </Box>
                )}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginLeft: "auto" }}>
                    <Typography variant="body2" color="neutral.700">
                      Sort by:
                    </Typography>
                    <MuiSelect
                        data-testid="sort-select"
                        value={this.state.sortBy}
                        onChange={(e: any) => this.handleSortByChange(e)}
                        defaultValue="relevance"
                        disableUnderline
                        variant='standard'
                        sx={{
                          paddingLeft: '10px',
                          width: '150px',
                          backgroundColor: 'transparent',
                          '& .MuiSelect-icon': {
                            color: 'gray',
                          },
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            '& input': {
                              backgroundColor: 'transparent',
                              padding: ''
                            },
                          },
                        }}
                      >
                        <MenuItem value="Name (A-Z)">Name (A-Z)</MenuItem>
                        <MenuItem value="Name (Z-A)">Name (Z-A)</MenuItem>
                        <MenuItem value="Oldest to newest">Oldest to newest</MenuItem>
                        <MenuItem value="Newest to oldest">Newest to oldest</MenuItem>
                        <MenuItem value="status">Status</MenuItem>
                      </MuiSelect>
                  </Box>
                </Box>

                <Paper sx={{ borderRadius: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      borderBottom: 1,
                      borderColor: "neutral.400",
                    }}
                  >
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                    />
                    <Typography
                      variant="body2"
                      color="neutral.600"
                      sx={{ ml: 2 }}
                    >
                      Notification
                    </Typography>
                  </Box>
                  <List>
                    {this.renderNotificationList()}
                  </List>
                  <TableFooter
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '16px',
                      marginLeft: '469px',
                      borderTop: '1px solid #E0E0E0',
                    }}
                  >
                    <TableRow
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <TableCell
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderBottom: 'none',
                        }}
                      >
                        <CustomPagination
                          data-test-id="pagination"
                          size='small'
                          count={10}
                          page={4}
                          //onChange={(event, value) => this.getUsers(value)}
                          siblingCount={1}
                          boundaryCount={1}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center',
                          borderBottom: 'none',
                          flex: 1,
                        }}
                      >
                        {`${1}-${12} of ${48} results`}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Paper>

                <Menu
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  PaperProps={{
                    elevation: 1,
                    sx: {
                      width: 143,
                      mt: 1.5,
                      "& .MuiMenuItem-root": {
                        px: 4,
                        py: 1.5,
                      },
                    },
                  }}
                >
                  <MenuItem onClick={this.handleClose}>View</MenuItem>
                  <MenuItem onClick={this.handleClose}>Mark as read</MenuItem>
                  <MenuItem onClick={this.handleClose} sx={{color:'red'}}>Delete</MenuItem>
                </Menu>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: 52,
                bgcolor: "primary.main",
                display: "flex",
                alignItems: "center",
                gap: 6,
              }}
            >
            </Box>
          </Box>
          <AdminFooter />
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
const CustomPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
          backgroundColor: "transparent",
          color: "#6200EA",
          fontWeight: "700",
      },
  },
})
// Customizable Area End
