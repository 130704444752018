import React from 'react'
import UserAnalyticsController, { Props } from "./UserAnalyticsController.web";
import {
  Button,
  Typography,
  Box,
  LinearProgress,
  TableRow,
  Grid,
  Table,
  TableHead,
  TableContainer,
  Paper,
  Tabs, Tab,
  AppBar,
  TableBody,
  Pagination,
  TextField,
  InputAdornment,
  CircularProgress,
  Select,
  MenuItem,
  ListItemText,
  Checkbox
} from "@mui/material";
import {
  FilterList,
  Search,
  KeyboardArrowDown
} from '@mui/icons-material';
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import Chart from "react-google-charts";
import { CardOverView, LegendBox, LegendLine, MapContainer, PieChartBox, StyledTableCell, StyledTableRow, webStyles } from "./CourseAnalytics.web";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
  styled,
} from "@mui/material/styles";
import {ImageSorting} from './assets'
import TableSkeleton from '../../../../packages/components/src/TableSkeleton.web'
import DOMPurify from 'dompurify';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


const theme2 = createTheme({
  palette: {
    secondary: {
      main: '#fff'
    },
    primary: {
      contrastText: "#fff",
      main: "#000",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
});

const StyledSelect = styled(Select)({
  backgroundColor: 'white',
  borderRadius: '4px',

  '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
      backgroundColor: 'white',
      borderRadius: '4px',
  },
})

const  StyledMenuItem = styled(MenuItem)({
  padding: 0,
  borderRadius: "2px",
  border:"1px solid #D7D7D7",
  backgroundColor:"#fff",
  margin:"10px",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: "500",
    color:"#000",
    marginLeft:"10px"
  },
});

const CustomCheckboxAnalytics = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    width: '20px',
    backgroundColor: 'white',
    height: '20px',
    borderRadius: '6px',
    border: '1px solid black',
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      borderColor: 'black',
      backgroundColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M5 12l5 5L20 7")',
      strokeWidth: 2,
      stroke: 'white',
      fill: 'none',
    },
  },
});
export default class UserAnalytics extends UserAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderHeaderWebUser = () => {
    return (
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"90px"}>
        <Box display={"flex"} gap="10px" alignItems={"center"}>
          <Typography
            style={{ fontFamily: 'Heebo',
              lineHeight: '32px', 
              fontSize: '24px', 
              fontWeight: '700', 
               color: '#1C1C1C'
               }}
          >Analytics
          </Typography>
          <>
            <Typography
              style={{ 
                fontWeight: '700', 
                fontFamily: 'Heebo', 
                color: '#475569', 
                fontSize: '16px', 
                lineHeight: '24px', 
              }}
            >
              Jul 30 - Aug 5
              </Typography>
            <KeyboardArrowDown />
          </>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
          <Button
            style={{ fontFamily: 'Heebo', 
              padding: '10px 16px', 
              lineHeight: '24px',
              borderRadius: '4px', 
              fontWeight: '700', 
              fontSize: '16px', 
              color: '#fff', 
               backgroundColor: '#000', 
               textTransform: 'none'
               }}
          >Export data
          </Button>
        </Box>
      </Box>
    )
  }
 
  /* istanbul ignore next */
  renderSearchSectionUser = () => {
    const {clientList,selectedClients} =this.state
    return (
      <Box display={"flex"} gap={"20px"} alignItems={"center"} height={"60px"}>
        <Box style={{
          width: '100%', height: '100%'
        }}>
        <StyledSelect
                displayEmpty
                renderValue={(selected: any) => this.renderClientNames(selected)}
                MenuProps={{
                    PaperProps: {
                      sx: {
                        '&.MuiPaper-root': {
                          marginTop:"0.5%",
                          border: '1px solid #ccc',
                          boxShadow: 'none',
                          height:"40%"
                        },
                      },
                    },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  }}
                IconComponent={(props) =><ArrowDropDownIcon {...props} style={{ color: "black" }} />}
                value={selectedClients}
                multiple
                sx={{ width: '100%' }}
            >
            <StyledMenuItem sx={{ display: 'none' }} value="" >
                All Client
            </StyledMenuItem>
            {clientList.map((client: any) => {
                return (
                    <StyledMenuItem key={client.id} value={client.id}>
                        <ListItemText key={client.id} primary={client.attributes.company_name} />
                        <CustomCheckboxAnalytics
                            data-testId={`client-cb-${client.id}`}
                            checked={selectedClients.includes(client.id)}
                            onChange={() => this.handleCheckboxToggle(client.id)}
                        />
                    </StyledMenuItem>
                );
            })}

        </StyledSelect>

        </Box>
        <Box height={"100%"}>
          <Button
            data-testID="filter-button"
            style={{
              borderRadius: '4px',
              padding: '10px 16px', 
              fontWeight: '700',
              fontFamily: 'Heebo', 
              alignItems: 'center', 
              height: '100%',
              display: 'flex', 
              textAlign: 'center', 
              gap: '10px',
              fontSize: '16px', lineHeight: '24px',
              textTransform: 'none',
              backgroundColor: 'transparent', color: '#000',
            }}
            onClick={() => { }}
          >
            <Typography
              style={{ fontFamily: 'Heebo', 
                fontSize: '14px', 
                fontWeight: '700', 
                color: '#000' }}
            >
              Filter
            </Typography>
            <FilterList />
          </Button>
        </Box>
      </Box>
    )
  }
  
  /* istanbul ignore next */
  renderPieChartUser = () => {
    const {overAllData} =this.state
    console.log(overAllData,"overAllData");
    
    const dataPie = [
      ["Devices", "Number"],
    ];

    const updateDataPie = (staticData:any, apiData:any) => {
      const updatedData = [...staticData]; 
      const data =apiData ||{}
      for (const [key, value] of Object.entries(data)) {
        updatedData.push([key, value]);
      }
      return updatedData;
    };
    
    const updatedDataPie = updateDataPie(dataPie, overAllData.device_breakdown);
    
    const optionsPie = {
      is3D: false,
      colors: ['#7B61FF', '#0099FF', '#F59E0B', '#9D9D9D'],
      pieHole: 0.7,
      legend: "none",
      pieSliceText: 'none',
    };

    const renderLegend2 = () => {
      return (
        <LegendBox flexDirection={'column'} flex={1} justifyContent={'center'} display={'flex'} >
          {updatedDataPie.slice(1).map(legend =>{
            return (
              <LegendLine>
                <div>
                  <div className={`legend-icon ${legend[0]}`}></div>
                  <p className="legend-title">{legend[0]}</p>
                </div>
                <p className="legend-value">{legend[1]}</p>
              </LegendLine>
            )
          })}

        </LegendBox>
      )
    }
    return (
      <CardOverView sx={{ display: 'flex', 
      flexDirection: 'column', 
      }}>
        <Box display={'flex'} flexDirection={'column'} >
          <Typography style={webStyles.titleOverview}>Devices breakdown</Typography>
          <Typography style={webStyles.contentOverview}>Devices</Typography>
        </Box>
        <PieChartBox 
        height={'100%'} 
        display={'flex'}
         flex={2} 
         overflow={'hidden'}>
          <Box flex={1} >
            <Chart 
            chartType="PieChart" 
            width="100%" 
            height={'100%'}
             data={updatedDataPie} 
             options={optionsPie} />
          </Box>
          {renderLegend2()}
        </PieChartBox>
      </CardOverView>
    )
  }
  renderAnalyticsOverviewUser = () => {
  const {overAllData} =this.state

    return (
      <Grid   spacing={2} container sx={{  height: '300px' ,marginTop: '24px',}}>
        <Grid 
        style={{ maxHeight: '150px' }} 
        item 
        xs={3}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Courses Assigned</Typography>
            <Typography style={webStyles.contentOverview}>{overAllData?.total_assigned_courses_overall}</Typography>

          </CardOverView>
        </Grid>
        <Grid 
        style={{ maxHeight: '150px' }} 
        item 
        xs={3}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Courses Completed</Typography>
            <Typography style={webStyles.contentOverview}>{overAllData?.total_completed_courses_overall}</Typography>

          </CardOverView>
        </Grid>
        <Grid 
        item
        style={{ maxHeight: '150px' }} 
         xs={3}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Courses Left to Complete</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: '#F59E0B' }}>{overAllData?.total_uncompleted_courses_overall}</Typography>
          </CardOverView>
        </Grid>
        <Grid style={{ maxHeight: '150px' }} 
        xs={3}
        item 
        >
          <CardOverView flexDirection={'column'} display={'flex'} >
            <Typography style={webStyles.titleOverview}>Total Learning Time</Typography>
            <Typography style={webStyles.contentOverview}>{overAllData?.total_learning_hours}h</Typography>

          </CardOverView>
        </Grid>
        <Grid
         item 
         style={{ maxHeight: '150px' }} 
         xs={4}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Assessment Attempts</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: '#F59E0B' }}>{overAllData?.total_attempted_assessments}</Typography>

          </CardOverView>
        </Grid>
        <Grid style={{ maxHeight: '150px' }} item xs={4}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Average Assessment Score</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: "#FC5555" }}>{overAllData?.total_completion_percentage?.toFixed(2)}%</Typography>

          </CardOverView>
        </Grid>
        <Grid style={{ maxHeight: '150px' }} item xs={4}>
          <CardOverView display={'flex'} flexDirection={'column'}>
            <Typography style={webStyles.titleOverview}>Average Time To Complete a Course</Typography>
            <Typography style={{ ...webStyles.contentOverview, color: "#059669" }}>{overAllData?.average_course_completion_time}</Typography>

          </CardOverView>
        </Grid>
      </Grid>
    )
  }
  /* istanbul ignore next */
  renderWorldMapChart = () => {
  const {overAllData} =this.state

    const mapData = [["Country", "User"]];

    const updateMapData = (staticData: any, apiData: any) => {
      const updatedData = [...staticData];
      const data = apiData || {};
    
      for (const [key, value] of Object.entries(data)) {
        const locationParts = key.split(", ").map((part) => [part, value]);
        updatedData.push(...locationParts); 
      }
    
      return updatedData;
    };
    const updatedDataPie = updateMapData(mapData, overAllData.location_breakdown);
    
    const userData = updatedDataPie?.map(([location, value]) => ({ location, value }))?.slice(1);

    return (
      <Grid  style={{ boxSizing: 'border-box', marginTop: '10px',marginBottom: '20px', overflow: 'hidden',  }} container spacing={2}>
        <Grid item xs={8} >
          <CardOverView >
            <Typography style={webStyles.titleOverview}>Location</Typography>
            <Typography style={webStyles.contentOverview}>{overAllData.total_users} <span style={{fontSize:'16px'}}>Users</span></Typography>
            <Box display={'flex'} height={'100%'} maxHeight={'250px'} >
              <MapContainer style={{ flex: '1', overflow:'scroll' }}>
                <Chart
                  chartType="GeoChart"
                  width="100%"
                  height='100%'
                  data={updatedDataPie}
                  options={{ 
                    legend: 'none',
                    colorAxis: { colors: ["#BABABA", "#BFBFBF", "#B1B1B1",'#AAAAAA','#969696','#626262'] }, 
                    datalessRegionColor:'#BABABA' }}
                />
              </MapContainer>
              <Box flex={1} px={'20px'}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={'10px'} mb={'10px'}  borderBottom={'1px solid #F0F0F0'} sx={{color:'#6A6A6A'}}>
                  <Typography fontSize={12}>Top 5 users locations</Typography>
                  <Typography fontSize={12}>Users</Typography>
                </Box>
                {userData.map(item=>{
                  return (
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} key={item.location}>
                      <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                        <Typography sx={{ mb: 1 }}>
                          {item.location}
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={item.value / 50 * 100}
                          sx={{
                            height: '5px',
                              borderRadius:'5px',
                              backgroundColor: 'white',
                            '& .MuiLinearProgress-bar': {
                              backgroundColor: 'black',
                              borderRadius:'5px'
                            },
                          }} />
                      </Box>
                      <Typography>{item.value}</Typography>
                    </Box>
                  )
                })}
                </Box>
              </Box>
          </CardOverView>
        </Grid>
        <Grid item xs={4} >
          {this.renderPieChartUser()}
        </Grid>
      </Grid>
    )
  }

  /* istanbul ignore next */
    sanitizeData = (htmlString:string) => {
      const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
      return sanitizedDOM.textContent || '';
    };

  /* istanbul ignore next */
  renderTableData = () => {
    const { tableData,metaData,isLoadingTable} = this.state; 
    const startResult = metaData.page * 10 + 1;
    const endResult = Math.min((metaData.page + 1) * 10, metaData.total_count);
    
    return (
      <TableContainer component={Paper}>
        <Box overflow={'scroll'}>
          <Table
          aria-label="customized table"
          sx={{ minWidth: 1050,
            borderRadius: '5px' ,
            }}
            >
            <TableHead>
              <TableRow>
                <StyledTableCell><div style={{ ...webStyles.cellHeader, minWidth: '120px' }}>First and Last Name <img src={ImageSorting} onClick={() => this.handleSort("user_name")} alt={`sort-btn`} /></div> </StyledTableCell>
                <StyledTableCell >
                  <div style={webStyles.cellHeader}>Course
                    <img src={ImageSorting} onClick={() => this.handleSort("course_name")} alt={`sort-btn`} />
                  </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Active Time
                  <img src={ImageSorting} onClick={() => this.handleSort("learning_hours")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Completed
                  <img src={ImageSorting} onClick={() => this.handleSort("completed_courses_count")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Device
                  <img src={ImageSorting} onClick={() => this.handleSort("most_used_device")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell ><div style={webStyles.cellHeader}>Location
                  <img src={ImageSorting} onClick={() => this.handleSort("most_used_location")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell style={{minWidth:'130px'}}><div style={webStyles.cellHeader}>Client
                  <img src={ImageSorting} onClick={() => this.handleSort("client_name")} alt={`sort-btn`} />
                </div>
                </StyledTableCell>
                <StyledTableCell style={{minWidth:'130px'}}><div style={webStyles.cellHeader}>Date started
                  <img src={ImageSorting} alt={`sort-btn`} onClick={() => this.handleSort("course_start_tim")}/>
                </div>
                </StyledTableCell>
                <StyledTableCell style={{minWidth:'130px'}}><div style={webStyles.cellHeader}>Date Completed
                  <img src={ImageSorting} alt={`sort-btn`} onClick={() => this.handleSort("course_end_time")}/>
                </div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoadingTable ?
              <TableSkeleton rowsNum={10} columnsNum={9}/>
              : tableData && tableData?.map((data:any) => (
                  <StyledTableRow key={data.user_id}>
                    <StyledTableCell >{data.user_name}</StyledTableCell>
                    <StyledTableCell style={{minWidth:'150px'}}>
                      <div style={webStyles.courseCell}> {this.sanitizeData(data.course_name) || "Course"}</div>
                    </StyledTableCell>
                    <StyledTableCell >{data.learning_hours}</StyledTableCell>
                    <StyledTableCell >{data.completed_courses_count}</StyledTableCell>
                    <StyledTableCell >{data.most_used_device || "-"}</StyledTableCell>
                    <StyledTableCell >{data.most_used_location || "-"}</StyledTableCell>
                    <StyledTableCell >{data.client_name}</StyledTableCell>
                    <StyledTableCell >{data.course_start_time || "-"}</StyledTableCell>
                    <StyledTableCell >
                        {data.course_end_time || " - "}
                      </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
        <Box style={{ display: 'flex',
          justifyContent: 'space-between',
          width: '100%', 
          alignItems: 'center', 
          padding: '20px', 
           boxSizing: 'border-box' }}>
          <Box>
          </Box>
          <Pagination
            color="primary"
            count={Math.ceil(metaData.total_count / 10)}
            onChange={this.handlePageChange}
            sx={{
              '& .MuiPaginationItem-root':{
                textDecoration:'underline'
              },
              '& .MuiPaginationItem-ellipsis':{
                textDecoration:'unset ',
              },
              '& .Mui-selected, .Mui-selected:hover': {
                color: '#6200EA',
                textDecoration:'unset ',
                backgroundColor: 'transparent',
              },
            }}
            page={metaData.current_page}
            size="large"
          />
          <Typography>
          {`${startResult}-${endResult} of ${metaData.total_count} results`}
          </Typography>
        </Box>
      </TableContainer>
    )
  }
  navConfig = [
    { label: 'User Analytics', value: 'UserAnalytics' },
    { label: 'Client-Wide Analytics', value: 'ClientWideAnalytics' },
    { label: 'Course Analytics', value: 'CourseAnalytics' },
    { label: 'Feedback Aggregation', value: "Analytics" },
    { label: 'Reports', value: 'ReportsAnalytics' },
  ];

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme2}>
          <Box display={"flex"} overflow={'hidden'}>
            <Box>
              <Sidebar navigation={this.props.navigation} current="analytics" />
            </Box>
            <Box
              gap={"16px"}
              flexDirection={"column"}
              padding={"24px 48px"}
              display={"flex"}
              width={"100%"}
              sx={{ backgroundColor: '#F0F0F0', overflow: 'auto' }}>
              {this.renderHeaderWebUser()}
              <Box width={"fit-content"}>
                <AppBar position="static" style={{ maxHeight:'60px',padding:'10px' ,width: "60vw", boxShadow: 'none', borderRadius: '4px' }} color="secondary">
                  <Tabs
                    data-testID="Tabs"
                    textColor="secondary"
                    value="UserAnalytics"
                    aria-label="analytics tabs"
                    TabIndicatorProps={{
                      style: {
                        display: 'none'
                      },
                    }}
                    style={{ gap: '10px' }}
                  >
                    {this.navConfig.map((tab, index) => (
                      <Tab
                      data-test-id={`tab-${index}`}
                        key={index}
                        onClick={() => this.handleChangeTabUserAnalytics(tab.value)}
                        label={
                          <Typography
                              sx={{
                                textTransform: 'none',
                                fontSize: '12px',
                                fontFamily: 'Heebo-bold',
                                lineHeight: '18px',
                                padding: '12px 0',
                                width:'max-content',
                                fontWeight: '700',
                              }}
                            >
                              {tab.label}
                            </Typography>
                        }
                        value={tab.value}
                        sx={{
                          '&.Mui-selected': {
                            backgroundColor: 'black',
                            color: 'white',
                            borderBottom: 'none',
                          },
                          borderRadius: '4px',
                          color: tab.value === 'UserAnalytics' ? 'white' : '#6A6A6A',
                          backgroundColor: tab.value === 'UserAnalytics' ? 'black' : 'inherit',
                          boxSizing:'border-box',
                          flex:1,
                          padding:'0 16px',
                          minHeight:0,
                          height:'100%',
                          maxHeight:'60px',
                        }}
                      />
                    ))}
                  </Tabs>
                </AppBar>
              </Box>
              <Box paddingTop={"20px"}>
              <Box width={"100%"} 
                flexDirection={'column'} 
                display={'flex'} 
                boxSizing={'border-box'} >
                  {this.renderSearchSectionUser()}
              </Box>
              {this.state.isLoadingPage?
                  <Box sx={{ width: '100%', height: '100vh' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                  <CircularProgress
                    style={{
                      height: '100px',
                      width: '100px',
                      boxShadow: 'inset 0 0 1px 1px #D9d9d9',
                      borderRadius: '50%'
                    }}
                    sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
                  />
                </Box>:
                <Box width={"100%"} 
                flexDirection={'column'} 
                display={'flex'} 
                boxSizing={'border-box'} >
                  {this.renderAnalyticsOverviewUser()}
                  {this.renderWorldMapChart()}
                  {this.renderTableData()}
                </Box>}
              </Box>
            </Box>
          </Box>
          <AdminFooter/>
        </ThemeProvider>
      </StyledEngineProvider>

    )
  }
}  
